/* eslint-disable arrow-body-style */
/* eslint-disable indent */
/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable react/no-array-index-key */
import { rolesType } from 'constants/rtoles'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch, batch } from 'react-redux'

import {
  AccessWrapper,
  allowedStatusesToDeleteImageAndVideo,
  LoanStatuses,
  normalizeValues,
} from 'helpers'
import { ToastTypes } from 'lib/toast/Toast'
import { GlobalState } from 'index'
import { deleteVideoVerification, fetchVideoVerification } from 'features/loan/extra'
import { addToast } from 'features/toast/toastSlice'
import { IVideoVerificationData } from 'features/loan/types'
import { Button, ModalConfirm, Module, Switcher } from 'components'
import {
  StyledVideoWrapper,
  StyledVideoItem,
  StyledDescriptionVideo,
  StyledSwitchBlockWrapper,
  StyledSwitcherBlock,
} from './Modules.styles'
import { PermissionContext } from 'app/contexts'
import { TabsMediaFilesFilteredType } from './types'

const moduleId = 'videoConfirmation'

interface IModal {
  show: boolean
  title: string
  action?: any
  style: string
}

const tabConfig = {
  [TabsMediaFilesFilteredType.VideoVerification]: { 0: 'По заявке', 1: 'Все' },
}

export const VideoConfirmation: any = ({
  openedModules,
  onToggleModule,
  customerId,
  claimsResolve,
  role,
  loanStatus,
  loanNumber,
  claims,
}): JSX.Element => {
  const dispatch = useDispatch()
  const isOpen = openedModules.includes(moduleId)
  const { permissions } = useContext(PermissionContext)

  const {
    videoVerification: { isFetching, data },
  } = useSelector((state: GlobalState) => state.singleLoan)

  const [videoData, setVideoData] = useState<IVideoVerificationData[]>(null)
  const [activeTab, setActiveTab] = useState<{
    [TabsMediaFilesFilteredType.VideoVerification]: number
  }>({
    [TabsMediaFilesFilteredType.VideoVerification]: 0,
  })

  const handleTabChange = (tabType: TabsMediaFilesFilteredType, index: number) => {
    setActiveTab((prev) => ({
      ...prev,
      [tabType]: index,
    }))
  }

  const [showModal, setShowModal] = useState<IModal>({
    show: false,
    title: '',
    action: null,
    style: '',
  })

  useEffect(() => {
    if (data.length > 0) setVideoData(data)
    else setVideoData(null)
    return () => setVideoData(null)
  }, [data])

  const handleExpand = (): void => onToggleModule(moduleId)

  const handleCloseModal = (): void =>
    setShowModal({ show: false, title: '', action: null, style: '' })

  const handleRemoveVideo = ({ id, customer_id }): void => {
    const action = (): void => {
      dispatch(
        deleteVideoVerification({
          video_id: id,
          onSuccess: () => {
            batch(() => {
              dispatch(fetchVideoVerification({ customer_id }))
              dispatch(
                addToast({
                  type: ToastTypes.success,
                  title: 'Успех',
                  description: 'Видео успешно удалено',
                })
              )
            })
          },
          onError: () =>
            dispatch(
              addToast({
                type: ToastTypes.danger,
                title: 'Ошибка',
                description: 'Видео не удалено',
              })
            ),
        })
      )
    }
    setShowModal({
      show: true,
      title: ` Удалить видео ?`,
      action,
      style: 'warning',
    })
  }

  const filteredVideoData = useMemo(() => {
    if (!videoData) return []

    return activeTab[TabsMediaFilesFilteredType.VideoVerification] === 0
      ? videoData.filter((video) => String(video.loan_number) === String(loanNumber))
      : videoData
  }, [videoData, activeTab, loanNumber])

  const canVideoBeRemoved = ({ imageLoanNumber }) => {
    if (
      (claims === rolesType.underwriter || claims === rolesType.agent) &&
      imageLoanNumber === loanNumber &&
      allowedStatusesToDeleteImageAndVideo.includes(loanStatus)
    )
      return true
    return false
  }

  return (
    <Module.Wrapper isOpen={isOpen} id={moduleId}>
      <ModalConfirm
        isOpen={showModal.show}
        onRequestClose={handleCloseModal}
        onClose={handleCloseModal}
        title={showModal.title}
        action={showModal.action}
        style={showModal.style}
      />
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <Module.HeadTitle>
            Видео идентификация
            {!data.length ? (
              <span style={{ color: 'gray', opacity: '0.5' }}> / отсутствует</span>
            ) : null}
          </Module.HeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        {isFetching ? (
          <div style={{ minHeight: '150px' }}>
            <Module.LoaderBlock />
          </div>
        ) : (
          <>
            <StyledSwitcherBlock>
              <StyledSwitchBlockWrapper>
                <Switcher
                  tabs={tabConfig[TabsMediaFilesFilteredType.VideoVerification]}
                  activeTab={activeTab[TabsMediaFilesFilteredType.VideoVerification]}
                  onTabChange={(index) =>
                    handleTabChange(TabsMediaFilesFilteredType.VideoVerification, index)
                  }
                />
              </StyledSwitchBlockWrapper>
            </StyledSwitcherBlock>

            <StyledVideoWrapper>
              {filteredVideoData
                ? filteredVideoData.map((video, index) => {
                    return (
                      <StyledVideoItem key={index}>
                        <video controls width='100%'>
                          <source src={video.video_url} />
                        </video>
                        <StyledDescriptionVideo>
                          <p>
                            Код : <b>{video.code}</b>
                          </p>
                          <p>
                            Потраченное время: <b>{video.time_spent}</b>
                          </p>
                          <p>
                            Дата создания:{' '}
                            <b>{normalizeValues(video.create_date, 'source_date')}</b>
                          </p>
                        </StyledDescriptionVideo>
                        <br />
                        <br />
                        <>
                          {canVideoBeRemoved({ imageLoanNumber: video.loan_number }) && (
                            <Button
                              onClick={() =>
                                handleRemoveVideo({
                                  id: video.video_id,
                                  customer_id: video.customer_id,
                                })
                              }
                              type='bigDanger'
                            >
                              Удалить видео
                            </Button>
                          )}
                        </>
                      </StyledVideoItem>
                    )
                  })
                : ''}
            </StyledVideoWrapper>
          </>
        )}
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}
