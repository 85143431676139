/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable indent */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useState, useEffect, useContext } from 'react'
import { Formik } from 'formik'
import { useSelector, useDispatch, batch } from 'react-redux'

import { Button, Module, Select, Switcher, UploadLoanImage } from 'components'
import { ImageModal } from 'containers'
import { GlobalState } from 'index'
import { PermissionContext } from 'app/contexts'

import {
  DatePickerField,
  InputField,
  LoanStatuses,
  divisionCodeMask,
  genderType,
  isValidForm,
  normalizeDateForApi,
  normalizeValues,
  numberPassportCodeMask,
  serialPassportCodeMask,
  validationSchemaPassport,
  AccessWrapper,
  previousPassportCodeMask,
  allowedStatusesToDeleteImageAndVideo,
} from 'helpers'

import {
  StyledButtonBlock,
  StyledImageDescription,
  StyledPassportPhotoItem,
  StyledPassportPhotoWrapper,
  StyledPassportWrapper,
  StyledPersonalPhotoWrapper,
  StyledResetFilter,
  StyledSelectBlock,
  StyledTitleWinActionButton,
} from './Modules.styles'

import { ReactComponent as CloseSVG } from 'assets/close.svg'

import { IPassportData, IPassportDataUpdate } from 'features/loan/types'

import { fetchAgentLoanUserPassportData } from 'features/agentLoans/extra'
import { fetchDeleteImageById, updatePassportData } from 'features/loan/extra'
import { addToast } from 'features/toast/toastSlice'
import { ToastTypes } from 'lib/toast/Toast'
import { rolesType } from 'constants/rtoles'
import { useGetAgentPhotoData } from 'features/agentLoans/agentSelectors'
import { TabsMediaFilesFilteredType } from 'pages/UnderwriterLoan/Modules/types'
import { StyledSwitchBlockWrapper } from 'pages/UnderwriterLoan/Modules/Modules.styles'
import { setFilteredAgentsImagesTab } from 'features/agentLoans/loanSlice'
import { addUndoAction, removeUndoIdAction } from 'features/undoAction/undoActionSlice'

const moduleId = 'passport'

const tabConfig = {
  [TabsMediaFilesFilteredType.AgentsSelfie]: { 0: 'По заявке', 1: 'Все' },
  [TabsMediaFilesFilteredType.AgentsRegPage]: { 0: 'По заявке', 1: 'Все' },
  [TabsMediaFilesFilteredType.AgentsMainPage]: { 0: 'По заявке', 1: 'Все' },
}

export const Passport: any = ({
  openedModules,
  onToggleModule,
  userId,
  claimsResolve,
  currentLoanIsBelongsToAgent,
  loanStatus,
  isEditable,
  loanNumber,
}): JSX.Element => {
  const dispatch = useDispatch()
  // const images = useSelector((store: GlobalState) => store.agentLoans.imageData)
  const images = useGetAgentPhotoData()
  const { userData, passportData, otherDocs } = useSelector(
    (store: GlobalState) => store.agentLoans
  )
  const { permissions } = useContext(PermissionContext)
  const activeTab = useSelector((store: GlobalState) => store.agentLoans.filteredImages)

  const [initialValues, setInitialValues] = useState<IPassportData | unknown>({})
  const [editable, setEditable] = useState<boolean>(false)
  const isOpen = openedModules.includes(moduleId)
  const [showModal, setShowModal] = useState({
    show: false,
    content: null,
  })
  const handleChangeEditable = (): void => setEditable(!editable)

  const handleCancel = (resetForm) => (): void => {
    resetForm()
    setEditable(!editable)
  }

  const handleExpand = (): void => onToggleModule(moduleId)

  useEffect(() => {
    if (passportData && !editable) {
      setInitialValues({
        ...passportData,
      })
    }
  }, [passportData, editable])

  const submitForm = async (values: IPassportData): Promise<void> => {
    setInitialValues(values)

    const {
      first_name,
      last_name,
      middle_name,
      gender,
      date_of_birth,
      place_of_birth,
      serial,
      number,
      date_of_issue,
      place_of_issue,
      code_division,
      previous_passport_data,
    } = values

    const data: IPassportDataUpdate = {
      first_name,
      last_name,
      middle_name,
      gender,
      date_of_birth: normalizeDateForApi(date_of_birth),
      place_of_birth,
      serial,
      number,
      date_of_issue: normalizeDateForApi(date_of_issue),
      place_of_issue,
      code_division,
      previous_passport_data,
    }

    dispatch(
      updatePassportData({
        id: userId,
        body: data,
        values,
        onError: () => {
          dispatch(
            addToast({
              type: ToastTypes.warning,
              title: 'Ошибка',
              description: `Данные не сохранены`,
            })
          )
        },
        onSuccess: () => {
          batch(() => {
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Успех',
                description: `Данные сохранены`,
              })
            )
            dispatch(fetchAgentLoanUserPassportData({ userId }))
          })

          setEditable(false)
        },
      })
    )
  }

  const handleCloseModal = (): void =>
    setShowModal({
      ...showModal,
      show: false,
    })

  const handleShowModal =
    ({ image }) =>
    () =>
      setShowModal({
        ...showModal,
        show: true,
        content: image,
      })

  const fetchImages = false // TODO
  const allowAction = loanStatus === LoanStatuses.on_check && currentLoanIsBelongsToAgent
  const handleTabChange = (tabType: TabsMediaFilesFilteredType, index: number) => {
    const newLoanNumber = index === 1 ? loanNumber : null
    dispatch(setFilteredAgentsImagesTab({ tab: tabType, loanNumber: newLoanNumber }))
  }

  const canImageBeRemoved = ({ imageLoanNumber }) => {
    if (imageLoanNumber === loanNumber && allowedStatusesToDeleteImageAndVideo.includes(loanStatus))
      return true
    return false
  }

  const removeImage = (e, id) => {
    e.stopPropagation()

    dispatch(
      addUndoAction({
        title: 'Отменить удаление',
        action: () =>
          dispatch(
            fetchDeleteImageById({
              image_id: id,
              user_id: userId,
              onError: () => {
                dispatch(removeUndoIdAction(id))
              },
            })
          ),
        cancelAction: () => dispatch(removeUndoIdAction(id)),
        id,
      })
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={submitForm}
      validationSchema={validationSchemaPassport}
    >
      {(formikProps) => {
        const { errors, dirty, resetForm, handleSubmit } = formikProps
        const imageContent = { ...showModal.content }

        return (
          <Module.Wrapper isOpen={isOpen} editable={editable} isEdited={false} id={moduleId}>
            <ImageModal.ModalContent
              removable={allowAction}
              userId={userId}
              isOpen={showModal.show}
              onRequestClose={handleCloseModal}
              imageContent={imageContent}
              onClose={handleCloseModal}
              type='agentLoans'
              loanStatus={loanStatus}
              isEditable={isEditable}
              claimsResolve={claimsResolve}
            />
            <Module.Header onClick={handleExpand}>
              <Module.Actions isOpen={isOpen}>
                <Module.HeadTitle>Паспортные данные</Module.HeadTitle>
                <Module.Arrow isOpen={isOpen} />
              </Module.Actions>
            </Module.Header>
            <Module.ContentWrapper>
              <AccessWrapper
                condition={permissions.includes(rolesType.regionalManager) || claimsResolve}
                access={[rolesType.agent, rolesType.regionalManager]}
              >
                <Module.Button handleChangeEditable={handleChangeEditable} />
              </AccessWrapper>
              <StyledPassportWrapper>
                <Module.ImageBlock>
                  <StyledPassportPhotoWrapper>
                    <StyledImageDescription>Основная страница</StyledImageDescription>
                    <StyledSwitchBlockWrapper>
                      <Switcher
                        tabs={tabConfig[TabsMediaFilesFilteredType.AgentsMainPage]}
                        activeTab={
                          activeTab[TabsMediaFilesFilteredType.AgentsMainPage] !== null ? 1 : 0
                        }
                        onTabChange={(index) =>
                          handleTabChange(TabsMediaFilesFilteredType.AgentsMainPage, index)
                        }
                      />
                    </StyledSwitchBlockWrapper>
                    <StyledPassportPhotoItem>
                      {fetchImages && <Module.ImageLoader />}
                      <UploadLoanImage
                        type='passport-main-page-photo'
                        userId={userData?.user_id}
                        agents
                        capture='environment'
                        loan_number={loanNumber}
                      />
                      {images &&
                        images?.personalDataPhoto.length > 0 &&
                        images.personalDataPhoto.map((image) => {
                          return (
                            <Module.ImageWrapper
                              onClick={handleShowModal({ image })}
                              key={image.image_id}
                              createdAt={image?.created_at}
                            >
                              <Module.SmallImage url={image.photo_url} />
                              {canImageBeRemoved({ imageLoanNumber: image?.loan_number }) && (
                                <Module.ImageDelete
                                  onClick={(e) => removeImage(e, image.image_id)}
                                />
                              )}
                            </Module.ImageWrapper>
                          )
                        })}
                    </StyledPassportPhotoItem>
                  </StyledPassportPhotoWrapper>
                  <StyledPersonalPhotoWrapper>
                    <StyledImageDescription>Страница регистрации</StyledImageDescription>
                    <StyledSwitchBlockWrapper>
                      <Switcher
                        tabs={tabConfig[TabsMediaFilesFilteredType.AgentsRegPage]}
                        activeTab={
                          activeTab[TabsMediaFilesFilteredType.AgentsRegPage] !== null ? 1 : 0
                        }
                        onTabChange={(index) =>
                          handleTabChange(TabsMediaFilesFilteredType.AgentsRegPage, index)
                        }
                      />
                    </StyledSwitchBlockWrapper>
                    <StyledPassportPhotoItem>
                      {fetchImages && <Module.ImageLoader />}
                      <UploadLoanImage
                        type='passport-registration-page-photo'
                        userId={userData?.user_id}
                        agents
                        capture='environment'
                        loan_number={loanNumber}
                      />
                      {images &&
                        images?.registrationDataPhoto.length > 0 &&
                        images.registrationDataPhoto.map((image) => (
                          <Module.ImageWrapper
                            onClick={handleShowModal({ image })}
                            key={image.image_id}
                            createdAt={image?.created_at}
                          >
                            <Module.SmallImage url={image.photo_url} />
                            {canImageBeRemoved({ imageLoanNumber: image?.loan_number }) && (
                              <Module.ImageDelete onClick={(e) => removeImage(e, image.image_id)} />
                            )}
                          </Module.ImageWrapper>
                        ))}
                    </StyledPassportPhotoItem>
                  </StyledPersonalPhotoWrapper>
                  <StyledPassportPhotoWrapper>
                    <StyledImageDescription>Фото с паспортом</StyledImageDescription>
                    <StyledSwitchBlockWrapper>
                      <Switcher
                        tabs={tabConfig[TabsMediaFilesFilteredType.AgentsSelfie]}
                        activeTab={
                          activeTab[TabsMediaFilesFilteredType.AgentsSelfie] !== null ? 1 : 0
                        }
                        onTabChange={(index) =>
                          handleTabChange(TabsMediaFilesFilteredType.AgentsSelfie, index)
                        }
                      />
                    </StyledSwitchBlockWrapper>
                    <StyledPassportPhotoItem>
                      <UploadLoanImage
                        type='passport-selfie'
                        userId={userData?.user_id}
                        agents
                        capture='environment'
                        loan_number={loanNumber}
                      />
                      {images &&
                        images?.passportSelfie.length > 0 &&
                        images.passportSelfie.map((image) => {
                          return (
                            <Module.ImageWrapper
                              onClick={handleShowModal({ image })}
                              key={image.image_id}
                              createdAt={image?.created_at}
                            >
                              <Module.SmallImage url={image.photo_url} />
                              {canImageBeRemoved({ imageLoanNumber: image?.loan_number }) && (
                                <Module.ImageDelete
                                  onClick={(e) => removeImage(e, image.image_id)}
                                />
                              )}
                            </Module.ImageWrapper>
                          )
                        })}
                    </StyledPassportPhotoItem>
                  </StyledPassportPhotoWrapper>
                </Module.ImageBlock>
              </StyledPassportWrapper>
              <Module.Content>
                {editable ? <Edit {...formikProps} /> : <View {...formikProps} {...otherDocs} />}
              </Module.Content>
            </Module.ContentWrapper>
            <StyledButtonBlock>
              {editable && (
                <div style={{ margin: '32px 0' }}>
                  <Button
                    disabled={isValidForm(errors) || !dirty}
                    type='standardBig'
                    onClick={() => dirty && handleSubmit()}
                    pending={null}
                  >
                    Сохранить
                  </Button>
                  <Button type='emptyBig' onClick={handleCancel(resetForm)}>
                    Отменить
                  </Button>
                </div>
              )}
            </StyledButtonBlock>
          </Module.Wrapper>
        )
      }}
    </Formik>
  )
}

const Edit = (props): JSX.Element => {
  const { values, setFieldValue, errors } = props

  const suggestionList = Object.keys(genderType).map((value) => ({
    label: genderType[value],
    value,
  }))

  return (
    <>
      <Module.Column column={1}>
        <InputField
          name='serial'
          onChange={setFieldValue}
          placeholder='Серия'
          value={values.serial}
          noMove
          mask={serialPassportCodeMask}
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='number'
          onChange={setFieldValue}
          placeholder='Номер'
          value={values.number}
          noMove
          mask={numberPassportCodeMask}
          {...props}
        />
      </Module.Column>
      <Module.Column column={2}>
        <DatePickerField
          name='date_of_issue'
          onChange={setFieldValue}
          placeholder='Дата выдачи'
          value={values.date_of_issue}
          format='dd.MM.yyyy'
          {...props}
        />
      </Module.Column>
      <Module.Column column={2}>
        <InputField
          name='place_of_issue'
          onChange={setFieldValue}
          placeholder='Место выдачи'
          value={values.place_of_issue}
          noMove
          {...props}
        />
      </Module.Column>
      <Module.Column column={2}>
        <InputField
          name='code_division'
          onChange={setFieldValue}
          placeholder='Код подразделения'
          value={values.code_division}
          noMove
          mask={divisionCodeMask}
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='last_name'
          onChange={setFieldValue}
          placeholder='Фамилия'
          value={values.last_name}
          noMove
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='first_name'
          onChange={setFieldValue}
          placeholder='Имя'
          value={values.first_name}
          noMove
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='middle_name'
          onChange={setFieldValue}
          placeholder='Отчество'
          value={values.middle_name}
          noMove
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <StyledSelectBlock>
          <Select
            data={suggestionList}
            placeholder='Семейный статус'
            value={suggestionList?.find((v) => v.value === values.gender) || ''}
            onChange={(v) => setFieldValue('gender', v.value)}
            error={!!errors?.gender}
          />
          {values.gender && (
            <StyledResetFilter onClick={() => setFieldValue('gender', '')}>
              <CloseSVG />
            </StyledResetFilter>
          )}
        </StyledSelectBlock>
      </Module.Column>
      <Module.Column column={1}>
        <DatePickerField
          name='date_of_birth'
          onChange={setFieldValue}
          placeholder='Дата рождения'
          value={values.date_of_birth}
          format='dd.MM.yyyy'
          {...props}
        />
      </Module.Column>
      <Module.Column column={2}>
        <InputField
          name='place_of_birth'
          onChange={setFieldValue}
          placeholder='Место рождения'
          value={values.place_of_birth}
          noMove
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='previous_passport_data'
          onChange={setFieldValue}
          placeholder='Предыдущий паспорт'
          value={values.previous_passport_data}
          noMove
          mask={previousPassportCodeMask}
          {...props}
        />
      </Module.Column>
    </>
  )
}

const View = (props): JSX.Element => {
  const { values, snils_number, inn_number } = props

  return (
    <>
      <>
        <Module.Column column={1}>
          <Module.Title>Серия</Module.Title>
          <Module.Value>{normalizeValues(values.serial)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Номер</Module.Title>
          <Module.Value>{normalizeValues(values.number)}</Module.Value>
        </Module.Column>
        <Module.Column column={2}>
          <Module.Title>Дата выдачи</Module.Title>
          <Module.Value>{normalizeValues(values.date_of_issue, 'date')}</Module.Value>
        </Module.Column>
        <Module.Column column={2}>
          <Module.Title>Место выдачи</Module.Title>
          <Module.Value>{normalizeValues(values.place_of_issue)}</Module.Value>
        </Module.Column>
        <Module.Column column={2}>
          <Module.Title>Код подразделения</Module.Title>
          <Module.Value>{normalizeValues(values.code_division)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Фамилия</Module.Title>
          <Module.Value>{normalizeValues(values.last_name)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Имя</Module.Title>
          <Module.Value>{normalizeValues(values.first_name)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Отчество</Module.Title>
          <Module.Value>{normalizeValues(values.middle_name)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Пол</Module.Title>
          <Module.Value>{normalizeValues(values.gender, 'gender')}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Дата рождения</Module.Title>
          <Module.Value>{normalizeValues(values.date_of_birth, 'date')}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Место рождения</Module.Title>
          <Module.Value>{normalizeValues(values.place_of_birth)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>СНИЛС</Module.Title>
          <Module.Value>{normalizeValues(snils_number)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <StyledTitleWinActionButton>
            <Module.Title>ИНН</Module.Title>
          </StyledTitleWinActionButton>
          <Module.Value>{normalizeValues(inn_number)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <StyledTitleWinActionButton>
            <Module.Title>Предыдущий паспорт</Module.Title>
          </StyledTitleWinActionButton>
          <Module.Value>{normalizeValues(values.previous_passport_data)}</Module.Value>
        </Module.Column>
      </>
    </>
  )
}
