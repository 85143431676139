/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, FieldArray } from 'formik'
import * as Yup from 'yup'

import { Button, Module } from 'components'
import { GlobalState } from 'index'
import { fetchPhonesData } from 'features/loan/extra'
import { InputField, isValidForm, normalizeValues, phoneMask } from 'helpers'
import { StyledButtonBlock } from './Modules.styles'

const PHONE_TYPES = {
  mobile: 'Мобильный',
  work: 'Рабочий',
}

const moduleId = 'phone'
export const Phones: any = ({ openedModules, onToggleModule }): JSX.Element => {
  const dispatch = useDispatch()
  const { dataPhones, isFetching } = useSelector(
    (store: GlobalState) => store.singleLoan.phonesData
  )
  const userId = useSelector((store: GlobalState) => store.singleLoan.userId)
  const [editable, setEditable] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState({ items: [] })

  const isOpen = openedModules.includes(moduleId)

  useEffect(() => {
    if (isOpen && userId && isFetching) {
      dispatch(fetchPhonesData({ id: userId }))
    }
  }, [isOpen, userId])

  useEffect(() => {
    if (dataPhones) {
      setInitialValues({ items: dataPhones })
    }
  }, [dataPhones])

  const handleCancel = (resetForm) => (): void => {
    resetForm()
    setEditable(!editable)
  }
  const handleExpand = (): void => onToggleModule(moduleId)

  const submitForm = async (values: unknown): Promise<void> => setEditable(false)

  const submitError = (handleSubmit) => (): void => handleSubmit()

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={submitForm}
      validationSchema={validationSchema}
    >
      {(formikProps) => {
        const { errors, dirty, resetForm, handleSubmit } = formikProps

        return (
          <Module.Wrapper
            isOpen={isOpen}
            editable={editable}
            isEdited={dirty && editable}
            id={moduleId}
          >
            <Module.Header onClick={handleExpand}>
              <Module.Actions isOpen={isOpen}>
                <Module.HeadTitle>Телефоны</Module.HeadTitle>
                <Module.Arrow isOpen={isOpen} />
              </Module.Actions>
            </Module.Header>
            <Module.ContentWrapper>
              <Module.Content>
                {isFetching && <Module.LoaderBlock />}
                {editable ? <Edit {...formikProps} /> : <View {...formikProps} />}
              </Module.Content>
            </Module.ContentWrapper>
            <StyledButtonBlock>
              {editable && dirty && (
                <div style={{ margin: dirty ? '32px 0' : '0px' }}>
                  <Button
                    disabled={isValidForm(errors)}
                    type='standardBig'
                    onClick={isValidForm(errors) ? submitError(handleSubmit) : handleSubmit}
                    pending={false}
                  >
                    Сохранить
                  </Button>
                  <Button type='emptyBig' onClick={handleCancel(resetForm)}>
                    Отменить
                  </Button>
                </div>
              )}
            </StyledButtonBlock>
          </Module.Wrapper>
        )
      }}
    </Formik>
  )
}

const Edit = (props): JSX.Element => {
  const { values, setFieldValue } = props

  return (
    <>
      <FieldArray
        name='items'
        render={() => (
          <>
            {values.items.map((item, index) => (
              <React.Fragment key={index}>
                <Module.Column column={4}>
                  <InputField
                    name={`items[${index}].number`}
                    onChange={setFieldValue}
                    placeholder={`${PHONE_TYPES[item?.number_type] || ''} Телефон`}
                    value={item.number}
                    mask={phoneMask}
                    noMove
                    {...props}
                  />
                </Module.Column>
              </React.Fragment>
            ))}
          </>
        )}
      />
    </>
  )
}

const View = (props): JSX.Element => {
  const { values } = props

  return (
    <>
      {values.items.map((item, index) => (
        <React.Fragment key={index}>
          <Module.Column column={4}>
            <Module.Title> {`${PHONE_TYPES[item?.number_type] || ''} Телефон`}</Module.Title>
            <Module.Value styles='styledValuePhone'>{normalizeValues(item.number)}</Module.Value>
          </Module.Column>
        </React.Fragment>
      ))}
    </>
  )
}

const validationSchema = Yup.object().shape({})
