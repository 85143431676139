/* eslint-disable import/order */
import { useSelector } from 'react-redux'
import { GlobalState } from 'index'
import { declOfNum, normalizeValues } from 'helpers'
import { LoanType } from 'features/loans/types'
import { Link } from 'react-router-dom'

import { ReactComponent as AcceptSVG } from 'assets/check.svg'
import { ReactComponent as RefuseSVG } from 'assets/delete.svg'
import { ReactComponent as CalendarSVG } from 'assets/calendar-limit.svg'
import { ReactComponent as WalletSVG } from 'assets/wallet-limit.svg'

import { Button, Module } from 'components'

import { UNDERWRITER_LOANS_ROUTE } from '../../../constants'

import {
  StyledLimitWrapper,
  StyledLimitTitle,
  StyledLimitContent,
  StyledRangeTitle,
  StyledLimitResult,
  StyledLimitItem,
  StyledResultWrapper,
  StyledUserWrapper,
  StyledTitleWrapper,
  StyledButtonsWrapper,
  StyledDate,
  StyledCommentWrapper,
} from './Modules.styles'

export const SetLimitResult: any = ({ resolve }): JSX.Element => {
  const {
    loadData: { loan_status: loanStatus },
  } = useSelector((store: GlobalState) => store.singleLoan)
  const {
    first_name: firstName = '',
    last_name: lastName = '',
    middle_name: middleName = '',
  } = useSelector((store: GlobalState) => store.auth?.user || {})

  return (
    <StyledLimitWrapper id='limit'>
      <StyledLimitTitle>Выставление лимита</StyledLimitTitle>
      <StyledLimitContent>
        <StyledLimitItem>
          <WalletSVG />
          <StyledRangeTitle>{LimitAmount[loanStatus]}</StyledRangeTitle>
          <StyledLimitResult>
            {normalizeValues(resolve?.sum_limit || resolve?.sumLimit, 'sum')}
          </StyledLimitResult>
        </StyledLimitItem>
        <StyledLimitItem>
          <CalendarSVG />
          <StyledRangeTitle>{LimitTerm[loanStatus]}</StyledRangeTitle>
          <StyledLimitResult>{`${resolve.timeLimit} ${declOfNum(resolve.timeLimit, [
            'день',
            'дня',
            'дней',
          ])}`}</StyledLimitResult>
        </StyledLimitItem>
      </StyledLimitContent>
      <StyledResultWrapper>
        {resolve.comment && (
          <StyledCommentWrapper>
            <Module.Title>Комментарий</Module.Title>
            <Module.Value>{resolve.comment}</Module.Value>
          </StyledCommentWrapper>
        )}

        <StyledTitleWrapper>
          <Module.Title>{LimitResult[loanStatus]}</Module.Title>
          {loanStatus === 'loan_approved' ? <AcceptSVG /> : <RefuseSVG />}
        </StyledTitleWrapper>
        <StyledUserWrapper>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '15px' }}>
              <Module.Title>Менеджер</Module.Title>
              <Module.Value>{`${firstName} ${lastName} ${middleName}`}</Module.Value>
            </div>
            <StyledDate>
              <Module.Title>{LimitDate[loanStatus]}</Module.Title>
              <Module.Value>{normalizeValues(resolve.resolve_date, 'date')}</Module.Value>
            </StyledDate>
          </div>
        </StyledUserWrapper>
        <StyledButtonsWrapper>
          <Link to={`${UNDERWRITER_LOANS_ROUTE}?type=${LoanType.unassigned}`}>
            <Button type='standardBig'>Перейти к свободным заявкам</Button>
          </Link>
          <Link to={`${UNDERWRITER_LOANS_ROUTE}?type=${LoanType.processing}`}>
            <Button type='emptyBig'>Перейти к моим заявкам</Button>
          </Link>
        </StyledButtonsWrapper>
      </StyledResultWrapper>
    </StyledLimitWrapper>
  )
}

enum LimitAmount {
  loan_approved = 'Согласованная андеррайтером сумма займа',
  refusal_by_the_underwriter = 'Сумма займа',
}
enum LimitTerm {
  loan_approved = 'Согласованная андеррайтером срок займа',
  refusal_by_the_underwriter = 'Срок займа',
}
enum LimitResult {
  loan_approved = 'Займ одобрен',
  refusal_by_the_underwriter = 'В займе отказано',
}
enum LimitDate {
  loan_approved = 'Дата принятия заявки',
  refusal_by_the_underwriter = 'Дата отказа в заявке',
}
