/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit'

import { CommonDocumentsService, OtherDocumentsListPayload } from 'services/commonDocumentService'

import { OtherDocumentsResponse } from './types'

interface WithCallbacks {
  onSuccess?: () => void
  onError?: () => void
}

interface OtherDocumentsListThunk extends OtherDocumentsListPayload, WithCallbacks {}

export const getOtherDocumentsList = createAsyncThunk<
  OtherDocumentsResponse[],
  OtherDocumentsListThunk
>('otherDocuments/getOtherDocumentsList', async (props) => {
  const { user_id, onError, onSuccess } = props
  const response = await CommonDocumentsService.getOtherDocumentsList({
    user_id,
  })
    .then(({ data }) => {
      onSuccess && onSuccess()
      return data
    })
    .catch((e) => {
      onError && onError()
      throw new Error(e)
    })
  return response
})
