import { rolesType } from 'constants/rtoles'
import { loanStatuses } from 'helpers/dictionary'

export interface IModuleProps {
  loanStatus: keyof typeof loanStatuses | string
  loan_id: string
  loanNumber: string
  openedModules: string[]
  onToggleModule: (anchor: string) => void
  creditProduct: string
  claimsResolve: boolean
  sameAddress?: boolean
  userId?: string
  customerId?: number
  role?: keyof typeof rolesType
  inPassport?: string
  isEditable?: boolean
  loanBalance?: string
  claims?: string
  loanId?: string
}

export enum TabsMediaFilesFilteredType {
  MainPage,
  RegPage,
  Selfie,
  VideoVerification,
  BankCards,
  AgentPhoto,
  AgentScans,
  SaasDocuments,
  PzDocuments,
  SignatureQuestionnairesDocuments,
  AgentsMainPage,
  AgentsRegPage,
  AgentsSelfie,
  ContractsMainPage,
  ContractsRegPage,
  ContractsSelfie,
  ContractsScans,
  OtherDocuments,
  BankruptDocuments,
}
