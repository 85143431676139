/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
// import { ITableProps, ITableStatus } from './types'

import InfiniteScroll from 'react-infinite-scroll-component'
import StickyBox from 'react-sticky-box'
import { batch, useDispatch, useSelector } from 'react-redux'
import { MouseEvent, useCallback, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import {
  Button,
  EmptyValueReplacer,
  Loader,
  ModalConfirm,
  ScrollToTop,
  Select,
  TableHead,
} from 'components'
import { AllView, CheckedView, CheckingView, RequiredDocView } from './MobileView'
import { AgentLoanType, AgentLoanTypeValue } from 'features/agentLoans/types'
import { GlobalState } from 'index'

import { BODY_STRUCTURE, COLUMN_TEMPLATE, HEAD_STRUCTURE } from './tableStructure'
import {
  StyledMessagesWrapper,
  StyledRowWrapper,
  StyledStatus,
  StyledTitle,
  StyledValue,
  StyledValueCount,
  StyledCopyLink,
  StyledCreateDate,
  StyledInfo,
} from './Table.styles'
import { HiOutlineClipboardCopy } from 'react-icons/hi'

import {
  StaledContainer,
  StyledEmptyLabel,
  StyledInfiniteLoader,
  StyledTableWrapper,
  StyledSelectAgent,
} from '../AgentPage.styles'

import { ReactComponent as MessagesSVG } from 'assets/messages.svg'
import { ReactComponent as CopySuccessSVG } from 'assets/copy_link_success.svg'
import { copyToClipboard, formatDate, getAdminFio, loanStatuses } from 'helpers'
import { useGetAgentUserNamesWithIds } from 'features/dict/dictSelectors'
import { changeAgent, fetchAgentLoans } from 'features/agentLoans/extra'
import { addToast } from 'features/toast/toastSlice'
import { ToastTypes } from 'lib/toast/Toast'

interface IShowModal {
  title: string
  description: string
  show: boolean
}

const initialModalState = { title: '', description: '', action: null, show: false }

export const Table = (props: any): JSX.Element => {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState<IShowModal>(initialModalState)
  const dictData = useSelector((state: GlobalState) => state.dict)
  const { type, fetchMoreData, checkHasMore, loans } = props

  const [customerPhone, setCustomerPhone] = useState<string>('')
  const [assignAgent, setAssignAgent] = useState<string>('')
  const agentUserNamesWithIds = useGetAgentUserNamesWithIds() || []
  const AgentUsernamesList = agentUserNamesWithIds.map((value) => ({
    label: getAdminFio(value.username, dictData),
    value: value.username,
  }))
  const { data: filters } = useSelector((store: GlobalState) => store.agentFilters)

  const history = useHistory()
  const { path } = useRouteMatch()

  const handleOpenLoan = (e, id: string) => {
    e.preventDefault()
    history.push(`${path}/${id}`)
  }

  const handleOpenComments = (e: MouseEvent<HTMLElement>): void => {
    e.stopPropagation()
    console.log('') // TODO
  }

  const handleCopyLink = (e: MouseEvent<HTMLElement>): void => {
    e.stopPropagation()
    console.log('') // TODO
  }

  const dataList = Object.values(loans[type]?.data || {})
  const getAgentName = (loan) => getAdminFio(loan.agent_username, dictData) || '-'
  const getAgentManagerName = (loan) => getAdminFio(loan.agent_manager_username, dictData) || '-'

  const mobileItems = useCallback(
    (loan) => ({
      [AgentLoanType.requiredDocs]: (
        <RequiredDocView
          loan={loan}
          onChangeAgent={handleChangeAgentModal(loan?.users_login)}
          agentManagerName={getAgentManagerName(loan)}
          agentName={getAgentName(loan)}
        />
      ),
      [AgentLoanType.checking]: (
        <CheckingView
          loan={loan}
          agentManagerName={getAgentManagerName(loan)}
          agentName={getAgentName(loan)}
        />
      ),
      [AgentLoanType.checked]: (
        <CheckedView
          loan={loan}
          agentManagerName={getAgentManagerName(loan)}
          agentName={getAgentName(loan)}
        />
      ),
      [AgentLoanType.allApp]: (
        <AllView
          loan={loan}
          agentManagerName={getAgentManagerName(loan)}
          agentName={getAgentName(loan)}
        />
      ),
      [AgentLoanType.atWork]: (
        <CheckingView
          loan={loan}
          agentManagerName={getAgentManagerName(loan)}
          agentName={getAgentName(loan)}
        />
      ),
      [AgentLoanType.customerRevision]: (
        <CheckingView
          loan={loan}
          agentManagerName={getAgentManagerName(loan)}
          agentName={getAgentName(loan)}
        />
      ),
    }),
    [dataList]
  )

  const fetchAgentChange = () => {
    if (!assignAgent) return
    dispatch(
      changeAgent({
        phone: customerPhone,
        agent_id: agentUserNamesWithIds.find((x) => x.username === assignAgent)?.admin_id || '',
        errorAction: () => {
          setShowModal(initialModalState)
          dispatch(
            addToast({
              type: ToastTypes.warning,
              title: 'Ошибка',
              description: `Передача клиента прошла с ошибкой`,
            })
          )
        },
        successAction: () => {
          setShowModal(initialModalState)
          batch(() => {
            dispatch(
              fetchAgentLoans({
                iteration: loans[type]?.iteration + 1,
                type: AgentLoanTypeValue.on_check,
                filters,
              })
            )
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Успех',
                description: `Передача клиента прошла успешно`,
              })
            )
          })
        },
      })
    )
  }
  const handleChangeAgentModal = (phone) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    setCustomerPhone(phone)
    setShowModal({
      show: true,
      title: 'Перезакрепление клиента.',
      description: 'Выберите агента для перезакрепления',
    })
  }

  const copy = (e, column, value) => {
    if (!column.copy) {
      return
    }
    e.stopPropagation()
    e.preventDefault()
    copyToClipboard(value)
      .then(() => {
        dispatch(
          addToast({
            type: ToastTypes.success,
            title: 'Успех',
            description: `Данные скопированны`,
          })
        )
      })
      .catch((err) => {
        console.log('Something went wrong', err)
      })
  }
  return (
    <div style={{ overflow: 'auto' }} id='scrollable'>
      <ScrollToTop scrollTo='#scrollable' />
      <StickyBox style={{ zIndex: 1 }}>
        <TableHead structure={HEAD_STRUCTURE} type={type} template={COLUMN_TEMPLATE[type]} />
      </StickyBox>
      <StyledTableWrapper>
        <>
          <InfiniteScroll
            dataLength={dataList?.length || 0}
            next={fetchMoreData}
            hasMore={checkHasMore()}
            loader={
              <StyledInfiniteLoader>
                <Loader />
              </StyledInfiniteLoader>
            }
            scrollableTarget='scrollable'
          >
            {loans && dataList.length ? (
              dataList?.map((loanData: any) => {
                const adminName = getAdminFio(
                  loanData.admin_username.length > 0 ? loanData.admin_username[0] : '',
                  dictData
                )
                const agentName = getAdminFio(loanData.agent_username, dictData)
                const agentManagerName = getAdminFio(loanData.agent_manager_username, dictData)

                const loan = {
                  ...loanData,
                  admin_username: adminName || '',
                  agent_username: agentName || '',
                  agent_manager_username: agentManagerName || '',
                }

                return (
                  <StaledContainer key={`${loan.loans_loan_id}`}>
                    <>
                      <StyledRowWrapper
                        indicate={+loan.closed_count > 0}
                        onClick={(e) => handleOpenLoan(e, loan.loans_loan_id)}
                        template={COLUMN_TEMPLATE[type]}
                        href={`${path}/${loan.loans_loan_id}`}
                      >
                        {BODY_STRUCTURE[type]?.map((column) => {
                          return (
                            <StyledValue
                              key={column.id}
                              onClick={(e) => copy(e, column, loan?.[column?.name])}
                            >
                              <StyledTitle pending={loan.pending}>
                                {column?.status && (
                                  <>
                                    <StyledStatus status={loan.loan_statuses_status_name} />
                                    {loanStatuses[loan.loan_statuses_status_name]}
                                  </>
                                )}
                                {column.value
                                  ? column.value(loan)
                                  : loan[column.name] || <EmptyValueReplacer />}
                                {column.copy && (
                                  <StyledInfo>
                                    <HiOutlineClipboardCopy />
                                  </StyledInfo>
                                )}
                              </StyledTitle>

                              {column?.link && (
                                <StyledCopyLink onClick={(e) => handleCopyLink(e)}>
                                  <CopySuccessSVG />
                                  Ссылка скопирована
                                </StyledCopyLink>
                              )}
                              {column?.message && (
                                <StyledMessagesWrapper onClick={(e) => handleOpenComments(e)}>
                                  <MessagesSVG />
                                  <StyledValueCount>5</StyledValueCount>
                                </StyledMessagesWrapper>
                              )}
                              {column?.createDate && (
                                <StyledCreateDate>
                                  {formatDate(loan?.loans_loan_date_create)}
                                </StyledCreateDate>
                              )}
                              {column?.transfer && (
                                <Button
                                  type='border'
                                  onClick={handleChangeAgentModal(loan?.users_login)}
                                >
                                  Передать клиента
                                </Button>
                              )}
                            </StyledValue>
                          )
                        })}
                        {mobileItems(loan)[type]}
                      </StyledRowWrapper>
                    </>
                  </StaledContainer>
                )
              })
            ) : (
              <StyledEmptyLabel>
                {!loans[type]?.pending && <p>Ничего не найдено</p>}
              </StyledEmptyLabel>
            )}
          </InfiniteScroll>
        </>
      </StyledTableWrapper>
      <ModalConfirm
        isOpen={showModal.show}
        onRequestClose={() => setShowModal({ ...showModal, show: false })}
        onClose={() => setShowModal({ ...showModal, show: false })}
        description={showModal.description}
        title={showModal.title}
        buttons={false}
        content={() => (
          <div>
            <StyledSelectAgent>
              <Select
                data={AgentUsernamesList}
                placeholder='Агент'
                value={AgentUsernamesList?.find((v) => v.value === assignAgent) || ''}
                onChange={({ value }) => setAssignAgent(value)}
              />
            </StyledSelectAgent>

            <Button type='standardBig' onClick={fetchAgentChange} disabled={!assignAgent}>
              Перезакрепить
            </Button>
            <Button type='emptyBig' onClick={() => setShowModal(initialModalState)}>
              Отмена
            </Button>
          </div>
        )}
      />
    </div>
  )
}
