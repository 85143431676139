/* eslint-disable indent */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useContext, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Module, Switcher, UploadLoanImage } from 'components'
import { addUndoAction, removeUndoIdAction } from 'features/undoAction/undoActionSlice'
import { ImageModal } from 'containers'
import { GlobalState } from 'index'
import { StyledPassportWrapper } from './Modules.styles'
import { fetchDeleteImageById } from 'features/loan/extra'
import { allowedStatusesToDeleteImageAndVideo, LoanStatuses } from 'helpers'
import { rolesType } from 'constants/rtoles'
import { PermissionContext } from 'app/contexts'
import {
  StyledImageWrapper,
  StyledSwitchBlockWrapper,
} from 'pages/UnderwriterLoan/Modules/Modules.styles'
import { useGetAgentPhotoData } from 'features/agentLoans/agentSelectors'
import { TabsMediaFilesFilteredType } from 'pages/UnderwriterLoan/Modules/types'
import { setFilteredAgentsImagesTab } from 'features/agentLoans/loanSlice'
import { Box } from 'shared'

const moduleId = 'form'

const tabConfig = {
  [TabsMediaFilesFilteredType.AgentScans]: { 0: 'По заявке', 1: 'Все' },
}

export const Agreement: any = ({
  openedModules,
  onToggleModule,
  userId,
  loanStatus,
  currentLoanIsBelongsToAgent,
  loanNumber,
}): JSX.Element => {
  const dispatch = useDispatch()
  const { permissions } = useContext(PermissionContext)
  const images = useGetAgentPhotoData()
  const { userData } = useSelector((store: GlobalState) => store.agentLoans)
  const activeTab = useSelector((store: GlobalState) => store.agentLoans.filteredImages)
  const isOpen = openedModules.includes(moduleId)
  const [, , deleteImages] = useSelector((state: GlobalState) => state.undoAction)
  const [showModal, setShowModal] = useState({
    show: false,
    content: null,
  })

  const handleExpand = (): void => onToggleModule(moduleId)

  const handleCloseModal = (): void =>
    setShowModal({
      ...showModal,
      show: false,
    })

  const handleShowModal =
    ({ image }) =>
    () =>
      setShowModal({
        ...showModal,
        show: true,
        content: image,
      })

  const fetchImages = false // TODO
  const allowAction =
    loanStatus === LoanStatuses.on_check &&
    (currentLoanIsBelongsToAgent || permissions.includes(rolesType.regionalManager))
  const imageContent = { ...showModal.content }

  const imageModalProps = {
    isOpen: showModal.show,
    onRequestClose: handleCloseModal,
    imageContent,
    onClose: handleCloseModal,
    userId,
    type: 'agentLoans',
    removable: allowAction,
  }

  const removeImage = (e, id) => {
    e.stopPropagation()

    dispatch(
      addUndoAction({
        title: 'Отменить удаление',
        action: () =>
          dispatch(
            fetchDeleteImageById({
              image_id: id,
              user_id: userId,
              onError: () => {
                dispatch(removeUndoIdAction(id))
              },
            })
          ),
        cancelAction: () => dispatch(removeUndoIdAction(id)),
        id,
      })
    )
  }

  const handleTabChange = (tabType: TabsMediaFilesFilteredType, index: number) => {
    const newLoanNumber = index === 1 ? loanNumber : null
    dispatch(setFilteredAgentsImagesTab({ tab: tabType, loanNumber: newLoanNumber }))
  }

  const canImageBeRemoved = ({ imageLoanNumber }) => {
    if (imageLoanNumber === loanNumber && allowedStatusesToDeleteImageAndVideo.includes(loanStatus))
      return true
    return false
  }

  return (
    <Module.Wrapper isOpen={isOpen} editable={false} isEdited={false} id={moduleId}>
      <ImageModal.ModalContent {...imageModalProps} />
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <Module.HeadTitle>Скан документов</Module.HeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        <Box margin={'0 30px'} maxWidth={'300px'}>
          <StyledSwitchBlockWrapper>
            <Switcher
              tabs={tabConfig[TabsMediaFilesFilteredType.AgentScans]}
              activeTab={activeTab[TabsMediaFilesFilteredType.AgentScans] !== null ? 1 : 0}
              onTabChange={(index) => handleTabChange(TabsMediaFilesFilteredType.AgentScans, index)}
            />
          </StyledSwitchBlockWrapper>
        </Box>
        <StyledPassportWrapper>
          <Module.ImageBlock>
            <StyledImageWrapper>
              {fetchImages && <Module.ImageLoader />}
              <UploadLoanImage
                type='agent-photo'
                userId={userData?.user_id}
                loan_number={loanNumber}
                agents
                capture='environment'
              />
              {images &&
                images?.agentDataPhoto.length > 0 &&
                images.agentDataPhoto.map((image) => (
                  <Module.ImageWrapper
                    onClick={handleShowModal({ image })}
                    key={image.image_id}
                    hide={deleteImages.includes(image.image_id)}
                    createdAt={image?.created_at}
                  >
                    <Module.SmallImage url={image.photo_url} />
                    {canImageBeRemoved({ imageLoanNumber: image?.loan_number }) && (
                      <Module.ImageDelete onClick={(e) => removeImage(e, image.image_id)} />
                    )}
                  </Module.ImageWrapper>
                ))}
            </StyledImageWrapper>
          </Module.ImageBlock>
        </StyledPassportWrapper>
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}
