/* eslint-disable indent */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react'
import { useSelector } from 'react-redux'

import { Module, Switcher } from 'components'
import { GlobalState } from 'index'

import { normalizeValues } from 'helpers'

import {
  StyledBKIDocumentsWrapper,
  StyledDocumentBlockWrapper,
  StyledDocumentTitle,
  StyledSwitchBlockWrapper,
} from './Modules.styles'
import { IModuleProps, TabsMediaFilesFilteredType } from './types'
import { FaRegFileAlt } from 'react-icons/fa'
import { Box } from 'shared'

const moduleId = 'otherDocuments'

const tabConfig = {
  [TabsMediaFilesFilteredType.OtherDocuments]: { 0: 'По заявке', 1: 'Все' },
}

export const OtherDocuments: React.FC<IModuleProps> = ({
  openedModules,
  onToggleModule,
  loanNumber,
}): JSX.Element => {
  const [loader, setLoader] = useState<{ [key: string]: boolean }>({})
  const [activeTab, setActiveTab] = useState<any>({
    [TabsMediaFilesFilteredType.OtherDocuments]: 0,
  })

  const { loadable, otherDocumentsList } = useSelector((store: GlobalState) => store.otherDocuments)

  const handleExpand = (): void => {
    onToggleModule(moduleId)
  }

  const isOpen = openedModules.includes(moduleId)

  const filteredOtherDocuments = otherDocumentsList?.filter((doc) =>
    activeTab[TabsMediaFilesFilteredType.OtherDocuments] === 0
      ? doc.loan_number === loanNumber
      : true
  )

  const handleDownloadOthersDocument = async ({ photo_url, image_id }) => {
    setLoader({
      ...loader,
      [image_id]: true,
    })

    try {
      window.open(photo_url, '_blank')
    } catch (error) {
      console.error('Ошибка при открытии документа:', error)
    } finally {
      setLoader({
        ...loader,
        [image_id]: false,
      })
    }
  }

  const handleTabChange = (tabType: TabsMediaFilesFilteredType, index: number) => {
    setActiveTab((prev) => ({
      ...prev,
      [tabType]: index,
    }))
  }

  return (
    <Module.Wrapper isOpen={isOpen} id={moduleId}>
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <Module.HeadTitle>Другие документы</Module.HeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        <>
          <StyledBKIDocumentsWrapper>
            <b>Другие документы:</b>
            <Box margin={'10px 0'}>
              <StyledSwitchBlockWrapper>
                <Switcher
                  tabs={tabConfig[TabsMediaFilesFilteredType.OtherDocuments]}
                  activeTab={activeTab[TabsMediaFilesFilteredType.OtherDocuments]}
                  onTabChange={(index) =>
                    handleTabChange(TabsMediaFilesFilteredType.OtherDocuments, index)
                  }
                />
              </StyledSwitchBlockWrapper>
            </Box>
            {(loadable?.otherDocumentsList || loadable?.pzDocumentsList) && <Module.LoaderBlock />}
            {filteredOtherDocuments?.map((v, index) => (
              <StyledDocumentBlockWrapper key={index}>
                <StyledDocumentTitle
                  loadable={loader[v?.image_id]}
                  key={index}
                  onClick={() =>
                    handleDownloadOthersDocument({
                      photo_url: v?.photo_url,
                      image_id: v?.image_id,
                    })
                  }
                >
                  <FaRegFileAlt style={{ marginRight: '5px' }} />
                  {`${v?.object_name} / ${normalizeValues(v?.created_at, 'date')}`}
                </StyledDocumentTitle>
              </StyledDocumentBlockWrapper>
            ))}
            <br />
          </StyledBKIDocumentsWrapper>
        </>
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}
