/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react/no-array-index-key */
import { rolesType } from 'constants/rtoles'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch, batch } from 'react-redux'
import { Formik, FieldArray } from 'formik'

import { Button, UploadLoanImage, ModalConfirm, Module, Switcher } from 'components'
import { ImageModal } from 'containers'
import { DatePickerField, InputField } from 'helpers/form'
import {
  AccessWrapper,
  allowedStatusesToDeleteImageAndVideo,
  isValidForm,
  normalizeValues,
  validationSchemaCardsInput,
} from 'helpers'
import { GlobalState } from 'index'

import { fetchBankCardData, fetchDeleteImageById, deleteBankCardData } from 'features/loan/extra'
import { useGetLoanBankCardsData, useGetPhotoData } from 'features/loan/loanSelectors'
import { addUndoAction, removeUndoIdAction } from 'features/undoAction/undoActionSlice'
import { addToast } from 'features/toast/toastSlice'
import { ToastTypes } from 'lib/undo/Undo'

import { StyledHeadTitle, StyledButtonBlock, StyledSwitchBankCardBlock } from './Modules.styles'
import { IModuleProps, TabsMediaFilesFilteredType } from './types'
import { PermissionContext } from 'app/contexts'
import { StyledPhotoItem, StyledPhotoWrapper } from 'components/Module/Module.styles'
import { setFilteredImagesTab } from 'features/loan/singleLoanSlice'

const moduleId = 'cards'

const tabConfig = {
  [TabsMediaFilesFilteredType.BankCards]: { 0: 'По заявке', 1: 'Все' },
}

export const BankCards: React.FC<IModuleProps> = ({
  openedModules,
  onToggleModule,
  claimsResolve,
  loanStatus,
  loanNumber,
  userId,
  role,
}): JSX.Element => {
  const dispatch = useDispatch()
  const { permissions } = useContext(PermissionContext)

  const {
    bankCardsFetch,
    loanImageDataFetch: { bankCardDataPhotoFetching },
  } = useSelector((store: GlobalState) => store.singleLoan)
  const activeTab = useSelector((store: GlobalState) => store.singleLoan.filteredImages)
  const [editable, setEditable] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState({ cards: [] })
  const [showModal, setShowModal] = useState({
    show: false,
    content: null,
  })

  const data = useGetLoanBankCardsData()
  const images = useGetPhotoData()
  const isOpen = openedModules.includes(moduleId)
  const [, , deleteImages] = useSelector((state: GlobalState) => state.undoAction)

  useEffect(() => {
    if (isOpen && userId && bankCardsFetch) dispatch(fetchBankCardData({ id: userId }))
  }, [isOpen, userId])

  useEffect(() => {
    if (data) setInitialValues({ cards: data })
  }, [data])

  const handleCancel = (resetForm) => () => {
    resetForm()
    setEditable(!editable)
  }

  const handleExpand = (): void => onToggleModule(moduleId)

  const submitForm = async () => setEditable(false)

  const submitError = (handleSubmit) => (): void => handleSubmit()

  const handleCloseModal = (): void =>
    setShowModal({
      ...showModal,
      show: false,
    })

  const handleShowModal =
    ({ image }) =>
    () =>
      setShowModal({
        ...showModal,
        show: true,
        content: image,
      })

  const handleTabChange = (tabType: TabsMediaFilesFilteredType, index: number) => {
    const newLoanNumber = index === 1 ? loanNumber : null
    dispatch(setFilteredImagesTab({ tab: tabType, loanNumber: newLoanNumber }))
  }

  const removeImage = (e, id) => {
    e.stopPropagation()

    dispatch(
      addUndoAction({
        title: 'Отменить удаление',
        action: () =>
          dispatch(
            fetchDeleteImageById({
              image_id: id,
              user_id: userId,
              onError: () => {
                dispatch(removeUndoIdAction(id))
              },
            })
          ),
        cancelAction: () => dispatch(removeUndoIdAction(id)),
        id,
      })
    )
  }

  const canImageBeRemoved = ({ imageLoanNumber }) => {
    if (imageLoanNumber === loanNumber && allowedStatusesToDeleteImageAndVideo.includes(loanStatus))
      return true
    return false
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={submitForm}
      validationSchema={validationSchemaCardsInput}
    >
      {(formikProps) => {
        const { errors, dirty, resetForm, handleSubmit } = formikProps
        const imageContent = { ...showModal.content }

        return (
          <Module.Wrapper
            isOpen={isOpen}
            editable={editable}
            isEdited={dirty && editable}
            id={moduleId}
          >
            <ImageModal.ModalContent
              userId={userId}
              isOpen={showModal.show}
              onRequestClose={handleCloseModal}
              imageContent={imageContent}
              onClose={handleCloseModal}
              claimsResolve={permissions.includes(rolesType.regionalManager) || claimsResolve}
              type='bankCard'
            />
            <Module.Header onClick={handleExpand}>
              <Module.Actions isOpen={isOpen}>
                <StyledHeadTitle>
                  Банковские карты{' '}
                  {initialValues.cards.length === 0 ? (
                    <span style={{ color: 'gray', opacity: '0.5' }} />
                  ) : null}{' '}
                </StyledHeadTitle>
                <Module.Arrow isOpen={isOpen} />
              </Module.Actions>
            </Module.Header>
            <Module.ContentWrapper>
              <Module.ImageBlock>
                <StyledPhotoWrapper>
                  <StyledSwitchBankCardBlock>
                    <Switcher
                      tabs={tabConfig[TabsMediaFilesFilteredType.BankCards]}
                      activeTab={activeTab[TabsMediaFilesFilteredType.BankCards] !== null ? 1 : 0}
                      onTabChange={(index) =>
                        handleTabChange(TabsMediaFilesFilteredType.BankCards, index)
                      }
                    />
                  </StyledSwitchBankCardBlock>
                  <StyledPhotoItem>
                    {bankCardDataPhotoFetching && <Module.ImageLoader />}
                    <AccessWrapper
                      access={[rolesType.agent, rolesType.regionalManager]}
                      condition={permissions.includes(rolesType.regionalManager) || claimsResolve}
                    >
                      <UploadLoanImage
                        type='bank-card-photo'
                        userId={userId}
                        capture='environment'
                        loan_number={loanNumber}
                      />
                    </AccessWrapper>
                    {images &&
                      images?.bankCardDataPhoto.length > 0 &&
                      images.bankCardDataPhoto.map((image) => (
                        <Module.ImageWrapper
                          onClick={handleShowModal({ image })}
                          key={image.image_id}
                          hide={deleteImages.includes(image.image_id)}
                          createdAt={image.created_at}
                        >
                          <Module.SmallImage url={image.photo_url} />

                          {canImageBeRemoved({ imageLoanNumber: image?.loan_number }) && (
                            <Module.ImageDelete onClick={(e) => removeImage(e, image.image_id)} />
                          )}
                        </Module.ImageWrapper>
                      ))}
                  </StyledPhotoItem>
                </StyledPhotoWrapper>
              </Module.ImageBlock>

              <Module.Content>
                {bankCardsFetch && <Module.LoaderBlock />}
                {editable ? (
                  <Edit {...formikProps} />
                ) : (
                  <View
                    {...formikProps}
                    userId={userId}
                    loanStatus={loanStatus}
                    claimsResolve={claimsResolve}
                    role={role}
                  />
                )}
              </Module.Content>
            </Module.ContentWrapper>
            <StyledButtonBlock>
              {editable && dirty && (
                <div style={{ margin: dirty ? '32px 0' : '0px' }}>
                  <Button
                    disabled={isValidForm(errors)}
                    type='standardBig'
                    onClick={isValidForm(errors) ? submitError(handleSubmit) : handleSubmit}
                    pending={false}
                  >
                    Сохранить
                  </Button>
                  <Button type='emptyBig' onClick={handleCancel(resetForm)}>
                    Отменить
                  </Button>
                </div>
              )}
            </StyledButtonBlock>
          </Module.Wrapper>
        )
      }}
    </Formik>
  )
}

const Edit = (props) => {
  const { values, setFieldValue } = props

  return (
    <>
      <FieldArray
        name='cards'
        render={
          (/* arrayHelpers */) => (
            <>
              {values.cards.map((card, index) => (
                <React.Fragment key={index}>
                  <Module.Column column={1}>
                    <InputField
                      name={`cards[${index}].card_number`}
                      onChange={setFieldValue}
                      placeholder='Карта'
                      value={card.card_number}
                      {...props}
                    />
                  </Module.Column>
                  <Module.Column column={1}>
                    <InputField
                      name={`cards[${index}].card_holder`}
                      onChange={setFieldValue}
                      placeholder='Держатель карты'
                      value={card.card_holder}
                      {...props}
                    />
                  </Module.Column>
                  <Module.Column column={1}>
                    <DatePickerField
                      name={`cards[${index}].card_added_at`}
                      onChange={setFieldValue}
                      placeholder='Карта добавлена'
                      value={card.card_added_at}
                      format='dd.MM.yyyy'
                      {...props}
                    />
                  </Module.Column>
                  <Module.Column column={1}>
                    <DatePickerField
                      name={`cards[${index}].card_expiration_date`}
                      onChange={setFieldValue}
                      placeholder='Срок действия'
                      value={card.card_expiration_date}
                      format='dd.MM.yyyy'
                      {...props}
                    />
                  </Module.Column>
                </React.Fragment>
              ))}
            </>
          )
        }
      />
    </>
  )
}
interface IModal {
  show: boolean
  title: string
  action?: any
  style: string
}

const View = (props) => {
  const { values, userId, claimsResolve, role } = props
  const dispatch = useDispatch()
  const { permissions } = useContext(PermissionContext)

  const [showModal, setShowModal] = useState<IModal>({
    show: false,
    title: '',
    action: null,
    style: '',
  })

  const handleCloseModal = (): void =>
    setShowModal({ show: false, title: '', action: null, style: '' })

  const handleRemoveCard = (cardId: string, cardNumber: string): void => {
    const action = (): void => {
      dispatch(
        deleteBankCardData({
          id: cardId,
          successAction: () => {
            batch(() => {
              dispatch(fetchBankCardData({ id: userId }))
              dispatch(
                addToast({
                  type: ToastTypes.success,
                  title: 'Успех',
                  description: 'Карта была удалена',
                })
              )
            })
          },
          errorAction: () =>
            dispatch(
              addToast({
                type: ToastTypes.danger,
                title: 'Ошибка',
                description: 'Карта не удалена',
              })
            ),
        })
      )
    }
    setShowModal({
      show: true,
      title: ` Удалить карту ${cardNumber} ?`,
      action,
      style: 'warning',
    })
  }

  return (
    <>
      {values.cards.map((card, index) => (
        <React.Fragment key={index}>
          <ModalConfirm
            isOpen={showModal.show}
            onRequestClose={handleCloseModal}
            onClose={handleCloseModal}
            title={showModal.title}
            action={showModal.action}
            style={showModal.style}
          />
          <Module.Column column={1}>
            <Module.Title>Карта</Module.Title>
            <Module.Value>{card.card_number}</Module.Value>
          </Module.Column>
          <Module.Column column={3}>
            <Module.Title>Держатель карты</Module.Title>
            <Module.Value>{card.card_holder}</Module.Value>
          </Module.Column>
          <Module.Column column={1}>
            <Module.Title>Карта добавлена</Module.Title>
            <Module.Value>{normalizeValues(card.card_added_at, 'date')}</Module.Value>
          </Module.Column>
          <Module.Column column={3}>
            <Module.Title>Срок действия</Module.Title>
            <Module.Value>{normalizeValues(card.card_expiration_date, 'date')}</Module.Value>
          </Module.Column>
          <AccessWrapper
            access={[rolesType.agent, rolesType.regionalManager]}
            condition={permissions.includes(rolesType.regionalManager) || claimsResolve}
          >
            <Module.Column column={4}>
              <Button
                onClick={() => handleRemoveCard(card?.card_id, card?.card_number)}
                type='bigDanger'
              >
                Удалить карту
              </Button>
            </Module.Column>
          </AccessWrapper>
        </React.Fragment>
      ))}
    </>
  )
}
