import { css, FlattenSimpleInterpolation } from 'styled-components'

interface SpacingProps {
  padding?: string | number
  margin?: string | number
}

interface SizeProps {
  width?: string | number
  height?: string | number
  minWidth?: string | number
  maxWidth?: string | number
  minHeight?: string | number
  maxHeight?: string | number
}

interface BorderProps {
  border?: string | number
  borderRadius?: string | number
}

interface DisplayProps {
  display?: string
  gap?: string | number
}

interface FlexboxProps {
  alignItems?: string
  justifyContent?: string
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse'
  flex?: string | number
}

/**
 * Объединяем все типы
 */
export type StyledProps = SpacingProps & SizeProps & BorderProps & DisplayProps & FlexboxProps

/**
 * Генерация CSS для отступов.
 */
export const spacing = (props: SpacingProps): FlattenSimpleInterpolation => css`
  ${props.padding ? `padding: ${props.padding};` : ''}
  ${props.margin ? `margin: ${props.margin};` : ''}
`

/**
 * Генерация CSS для размеров.
 */
export const size = (props: SizeProps): FlattenSimpleInterpolation => css`
  ${props.width !== undefined ? `width: ${props.width};` : ''}
  ${props.height !== undefined ? `height: ${props.height};` : ''}
  ${props.minWidth !== undefined ? `min-width: ${props.minWidth};` : ''}
  ${props.maxWidth !== undefined ? `max-width: ${props.maxWidth};` : ''}
  ${props.minHeight !== undefined ? `min-height: ${props.minHeight};` : ''}
  ${props.maxHeight !== undefined ? `max-height: ${props.maxHeight};` : ''}
`

/**
 * Генерация CSS для границ.
 */
export const border = (props: BorderProps): FlattenSimpleInterpolation => css`
  ${props.border ? `border: ${props.border};` : ''}
  ${props.borderRadius ? `border-radius: ${props.borderRadius};` : ''}
`

/**
 * Генерация CSS для дисплея.
 */
export const display = (props: DisplayProps): FlattenSimpleInterpolation => css`
  ${props.display ? `display: ${props.display};` : ''}
  ${props.gap !== undefined ? `gap: ${props.gap};` : ''}
`

/**
 * Генерация CSS для flexbox.
 */
export const flexbox = (props: FlexboxProps): FlattenSimpleInterpolation => css`
  ${props.alignItems ? `align-items: ${props.alignItems};` : ''}
  ${props.justifyContent ? `justify-content: ${props.justifyContent};` : ''}
  ${props.flexDirection ? `flex-direction: ${props.flexDirection};` : ''}
  ${props.flexWrap ? `flex-wrap: ${props.flexWrap};` : ''}
  ${props.flex !== undefined ? `flex: ${props.flex};` : ''}
`
