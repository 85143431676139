/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, batch, useSelector } from 'react-redux'
import StickyBox from 'react-sticky-box'

import { Loader, UserInformation, Navigation, ScrollToTop } from 'components'
import { INavigationItem } from 'components/Navigation/Navigation'
import { HeadInfo, Head } from './Head'
import {
  fetchAgentDataPhoto,
  fetchContractPassportSelfieDataPhoto,
  fetchContractsUserPassportData,
  fetchContractsUserPersonalData,
  fetchLoanPageById,
  fetchLoanTransactions,
  fetchPersonalDataPhoto,
  fetchPhonesData,
  fetchRegistrationDataPhoto,
  fetchUserLoans,
  getLoanComments,
} from 'features/contracts/extra'

import {
  StyledLoaderPage,
  StyledWrapper,
  StyledBlockNav,
  StyledContent,
  StyledNavContent,
} from './Contracts.styles'
import { Passport, Agreement, Contacts } from './Modules'
import { resetContracts } from 'features/contracts/contractSlice'
import { GlobalState } from 'index'
import { NavMenuType } from 'App'
import { UNDERWRITER_LOANS_ROUTE } from 'constants/pagesRoutes'
import { RepaymentHistory } from './Modules/RepaymentHistory'
import { AccrueHistory } from './Modules/AccrueHistory'
import { Comments } from './Modules/Comments'
import { Payments } from './Modules/Payments'
import {
  AdditionalContacts,
  LoansHistory,
  Services,
  StatusesHistory,
  UserDetails,
} from 'pages/UnderwriterLoan/Modules'
import {
  fetchAdditionalContacts,
  fetchLoanDebtByNumber,
  fetchLoanGeneralInfoData,
  fetchServicesData,
  fetchUserDetailsData,
  getStatusesHistory,
} from 'features/loan/extra'
import { NavigationContext } from 'app/contexts'
import { Information } from 'pages/UnderwriterLoan/Modules/Information'
import { PDNLimitCalculate } from 'pages/UnderwriterLoan/Modules/SetLimitInstallment/PDNLimitCalculate'
import { checkLoanIssuedDate, checkLoanIssuedStatus, LoanStatuses } from 'helpers'

const navigationList = (): INavigationItem[] => {
  const list = [
    { title: 'Паспортные данные', anchor: 'passport' },
    { title: 'Анкета и согласие', anchor: 'form' },
    { title: 'Контакты', anchor: 'contacts' },
    { title: 'Дополнительные контакты', anchor: 'additionalContacts' },
    { title: 'Занятость и доходы', anchor: 'income' },
    { title: 'История статусов', anchor: 'statusesHistory' },
    { title: 'История займов', anchor: 'loansHistory' },
    { title: 'Услуги', anchor: 'services' },
    { title: 'История погашений', anchor: 'repaymentHistory' },
    { title: 'История начислений', anchor: 'accrueHistory' },
    { title: 'Комментарии андеррайтера ', anchor: 'comments' },
    { title: 'График платежей', anchor: 'payments' },
    { title: 'Информация по займу', anchor: 'information' },
  ]
  return list
}

interface IContractProps {
  claims: string
}

export const Contracts: React.FC<IContractProps> = ({ claims }): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams<{ id: string | undefined }>()
  const [openedModules, setOpenedModules] = useState<string[]>([])
  const { userData, loan_balance } = useSelector((store: GlobalState) => store.contracts)
  const loanData = useSelector((store: GlobalState) => store.contracts.loanData)
  const { statusesHistory } = useSelector((store: GlobalState) => store.singleLoan)
  const { setNavigation, setNavMenuType, isAgentRole } = useContext(NavigationContext)

  useEffect(() => {
    if (isAgentRole === false) {
      history.push(UNDERWRITER_LOANS_ROUTE)
      setNavMenuType(NavMenuType.underwriter)
    } else {
      setNavMenuType(NavMenuType.agent)
    }
  }, [isAgentRole])

  useEffect(() => {
    dispatch(fetchLoanPageById({ id }))
    return () => {
      dispatch(resetContracts(false))
    }
  }, [])

  useEffect(() => {
    if (loanData) {
      setNavigation({
        activeMenu: null,
        headContent: `Договор № ${loanData.loan_number}`,
        backTo: '/contracts/list?type=all_contracts',
        slug: 'contracts',
      })
    }
  }, [loanData])

  useEffect(() => {
    if (userData?.user_id)
      batch(() => {
        dispatch(fetchAdditionalContacts({ id: userData.user_id }))
        dispatch(fetchUserDetailsData({ id: userData.user_id }))
        dispatch(fetchContractsUserPassportData({ userId: userData.user_id }))
        dispatch(fetchContractsUserPersonalData({ userId: userData.user_id }))
        dispatch(fetchPersonalDataPhoto({ id: userData.user_id }))
        dispatch(fetchAgentDataPhoto({ id: userData.user_id }))
        dispatch(fetchContractPassportSelfieDataPhoto({ userId: userData.user_id }))
        dispatch(fetchRegistrationDataPhoto({ id: userData.user_id }))
        dispatch(fetchPhonesData({ id: userData.user_id }))
        dispatch(fetchUserLoans({ ids: [`${userData.user_id}`] }))
        dispatch(getLoanComments({ loanNumber: loanData?.loan_number }))
        dispatch(fetchLoanDebtByNumber({ number: +loanData?.loan_number }))
        dispatch(fetchLoanGeneralInfoData({ loanNumber: loanData?.loan_number }))
        dispatch(fetchServicesData({ loanNumber: loanData?.loan_number }))
        dispatch(getStatusesHistory({ id: loanData?.loan_id }))
      })

    if (loanData?.loan_number)
      batch(() => {
        dispatch(fetchLoanTransactions({ loan_number: loanData?.loan_number }))
      })
    if (loanData?.loan_id) dispatch(getStatusesHistory({ id: loanData.loan_id }))
  }, [userData, loanData?.loan_number, loanData?.loan_id])

  const onToggleModule = (anchor: string): void => {
    const newOpenedModules = [...openedModules]
    const index = newOpenedModules.indexOf(anchor)
    index !== -1 ? newOpenedModules.splice(index, 1) : newOpenedModules.push(anchor)
    setOpenedModules(newOpenedModules)
  }

  const onNavigationClick = (anchor: string): void => {
    if (!openedModules.includes(anchor)) setOpenedModules([...openedModules, anchor])
  }

  const showPDNLimits =
    checkLoanIssuedDate(loanData?.loan_date_create) &&
    (checkLoanIssuedStatus(statusesHistory?.data) ||
      [
        LoanStatuses.setting_a_limit,
        LoanStatuses.on_check,
        LoanStatuses.agent_in_work,
        LoanStatuses.customer_revision,
      ].includes(loanData?.loan_status))

  const props = {
    openedModules,
    onToggleModule,
    loan_id: id,
    loanNumber: loanData?.loan_number,
    loanStatus: loanData?.loan_status,
    creditProduct: loanData?.credit_product,
    claimsResolve: false,
    claims,
    loanBalance: loan_balance,
  }

  const onRollUp = (): void => {
    setOpenedModules([])
    const el = document.getElementById('loan-content')
    if (el)
      el.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
  }

  const onRollDown = (): void => {
    const anchors = navigationList().map((v) => v.anchor)
    setOpenedModules(anchors)
  }

  const isFetching = false

  return (
    <>
      {isFetching ? (
        <StyledLoaderPage>
          <Loader />
        </StyledLoaderPage>
      ) : (
        <>
          <ScrollToTop />
          <StyledWrapper>
            <StyledBlockNav>
              <UserInformation
                fullName={[userData?.last_name, userData?.first_name, userData?.middle_name].join(
                  ' '
                )}
                creditProduct={loanData?.credit_product}
              />
              <Navigation
                list={navigationList()}
                containerId='loan-content'
                onChange={onNavigationClick}
              />
            </StyledBlockNav>
            <StyledNavContent>
              <StickyBox>
                <HeadInfo onRollUp={onRollUp} onRollDown={onRollDown} />
              </StickyBox>
              <StyledContent id='loan-content'>
                <Head />
                <Passport {...props} />
                <Agreement {...props} />
                <Contacts {...props} />
                <AdditionalContacts {...props} />
                <UserDetails {...props} />
                <StatusesHistory {...props} />
                <LoansHistory {...props} />
                <Services {...props} />
                <RepaymentHistory {...props} />
                <AccrueHistory {...props} />
                <Comments {...props} />
                <Payments {...props} />
                <Information {...props} />
                {showPDNLimits && <PDNLimitCalculate showRecalculate storeData='contract' />}
              </StyledContent>
            </StyledNavContent>
          </StyledWrapper>
        </>
      )}
    </>
  )
}
