/* eslint-disable indent */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Module, Switcher, UploadLoanImage } from 'components'
import { ImageModal } from 'containers'
import { GlobalState } from 'index'
import { StyledPassportWrapper } from './Modules.styles'
import { useGetContractPhotoData } from 'features/contracts/contractSelectors'
import { TabsMediaFilesFilteredType } from 'pages/UnderwriterLoan/Modules/types'
import { setFilteredContractImagesTab } from 'features/contracts/contractSlice'
import { StyledSwitchBlockWrapper } from 'pages/UnderwriterLoan/Modules/Modules.styles'
import { Box } from 'shared'

const moduleId = 'form'

const tabConfig = {
  [TabsMediaFilesFilteredType.ContractsScans]: { 0: 'По заявке', 1: 'Все' },
}

export const Agreement: any = ({
  openedModules,
  onToggleModule,
  userId,
  loanNumber,
}): JSX.Element => {
  const dispatch = useDispatch()
  // const images = useSelector((store: GlobalState) => store.contracts.imageData)
  const images = useGetContractPhotoData()
  const activeTab = useSelector((store: GlobalState) => store.contracts.filteredImages)
  const { userData } = useSelector((store: GlobalState) => store.contracts)
  const isOpen = openedModules.includes(moduleId)
  const [showModal, setShowModal] = useState({
    show: false,
    content: null,
  })

  const handleExpand = (): void => onToggleModule(moduleId)

  const handleTabChange = (tabType: TabsMediaFilesFilteredType, index: number) => {
    const newLoanNumber = index === 1 ? loanNumber : null
    dispatch(setFilteredContractImagesTab({ tab: tabType, loanNumber: newLoanNumber }))
  }

  const handleCloseModal = (): void =>
    setShowModal({
      ...showModal,
      show: false,
    })

  const handleShowModal =
    ({ image }) =>
    () =>
      setShowModal({
        ...showModal,
        show: true,
        content: image,
      })

  const fetchImages = false // TODO
  const imageContent = { ...showModal.content }

  return (
    <Module.Wrapper isOpen={isOpen} editable={false} isEdited={false} id={moduleId}>
      <ImageModal.ModalContent
        userId={userId}
        isOpen={showModal.show}
        onRequestClose={handleCloseModal}
        imageContent={imageContent}
        onClose={handleCloseModal}
        type='agentsScans'
      />
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <Module.HeadTitle>Скан документов</Module.HeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        <Box margin={'0 30px'} maxWidth={'300px'}>
          <StyledSwitchBlockWrapper>
            <Switcher
              tabs={tabConfig[TabsMediaFilesFilteredType.ContractsScans]}
              activeTab={activeTab[TabsMediaFilesFilteredType.ContractsScans] !== null ? 1 : 0}
              onTabChange={(index) =>
                handleTabChange(TabsMediaFilesFilteredType.ContractsScans, index)
              }
            />
          </StyledSwitchBlockWrapper>
        </Box>
        <StyledPassportWrapper>
          <Module.ImageBlock>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {fetchImages && <Module.ImageLoader />}
              <UploadLoanImage
                type='agent-photo'
                userId={userData?.user_id}
                capture='environment'
                loan_number={loanNumber}
              />
              {images &&
                images?.agentDataPhoto.length > 0 &&
                images.agentDataPhoto.map((image) => (
                  <Module.ImageWrapper onClick={handleShowModal({ image })} key={image.image_id}>
                    <Module.SmallImage url={image.photo_url} />
                  </Module.ImageWrapper>
                ))}
            </div>
          </Module.ImageBlock>
        </StyledPassportWrapper>
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}
