/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable indent */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useContext, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import StickyBox from 'react-sticky-box'
import MaskedInput from 'react-text-mask'

import { NavigationContext } from 'app/contexts'
import { NavMenuType } from 'App'
import { AppDispatch, GlobalState } from 'index'
import { Button, ModalConfirm, Select, TableHead } from 'components'
import { StyledInput } from 'helpers'
import { StyledEmptyLabel, StyledPageWrapper } from 'pages/UnderwriterList/Underwriter.styles'
import {
  addToBlackListByPassport,
  addToBlackListBySnils,
  getBlackListByPassport,
  getBlackListBySnils,
} from 'features/blackList/extra'

import {
  StaledContainer,
  StyledActionsBlock,
  StyledActiveTab,
  StyledAddButton,
  StyledButtons,
  StyledCommentsBlock,
  StyledCommentTitle,
  StyledInputBlock,
  StyledReset,
  StyledResetFilter,
  StyledSearchBlock,
  StyledSearchContent,
  StyledSearchWrapper,
  StyledSelectBlock,
  StyledSwitcher,
  StyledSwitcherWrapper,
  StyledTab,
  StyledTableWrapper,
  StyledTextArea,
} from './BlckList'

import { STRUCTURE } from './Table/tableStructure'
import { COLUMN_TEMPLATE } from './Table/options'
import { Table } from './Table'

import { addToast } from 'features/toast/toastSlice'
import { ToastTypes } from 'lib/toast/Toast'

import { ReactComponent as CloseSVG } from 'assets/close.svg'
import { ReactComponent as PlusSVG } from '../../assets/plus.svg'
import { Controls } from 'components/Controls'

export const BlackList: React.FC = (): JSX.Element => {
  const dispatch: AppDispatch = useDispatch()
  const { loadable, blackListByPassportList, blackListBySnilsList } = useSelector(
    (store: GlobalState) => store.blackList
  )

  const { setNavigation, setNavMenuType } = useContext(NavigationContext)
  const [activeTab, setActiveTab] = useState<number>(0)
  const [newComment, setNewComment] = useState<string>('')
  const [passport, setPassport] = useState<string>('')
  const [snils, setSnils] = useState<string>('')
  const [userFio, setUserFio] = useState<string>('')
  const [passportBlockType, setPassportBlockType] = useState<string>('')
  const [valueByPassport, setValueByPassport] = useState<string>('')
  const [valueBySnils, setValueBySnils] = useState<string>('')
  const [showModal, setShowModal] = useState({
    show: false,
    description: '',
    title: '',
    action: null,
    style: '',
    withActionButton: false,
    blackList: null,
  })

  const isSubmitDisabled = useMemo(() => {
    const passportIsValid = /^\d{4} \d{6}$/.test(passport.trim())
    const fioIsValid = userFio.trim().length > 0
    const blockTypeIsValid = passportBlockType.trim().length > 0
    return !(passportIsValid && fioIsValid && blockTypeIsValid)
  }, [passport, userFio, passportBlockType])

  useEffect(() => {
    setNavMenuType(NavMenuType.underwriter)
    setNavigation({
      activeMenu: null,
      headContent: `Черный список`,
      backTo: false,
    })
  }, [setNavMenuType, setNavigation])

  const handleKeyDown = (value: string) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && value.trim()) handleSubmit()
  }

  const handleSubmit = () => {
    const searchValue = activeTab === 0 ? valueByPassport : valueBySnils
    const action = activeTab === 0 ? getBlackListByPassport : getBlackListBySnils
    dispatch(action({ search: searchValue }))
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    activeTab === 0 ? setValueByPassport(value) : setValueBySnils(value)
  }

  const isButtonDisabled = useMemo(() => {
    const value = activeTab === 0 ? valueByPassport : valueBySnils
    return !value.trim() || loadable?.blackListByPassportList || loadable?.blackListBySnilsList
  }, [activeTab, valueByPassport, valueBySnils, loadable])

  const resetByPassportState = () => {
    setNewComment('')
    setPassport('')
    setPassportBlockType('')
    setUserFio('')
  }

  const resetBySnilsState = () => setSnils('')

  const handleCloseModal = (): void => {
    setShowModal({
      show: false,
      title: '',
      action: null,
      style: '',
      description: '',
      withActionButton: false,
      blackList: null,
    })
    activeTab === 0 ? resetByPassportState() : resetBySnilsState()
  }

  const handleAddToBlackList = () => {
    setShowModal({
      blackList: 'passport',
      withActionButton: true,
      show: true,
      title: activeTab === 0 ? 'Внести паспорт в блеклист' : 'Внести СНИЛС в блеклист',
      description: ``,
      action: ({ full_name, user_passport, comment, passportType, user_snils }) => {
        activeTab === 0
          ? dispatch(
              addToBlackListByPassport({
                full_name,
                passport: user_passport,
                type: passportType,
                comment,
                onError: () =>
                  dispatch(
                    addToast({
                      type: ToastTypes.danger,
                      title: 'Ошибка',
                      description: `Во время выполнения запроса произошла ошибка`,
                    })
                  ),
                onSuccess: () => {
                  dispatch(
                    addToast({
                      type: ToastTypes.success,
                      title: 'Успех',
                      description: `Пользователь ${userFio} успешно внесен в черный список`,
                    })
                  )
                  handleCloseModal()
                },
              })
            )
          : dispatch(
              addToBlackListBySnils({
                snils: user_snils,
                onError: () =>
                  dispatch(
                    addToast({
                      type: ToastTypes.danger,
                      title: 'Ошибка',
                      description: `Во время выполнения запроса произошла ошибка`,
                    })
                  ),
                onSuccess: () => {
                  dispatch(
                    addToast({
                      type: ToastTypes.success,
                      title: 'Успех',
                      description: `Пользователь ${userFio} успешно внесен в черный список`,
                    })
                  )
                  handleCloseModal()
                },
              })
            )
      },
      style: 'warning',
    })
  }

  const suggestionList = [
    {
      label: 'Мошенники',
      value: '1',
    },
    {
      label: 'Просрочники',
      value: '2',
    },
    {
      label: 'Дефолт',
      value: '3',
    },
  ]

  const toggleSearch = (): void => {}

  const ControlProps = {
    toggleSearch,
    showSearch: false,
    setShowSearch: () => null,
    filtersVisible: false,
    setShowFilters: () => null,
    pageControlsStructure: 'blackList',
    actionType: 'blackList',
    setType: (v) => setActiveTab(v),
    type: activeTab,
  }

  return (
    <StyledPageWrapper>
      <ModalConfirm
        isOpen={showModal.show}
        onRequestClose={handleCloseModal}
        onClose={handleCloseModal}
        description={showModal.description}
        title={showModal.title}
        action={showModal.action}
        style={showModal.style}
        buttons={!showModal.withActionButton}
        content={() =>
          showModal.withActionButton ? (
            <StyledCommentsBlock>
              <>
                <StyledCommentTitle>
                  {activeTab === 0 ? 'Данные пользователя' : 'СНИЛС'}{' '}
                </StyledCommentTitle>
                {activeTab === 0 && (
                  <>
                    <StyledInputBlock
                      placeholder='ФИО'
                      value={userFio}
                      onChange={(event) => setUserFio(event?.target?.value)}
                    />
                    <StyledInputBlock
                      as={MaskedInput}
                      mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                      placeholder='Паспорт'
                      value={passport}
                      onChange={(event) => setPassport(event?.target?.value)}
                    />
                    <StyledTextArea
                      value={newComment || ''}
                      maxLength={999}
                      placeholder='Комментарий'
                      onChange={(e) => setNewComment(e?.target?.value)}
                    />
                  </>
                )}
              </>
              {activeTab === 0 && (
                <StyledSelectBlock>
                  <Select
                    data={suggestionList}
                    placeholder='Выберте тип блокировки'
                    value={suggestionList?.find((v) => v.value === passportBlockType) || ''}
                    onChange={(v) => setPassportBlockType(v.value)}
                    error={false}
                  />
                  {passportBlockType && (
                    <StyledResetFilter onClick={() => setPassportBlockType('')}>
                      <CloseSVG />
                    </StyledResetFilter>
                  )}
                </StyledSelectBlock>
              )}

              {activeTab === 1 && (
                <StyledInputBlock
                  as={MaskedInput}
                  mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                  placeholder='СНИЛС'
                  value={snils}
                  onChange={(event) => setSnils(event?.target?.value)}
                />
              )}

              <StyledButtons>
                <Button
                  pending={loadable?.addToBlackListByPassport || loadable?.addToBlackListBySnils}
                  type='standardBig'
                  onClick={() =>
                    activeTab === 0
                      ? isSubmitDisabled
                        ? null
                        : showModal.action({
                            full_name: userFio,
                            user_passport: passport,
                            comment: newComment,
                            passportType: passportBlockType,
                          })
                      : !snils || snils.includes('_')
                      ? null
                      : showModal.action({
                          user_snils: snils,
                        })
                  }
                  disabled={
                    (activeTab === 0 ? isSubmitDisabled : !snils || snils.includes('_')) ||
                    loadable?.addToBlackListByPassport ||
                    loadable?.addToBlackListBySnils
                  }
                >
                  Подтвердить
                </Button>
                <Button type='emptyBig' disabled={false} onClick={handleCloseModal}>
                  Отмена
                </Button>
              </StyledButtons>
            </StyledCommentsBlock>
          ) : null
        }
      />

      <Controls {...ControlProps} />
      <StyledSearchBlock showSearch>
        <StyledSearchContent>
          <StyledAddButton onClick={handleAddToBlackList}>
            <PlusSVG />
            Добавить
          </StyledAddButton>
          <StyledSearchWrapper>
            <StyledInput
              value={activeTab === 0 ? valueByPassport : valueBySnils}
              placeholder={
                activeTab === 0 ? 'Поиск (Паспорт, ФИО пользователя)' : 'Поиск (СНИЛС пользователя)'
              }
              onChange={handleChange}
              onKeyDown={handleKeyDown(activeTab === 0 ? valueByPassport : valueBySnils)}
            />
            {(valueByPassport || valueBySnils) && (
              <StyledReset
                onClick={() => (activeTab === 0 ? setValueByPassport('') : setValueBySnils(''))}
              >
                <CloseSVG />
              </StyledReset>
            )}
          </StyledSearchWrapper>
          <StyledActionsBlock disabled={isButtonDisabled}>
            <Button
              type='standard'
              onClick={!isButtonDisabled ? handleSubmit : null}
              pending={loadable?.blackListByPassportList || loadable?.blackListBySnilsList}
            >
              Найти
            </Button>
          </StyledActionsBlock>
        </StyledSearchContent>
      </StyledSearchBlock>
      <div style={{ overflow: 'auto' }} id='scrollable'>
        <StickyBox>
          <TableHead structure={STRUCTURE} template={COLUMN_TEMPLATE[activeTab]} type={activeTab} />
        </StickyBox>
        <StyledTableWrapper>
          {activeTab === 0 ? (
            <>
              {blackListByPassportList ? (
                blackListByPassportList?.map((blackList, index) => {
                  const TableProps = {
                    ...blackList,
                  }
                  return (
                    <StaledContainer key={index}>
                      <Table {...TableProps} activeTab={activeTab} />
                    </StaledContainer>
                  )
                })
              ) : (
                <StyledEmptyLabel>
                  {!loadable?.blackListByPassportList && <p>Ничего не найдено</p>}
                </StyledEmptyLabel>
              )}
            </>
          ) : (
            <>
              {blackListBySnilsList ? (
                blackListBySnilsList?.map((user_snils, index) => {
                  return (
                    <StaledContainer key={index}>
                      <Table user_snils={user_snils?.snils} activeTab={activeTab} />
                    </StaledContainer>
                  )
                })
              ) : (
                <StyledEmptyLabel>
                  {!loadable?.blackListBySnilsList && <p>Ничего не найдено</p>}
                </StyledEmptyLabel>
              )}
            </>
          )}
        </StyledTableWrapper>
      </div>
    </StyledPageWrapper>
  )
}
