/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable object-shorthand */
/* eslint-disable arrow-body-style */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getAdminFio, LoanStatuses, normalizeValues } from 'helpers'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalState } from 'index'
import moment from 'moment'

import { Button, EmptyValueReplacer, ModalConfirm, Select, SmallLoader, Tooltip } from 'components'

import { ReactComponent as DottedSVG } from 'assets/dotted-vertical.svg'
import { ReactComponent as PlaySVG } from 'assets/small-play-button.svg'

import {
  changeLoanProduct,
  fetchFsspData,
  fetchLoanPageById,
  sendPassportToBlackList,
  sendSnilsToBlackList,
} from 'features/loan/extra'
import { useGetLoanPassportData, useGetNBKIScoring } from 'features/loan/loanSelectors'
import { THEME } from 'core/theme'
import { resetAgentsLoans } from 'features/agentLoans/loanSlice'

import {
  CreditProduct,
  CreditProductCodes,
  CreditProductTranslate,
  isBankrupt,
} from 'constants/creditProduct'

import {
  StyledHeadWrapper,
  StyledHeadActionBlock,
  StyledAction,
  StyledHeadLoanInfo,
  StyledInfoWrapper,
  StyledTitle,
  StyledValue,
  StyledCloseExpandedElements,
  StyledRollIcon,
  StyledHeadTitle,
  StyledHeadLoanInfoWrapper,
  StyledFioBlock,
  StyledFioValue,
  StyledTooltipMenuWrapper,
  StyledActionButton,
  StyledCommentsBlock,
  StyledCommentTitle,
  StyledTextArea,
  StyledButtons,
  StyledInfo,
  StyledInactiveButton,
  StyledSelectBlock,
  StyledResetFilter,
  StyledSelectAgent,
  StyledLoaderWrapper,
} from './Head.styles'
import { ProjectAgentLoansService } from 'services/agentLoanService'
import { addToast } from 'features/toast/toastSlice'
import { ToastTypes } from 'lib/toast/Toast'
import { ProjectLoansService } from 'services/loanService'
import { resetLoan } from 'features/loan/singleLoanSlice'

import { ReactComponent as CloseSVG } from 'assets/close.svg'
import { MdChangeCircle } from 'react-icons/md'

interface GetAmountRefound {
  amount: number
  term: number
  percent: number
  credit_product: string
}

export const HeadInfo: React.FC<any> = ({
  onRollUp,
  onRollDown,
  handleRefuseLoan,
  onChangeAgent,
  isAssignLoan,
}): JSX.Element => {
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string | undefined }>()
  const [newComment, setNewComment] = useState<string>('')
  const { userData, passportData, blacklisted, otherDocs, currentLoanIsBelongsToAgent } =
    useSelector((store: GlobalState) => store.agentLoans)

  const [passportBlockType, setPassportBlockType] = useState<string>('')
  const [showModal, setShowModal] = useState({
    show: false,
    description: '',
    title: '',
    action: null,
    style: '',
    withActionButton: false,
    blackList: null,
  })

  const currentUserFullName = `${passportData?.last_name} ${passportData?.first_name} ${passportData?.middle_name}`

  const handleCloseModal = (): void => {
    setShowModal({
      show: false,
      title: '',
      action: null,
      style: '',
      description: '',
      withActionButton: false,
      blackList: null,
    })
    setNewComment('')
  }

  const openUserPersonalArea = () => {
    ProjectLoansService.getUserToken(userData?.user_id).then(({ data: { token } }) => {
      window.open(
        `https://papa-zaim.ru/personal-area?token=${token}&userId=${userData?.user_id}&underwriter=true`,
        '_blank'
      )
    })
  }

  const handleClick = (): void => {
    const action = (): void => handleRefuseLoan(newComment)

    setShowModal({
      show: true,
      title: `Отказ клиента`,
      description: `Поставить статус отказ клиента ?`,
      action,
      style: 'success',
      withActionButton: false,
      blackList: null,
    })
  }

  const handleAssignLoan = (): void => {
    ProjectAgentLoansService.getAssignTheLoan(id)
      .then(() => {
        window.location.reload()
      })
      .catch((e) => {
        dispatch(
          addToast({
            type: ToastTypes.warning,
            title: 'Ошибка',
            description: `заявка в работу не взята`,
          })
        )
      })
  }

  const handlePassportBlackList = () => {
    setShowModal({
      blackList: 'passport',
      withActionButton: true,
      show: true,
      title: 'Внести паспорт в ЧС',
      description: `Вы подтверждаете отправку паспорта пользователя ${currentUserFullName} в ЧС ?`,
      action: ({ comment, type }) =>
        dispatch(
          sendPassportToBlackList({
            full_name: currentUserFullName,
            passport: `${passportData?.serial} ${passportData?.number}`,
            type: type,
            comment: comment,
            onError: () =>
              dispatch(
                addToast({
                  type: ToastTypes.danger,
                  title: 'Ошибка',
                  description: `Во время выполнения запроса произошла ошибка`,
                })
              ),
            onSuccess: () => window.location.reload(),
          })
        ),
      style: 'warning',
    })
  }

  const handleSnilsBlackList = () => {
    setShowModal({
      blackList: 'snils',
      withActionButton: true,
      show: true,
      title: 'Внести снилс в ЧС',
      description: `Вы подтверждаете отправку СНИЛС пользователя ${currentUserFullName} в ЧС ?`,
      action: ({ snils }) => {
        return dispatch(
          sendSnilsToBlackList({
            snils: snils,
            onError: () =>
              dispatch(
                addToast({
                  type: ToastTypes.danger,
                  title: 'Ошибка',
                  description: `Во время выполнения запроса произошла ошибка`,
                })
              ),
            onSuccess: () => window.location.reload(),
          })
        )
      },
      style: 'success',
    })
  }

  const suggestionList = [
    {
      label: 'Мошенники',
      value: '1',
    },
    {
      label: 'Просрочники',
      value: '2',
    },
    {
      label: 'Дефолт',
      value: '3',
    },
  ]

  return (
    <StyledHeadActionBlock>
      <ModalConfirm
        isOpen={showModal.show}
        onRequestClose={handleCloseModal}
        onClose={handleCloseModal}
        description={showModal.description}
        title={showModal.title}
        action={showModal.action}
        style={showModal.style}
        buttons={!showModal.withActionButton}
        content={() =>
          showModal.withActionButton ? (
            <StyledCommentsBlock>
              {showModal.blackList === 'passport' && (
                <>
                  <StyledCommentTitle>Добавить комментарий</StyledCommentTitle>
                  <StyledTextArea
                    value={newComment || ''}
                    maxLength={999}
                    placeholder='Комментарий'
                    onChange={(e) => setNewComment(e?.target?.value)}
                  />
                </>
              )}
              {showModal.blackList === 'passport' && (
                <StyledSelectBlock>
                  <Select
                    data={suggestionList}
                    placeholder='Выберте тип блокировки'
                    value={suggestionList?.find((v) => v.value === passportBlockType) || ''}
                    onChange={(v) => setPassportBlockType(v.value)}
                    error={false}
                  />
                  {passportBlockType && (
                    <StyledResetFilter onClick={() => setPassportBlockType('')}>
                      <CloseSVG />
                    </StyledResetFilter>
                  )}
                </StyledSelectBlock>
              )}

              <StyledButtons>
                {showModal.blackList === 'passport' && (
                  <Button
                    type='standardBig'
                    onClick={() =>
                      newComment.trim() === '' || !passportBlockType
                        ? null
                        : showModal.action({ comment: newComment, type: passportBlockType })
                    }
                    disabled={newComment.trim() === '' || !passportBlockType}
                  >
                    Подтвердить
                  </Button>
                )}
                {showModal.blackList === 'snils' && (
                  <Button
                    type='standardBig'
                    onClick={() => showModal.action({ snils: otherDocs?.snils_number || '' })}
                  >
                    Подтвердить
                  </Button>
                )}
                <Button type='emptyBig' disabled={false} onClick={handleCloseModal}>
                  Отмена
                </Button>
              </StyledButtons>
            </StyledCommentsBlock>
          ) : (
            <>
              <StyledCommentsBlock>
                <StyledCommentTitle>Добавить комментарий</StyledCommentTitle>
                <StyledTextArea
                  value={newComment || ''}
                  maxLength={999}
                  placeholder='Комментарий'
                  onChange={(e) => setNewComment(e?.target?.value)}
                />

                <StyledButtons>
                  <Button
                    type='standardBig'
                    onClick={() => (newComment.trim() === '' ? null : showModal.action(newComment))}
                    disabled={newComment.trim() === ''}
                  >
                    Подтвердить
                  </Button>
                  <Button type='emptyBig' disabled={false} onClick={handleCloseModal}>
                    Отмена
                  </Button>
                </StyledButtons>
              </StyledCommentsBlock>
            </>
          )
        }
      />
      {isAssignLoan && (
        <Button type='smallSuccess' onClick={handleAssignLoan}>
          <PlaySVG />
          Взять в работу
        </Button>
      )}
      <StyledAction>
        <StyledCloseExpandedElements onClick={onRollDown}>
          <StyledRollIcon>+</StyledRollIcon> Развернуть все
        </StyledCloseExpandedElements>
        <StyledCloseExpandedElements onClick={onRollUp}>
          <StyledRollIcon>-</StyledRollIcon> Свернуть все
        </StyledCloseExpandedElements>
        <Tooltip
          content={
            <StyledActionButton>
              Действия
              <DottedSVG />
            </StyledActionButton>
          }
          coward={false}
        >
          <StyledTooltipMenuWrapper style={{ cursor: 'pointer' }}>
            <Button type='emptyBig' onClick={onChangeAgent}>
              Передать клиента
            </Button>
            <Button type='emptyBig' onClick={openUserPersonalArea}>
              Личный кабинет клиента
            </Button>

            <StyledInactiveButton inactive={blacklisted || !passportData || !userData}>
              <Button
                type='emptyBig'
                onClick={() =>
                  blacklisted || !passportData || !userData ? null : handlePassportBlackList()
                }
              >
                Внести паспорт в ЧС
              </Button>
            </StyledInactiveButton>

            <StyledInactiveButton inactive={blacklisted || !passportData || !userData}>
              <Button
                type='emptyBig'
                onClick={() =>
                  blacklisted || !passportData || !userData ? null : handleSnilsBlackList()
                }
              >
                Внести снилс в ЧС
              </Button>
            </StyledInactiveButton>
          </StyledTooltipMenuWrapper>
        </Tooltip>
      </StyledAction>
    </StyledHeadActionBlock>
  )
}

export const getAmountRefound = ({
  amount,
  term,
  percent,
  credit_product,
}: GetAmountRefound): string => {
  let result = null
  if (!amount || !term || !percent || !credit_product) return ''

  if (isBankrupt(credit_product)) {
    result =
      +amount *
      ((((percent * 14) / 100) * (1 + (percent * 14) / 100) ** (term / 2)) /
        ((1 + (percent * 14) / 100) ** (term / 2) - 1))
  }

  result =
    +amount *
    ((((percent * 7) / 100) * (1 + (percent * 7) / 100) ** term) /
      ((1 + (percent * 7) / 100) ** term - 1))

  return `${result.toFixed(2)} ₽`
}

const initialModalState = { title: '', description: '', show: false }

export const Head: React.FC<any> = (): JSX.Element => {
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string | undefined }>()
  const passportData = useSelector((store: GlobalState) => store.agentLoans.passportData)
  const personalData = useSelector((store: GlobalState) => store.agentLoans.personalData)
  const { assign_info, loanData, currentLoanIsBelongsToAgent } = useSelector(
    (store: GlobalState) => store.agentLoans
  )
  const dictData = useSelector((state: GlobalState) => state.dict)
  const scoring = useGetNBKIScoring()
  const isLoading: any = useRef(null)
  const isLoadingFSSP: any = useRef(null)
  isLoading.current = undefined
  isLoading.isLoadingFSSP = undefined

  const [showChangeProductModal, setChangeProductShowModal] = useState(initialModalState)
  const [newProduct, setNewProduct] = useState<string>(
    CreditProductTranslate[loanData?.credit_product] || ''
  )

  const {
    loadData: { loan_id: loanId, loan_source: loanSource = '', loan_number: currentLoanNumber },
    userId,
    fssp,
    selectedLoanData,
    loadable,
  } = useSelector((store: GlobalState) => store.singleLoan)

  useEffect(() => {
    if (loanData?.credit_product) setNewProduct(CreditProductTranslate[loanData?.credit_product])
  }, [loanData?.credit_product])

  useEffect(() => {
    if (isLoading.current === undefined) {
      isLoading.current = true
      dispatch(fetchLoanPageById({ id }))
    }

    return () => {
      dispatch(resetAgentsLoans(false))
      dispatch(resetLoan(null))
    }
  }, [])

  const {
    first_name: firstName = '',
    last_name: lastName = '',
    middle_name: middleName = '',
    registration_address: regAddress,
    date_of_birth: birthDate,
    serial,
    number,
    date_of_issue: dateOfIssue,
    place_of_birth: placeOfBirth,
    place_of_issue: placeOfIssue,
    registration_address: address,
  } = useGetLoanPassportData()

  useEffect(() => {
    if (
      firstName &&
      lastName &&
      userId &&
      currentLoanNumber &&
      loanData?.credit_product !== CreditProduct.pdl_08 &&
      !isLoadingFSSP.current
    ) {
      isLoadingFSSP.current = true
      dispatch(
        fetchFsspData({
          data: {
            last_name: lastName,
            first_name: firstName,
            mid_name: middleName,
            birth_date: moment(new Date(birthDate)).format('DD.MM.YYYY'),
          },
          userId,
          number: currentLoanNumber,
          force: false,
        })
      )
    }
  }, [
    firstName,
    lastName,
    userId,
    currentLoanNumber,
    birthDate,
    loanData?.credit_product,
    dispatch,
    middleName,
  ])

  const handleUpdateFssp = (): void => {
    dispatch(
      fetchFsspData({
        data: {
          last_name: lastName,
          first_name: firstName,
          mid_name: middleName,
          birth_date: moment(new Date(birthDate)).format('DD.MM.YYYY'),
        },
        userId,
        number: currentLoanNumber,
        force: true,
      })
    )
  }

  // NOTE: Временное решение для отключения кнопок
  const showButtons = false
  // const showButtons = ![
  //   CreditProduct.installment_30_100k,
  //   CreditProduct.pdl_08,
  //   CreditProduct.pdl,
  // ].includes(loanData?.credit_product)

  const getScoreStyleNBKI = useCallback(
    (score: number) => {
      if (score < 80) return 'red'
      if (score > 90) return 'green'
      return null
    },
    [scoring?.score]
  )

  const changeProduct = () => {
    if (!loanData?.credit_product) {
      console.error('Product name is not defined')
      return
    }

    const changedProductId = Object.keys(CreditProductCodes).find(
      (key) =>
        CreditProductCodes[key] ===
        Object.keys(CreditProductTranslate).find(
          (translateKey) => CreditProductTranslate[translateKey] === newProduct
        )
    )

    dispatch(
      changeLoanProduct({
        loan_id: loanId,
        product_id: changedProductId,
        onSuccess: () => {
          dispatch(
            addToast({
              type: ToastTypes.success,
              title: 'Успех',
              description: 'Продукт успешно изменен',
            })
          )
          setChangeProductShowModal(initialModalState)
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        },
        onError: () => {
          dispatch(
            addToast({
              type: ToastTypes.danger,
              title: 'Ошибка',
              description: 'Во время запроса произошла ошибка',
            })
          )
        },
      })
    )
  }

  const handleChangeProduct = () => {
    setChangeProductShowModal({
      show: true,
      title: 'Изменить тип продукта.',
      description: 'Выберите тип продукта для изменения',
    })
  }

  const CreditProductList = Object.keys(CreditProductTranslate).map((value) => {
    const data = {
      label: CreditProductTranslate[value],
      value: CreditProductTranslate[value],
    }
    return data
  })

  const showChangeLoanProductOption = (): boolean => {
    if (loanData?.loan_status === LoanStatuses.agent_in_work && currentLoanIsBelongsToAgent)
      return true
    return false
  }

  return (
    <StyledHeadWrapper>
      {!loanData ? (
        <StyledLoaderWrapper>
          <SmallLoader />
        </StyledLoaderWrapper>
      ) : (
        <>
          {' '}
          <StyledHeadLoanInfoWrapper>
            <StyledInfoWrapper>
              <StyledHeadTitle>Клиент</StyledHeadTitle>
              <StyledFioBlock>
                <StyledTitle>ФИО</StyledTitle>
                <StyledFioValue>
                  {passportData &&
                    normalizeValues(
                      `${passportData.last_name} ${passportData.first_name} ${passportData.middle_name}`
                    )}
                </StyledFioValue>
              </StyledFioBlock>
            </StyledInfoWrapper>
          </StyledHeadLoanInfoWrapper>
          <StyledHeadLoanInfo>
            <StyledInfoWrapper>
              <StyledTitle>Номер телефона</StyledTitle>
              <StyledValue styles='boldGreen'>
                {personalData && normalizeValues(personalData.login)}
              </StyledValue>
            </StyledInfoWrapper>
            <StyledInfoWrapper>
              <StyledTitle>Запрашиваемая сумма займа</StyledTitle>
              <StyledValue>
                {loanData?.loan_ask_sum && normalizeValues(loanData.loan_ask_sum)}
              </StyledValue>
            </StyledInfoWrapper>
            <StyledInfoWrapper>
              <StyledTitle>Разрешенная сумма</StyledTitle>
              <StyledValue>
                {loanData?.loan_date_create && normalizeValues(loanData.loan_permitted_sum)}
              </StyledValue>
            </StyledInfoWrapper>
            <StyledInfoWrapper>
              <StyledTitle>Создание заявки</StyledTitle>
              <StyledValue>
                {loanData?.loan_date_create &&
                  normalizeValues(loanData.loan_date_create, 'full_date')}
              </StyledValue>
            </StyledInfoWrapper>
            <StyledInfoWrapper>
              <StyledTitle>Андеррайтер</StyledTitle>
              <StyledValue>
                {assign_info?.admin_username &&
                  assign_info?.admin_username.length &&
                  getAdminFio(assign_info?.admin_username[0], dictData)}
              </StyledValue>
            </StyledInfoWrapper>
            <StyledInfoWrapper>
              <StyledTitle>Агент</StyledTitle>
              <StyledValue>
                {selectedLoanData?.agent_username &&
                  getAdminFio(selectedLoanData?.agent_username, dictData)}
              </StyledValue>
            </StyledInfoWrapper>
            <StyledInfoWrapper>
              <StyledTitle>Статус заявки</StyledTitle>
              <StyledValue>
                {loanData?.loan_status && normalizeValues(loanData.loan_status, 'loan_status')}
              </StyledValue>
            </StyledInfoWrapper>
            <StyledInfoWrapper>
              <StyledTitle>Процентная ставка</StyledTitle>
              <StyledValue> {loanData?.product_percent_day} %</StyledValue>
            </StyledInfoWrapper>
            <StyledInfoWrapper>
              <StyledTitle>Скорр балл НБКИ</StyledTitle>
              <StyledValue alarm={getScoreStyleNBKI(scoring?.score)}>
                {scoring?.score || <EmptyValueReplacer />}
              </StyledValue>
            </StyledInfoWrapper>
            <StyledInfoWrapper>
              <StyledTitle>Тип продукта</StyledTitle>
              <StyledValue>{CreditProductTranslate[loanData?.credit_product]}</StyledValue>
              {showChangeLoanProductOption() && (
                <StyledInfo leftPosition='90px' onClick={handleChangeProduct}>
                  Изменить
                  <div
                    style={{
                      fontSize: '18px',
                    }}
                  >
                    <MdChangeCircle />
                  </div>
                </StyledInfo>
              )}
            </StyledInfoWrapper>
            <StyledInfoWrapper>
              <StyledTitle>ФССП</StyledTitle>
              {!fssp.isFetching && fssp.data && !fssp.data?.detail && (
                <StyledValue>
                  <span style={{ color: fssp.data.found ? THEME.COLORS.WARNING : 'initial' }}>
                    {fssp.data.found ? 'Найден' : 'Не найден'}
                  </span>
                </StyledValue>
              )}
              {showButtons && (
                <StyledValue>
                  <StyledInfo
                    onClick={handleUpdateFssp}
                    style={{ cursor: 'pointer', gridColumn: 'span 1' }}
                  >
                    запросить
                  </StyledInfo>
                </StyledValue>
              )}

              {fssp.isFetching && <StyledValue>запрашивается...</StyledValue>}
            </StyledInfoWrapper>

            <StyledInfoWrapper>
              <StyledTitle>Еженедельный платеж</StyledTitle>
              <StyledValue>
                {getAmountRefound({
                  amount: loanData?.loan_sum ? +loanData?.loan_sum : loanData?.loan_ask_sum,
                  term: loanData?.loan_ask_period,
                  percent: +loanData?.product_percent_day,
                  credit_product: loanData?.credit_product,
                })}
              </StyledValue>
            </StyledInfoWrapper>
            <StyledInfoWrapper>
              <StyledTitle>Уступка прав требований</StyledTitle>
              <StyledValue alarm={loanData?.is_third_transfer_approved ? 'green' : 'red'}>
                {loanData?.is_third_transfer_approved ? 'Да' : 'Нет'}
              </StyledValue>
            </StyledInfoWrapper>
            <StyledInfoWrapper>
              <StyledTitle>Согласие передачи в БКИ</StyledTitle>
              <StyledValue alarm={loanData?.is_transfer_bki_approved ? 'green' : 'red'}>
                {loanData?.is_transfer_bki_approved ? 'Да' : 'Нет'}
              </StyledValue>
            </StyledInfoWrapper>
          </StyledHeadLoanInfo>
          <ModalConfirm
            isOpen={showChangeProductModal.show}
            onRequestClose={() =>
              setChangeProductShowModal({ ...showChangeProductModal, show: false })
            }
            onClose={() => setChangeProductShowModal({ ...showChangeProductModal, show: false })}
            description={showChangeProductModal.description}
            title={showChangeProductModal.title}
            buttons={false}
            content={() => (
              <div>
                <StyledSelectAgent>
                  <Select
                    data={CreditProductList}
                    placeholder='Продукт'
                    value={CreditProductList?.find((v) => v.value === newProduct) || ''}
                    onChange={({ value }) => setNewProduct(value)}
                  />
                </StyledSelectAgent>

                <Button
                  type='standardBig'
                  onClick={
                    !newProduct ||
                    newProduct === CreditProductTranslate[loanData?.credit_product] ||
                    loadable['changeLoanProduct']
                      ? null
                      : () => changeProduct()
                  }
                  pending={loadable['changeLoanProduct']}
                  disabled={
                    !newProduct ||
                    newProduct === CreditProductTranslate[loanData?.credit_product] ||
                    loadable['changeLoanProduct']
                  }
                >
                  Изменить тип продука
                </Button>
                <Button
                  type='emptyBig'
                  onClick={() => setChangeProductShowModal(initialModalState)}
                >
                  Отмена
                </Button>
              </div>
            )}
          />
        </>
      )}
    </StyledHeadWrapper>
  )
}
