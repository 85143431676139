/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention,@typescript-eslint/no-unused-vars */
/* eslint-disable indent */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getOtherDocumentsList } from './extra'
import { OtherDocumentsResponse, OtherDocumentsSlice } from './types'

const initialState: OtherDocumentsSlice = {
  loadable: {},
  otherDocumentsList: [],
}

export const otherDocumentsSlice = createSlice({
  name: 'otherDocuments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOtherDocumentsList.pending, (state) => {
        state.loadable['otherDocumentsList'] = true
        state.otherDocumentsList = []
      })
      .addCase(getOtherDocumentsList.rejected, (state) => {
        state.loadable['otherDocumentsList'] = false
      })
      .addCase(
        getOtherDocumentsList.fulfilled,
        (state, { payload }: PayloadAction<OtherDocumentsResponse[]>) => {
          state.loadable['otherDocumentsList'] = false
          state.otherDocumentsList = payload
        }
      )
  },
})

export default otherDocumentsSlice.reducer
