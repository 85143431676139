/* eslint-disable indent */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { isEmpty } from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectLoansService } from '../../services/loanService'

export const fetchLoans = createAsyncThunk<
  any,
  {
    iteration: number
    type: string
    filters: any
    refresh?: boolean
    subject?: string
  }
>('loans/loadLoans', async (props) => {
  const { iteration, type, filters, refresh = false, subject = 'underwriter' } = props
  const { loan_status, allProduct, unassignedProducts, ...restFilters } = filters

  const productData = {
    all: allProduct,
    unassigned: unassignedProducts,
  }

  let currentFilters = restFilters

  if (type === 'all' && !isEmpty(loan_status))
    currentFilters = {
      ...currentFilters,
      loan_status,
    }

  if (type === 'processing')
    currentFilters = {
      ...currentFilters,
      loan_status: ['setting_a_limit', 'pre_approved'],
    }

  if (productData[type])
    currentFilters = {
      ...currentFilters,
      ...(productData[type] &&
        productData[type]?.length > 0 && {
          product: productData[type],
        }),
    }

  const response = await ProjectLoansService.getLoans(
    iteration,
    type,
    currentFilters,
    subject
  ).then(({ data }) => ({
    data,
    type,
    refresh,
    iteration,
  }))
  return response
})

export const fetchLoansCount = createAsyncThunk<any>('loans/loadCount', async () => {
  const { total_loans_count: all } = await ProjectLoansService.getLoansCount().then(
    ({ data }) => data
  )
  const { processing_loans_count: processing } =
    await ProjectLoansService.getProcessingLoansCount().then(({ data }) => data)
  const { unassigned_loans_count: unassigned } =
    await ProjectLoansService.getUnassignedLoansCount().then(({ data }) => data)
  const { pre_approved_loans_count: pre_approved } =
    await ProjectLoansService.getPreApprovedLoansCount().then(({ data }) => data)

  const response = { all, processing, unassigned, pre_approved }
  return response
})

export const fetchAssignTheLoan = createAsyncThunk<
  any,
  { loanId: string; type?: string; username?: string; onSuccess?: any }
>('singleLoan/AssignTheLoan', async (props) => {
  const { loanId, type, onSuccess, username } = props
  const response = await ProjectLoansService.getAssignTheLoan(loanId).then(({ data }) => {
    onSuccess()
    return { data, type, loanId, username }
  })
  return response
})
