/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'

import { Button, Select, Module, ModalConfirm, Switcher } from 'components'
import { IBankruptDataItems } from 'features/loan/types'
import {
  createBankruptData,
  deleteBankruptData,
  fetchBankruptData,
  fetchBkiDocuments,
  updateBankruptData,
} from 'features/loan/extra'
import {
  allowedStatusesToDeleteImageAndVideo,
  DatePickerField,
  InputField,
  isValidForm,
  normalizeValues,
  validationSchemaBankrupt,
} from 'helpers'

import { ReactComponent as CloseSVG } from 'assets/close.svg'
import {
  StyledSelectBlock,
  StyledResetFilter,
  StyledBKIDocumentsWrapper,
  StyledButtonBlock,
  StyledCommentsButtonBlock,
  StyledSwitchBlockWrapper,
} from './Modules.styles'
import { IModuleProps, TabsMediaFilesFilteredType } from './types'
import { GlobalState } from 'index'
import { addToast } from 'features/toast/toastSlice'
import { ToastTypes } from 'lib/toast/Toast'
import { Box } from 'shared'
import { rolesType } from 'constants/rtoles'

const moduleId = 'bankrupt'

const createData = {
  statement_date: null,
  bankrupt_date: null,
  is_all_debt_off: null,
  is_deliberate_bankrupt: null,
  is_fraud_credit: null,
  is_bankrupt_credit: null,
  bankrupt_company_name: '',
  bankrupt_credit_decision: '',
}

const suggestionList = [
  {
    label: 'Да',
    value: true,
  },
  {
    label: 'Нет',
    value: false,
  },
]

interface IModal {
  show: boolean
  title: string
  action?: any
  style: string
}

const tabConfig = {
  [TabsMediaFilesFilteredType.BankruptDocuments]: { 0: 'По заявке', 1: 'Все' },
}

export const BankruptData: React.FC<IModuleProps> = ({
  openedModules,
  onToggleModule,
  claimsResolve,
  userId,
  loanNumber,
  claims,
  loanStatus,
}): JSX.Element => {
  const dispatch = useDispatch()
  const [editable, setEditable] = useState<boolean>(false)
  const [createBankrupt, setCreateBankrupt] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<any>(false)
  const { data, isFetching, isUpdating } = useSelector(
    (store: GlobalState) => store.singleLoan.bankruptData
  )
  const [activeTab, setActiveTab] = useState<any>({
    [TabsMediaFilesFilteredType.BankruptDocuments]: 0,
  })

  const { bkiDocuments } = useSelector((store: GlobalState) => store.singleLoan)
  const userData = useSelector((store: GlobalState) => store.singleLoan.userData)

  const isOpen = openedModules.includes(moduleId)

  const [showModal, setShowModal] = useState<IModal>({
    show: false,
    title: '',
    action: null,
    style: '',
  })

  useEffect(() => {
    if (data && !editable) setInitialValues(data)
  }, [data, editable])

  const handleChangeEditable = (): void => setEditable(!editable)

  const filteredBankruptDocuments = bkiDocuments?.filter((doc) =>
    activeTab[TabsMediaFilesFilteredType.BankruptDocuments] === 0
      ? doc.loan_number === loanNumber
      : true
  )

  const handleCancel = (resetForm) => (): void => {
    resetForm()
    setEditable(!editable)
  }

  const handleExpand = (): void => onToggleModule(moduleId)

  const submitForm = (values: any) => {
    setInitialValues(values)

    const { id, ...restValues } = values

    const body = {
      ...restValues,
      customer_id: userData?.customer_id,
    }

    if (createBankrupt) {
      dispatch(
        createBankruptData({
          userId,
          body,
          onError: () => {
            dispatch(
              addToast({
                type: ToastTypes.warning,
                title: 'Ошибка',
                description: `Данные не сохранены`,
              })
            )
          },
          onSuccess: () => {
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Успех',
                description: `Данные сохранены`,
              })
            )
            dispatch(fetchBankruptData({ userId }))
            setEditable(false)
            setCreateBankrupt(false)
          },
        })
      )
    } else {
      dispatch(
        updateBankruptData({
          userId,
          body,
          onError: () => {
            dispatch(
              addToast({
                type: ToastTypes.warning,
                title: 'Ошибка',
                description: `Данные не сохранены`,
              })
            )
          },
          onSuccess: () => {
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Успех',
                description: `Данные сохранены`,
              })
            )
            dispatch(fetchBankruptData({ userId }))
            setEditable(false)
            setCreateBankrupt(false)
          },
        })
      )
    }
  }

  const handleDeleteBankrupt = (resetForm: () => void): void => {
    const action = (): void => {
      dispatch(
        deleteBankruptData({
          userId,
          onError: () => {
            dispatch(
              addToast({
                type: ToastTypes.warning,
                title: 'Ошибка',
                description: `Данные не удалены`,
              })
            )
          },
          onSuccess: () => {
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Успех',
                description: `Данные упешно удалены`,
              })
            )
            dispatch(fetchBankruptData({ userId }))
            setEditable(false)
            setCreateBankrupt(false)
            resetForm()
            setInitialValues(false)
          },
        })
      )
    }

    setShowModal({
      show: true,
      title: ` Удалить банкротство`,
      action,
      style: 'warning',
    })
  }

  const handleCloseModal = (): void =>
    setShowModal({ show: false, title: '', action: null, style: '' })

  const handleTabChange = (tabType: TabsMediaFilesFilteredType, index: number) => {
    setActiveTab((prev) => ({
      ...prev,
      [tabType]: index,
    }))
  }

  const canMediaBeRemoved = ({ imageLoanNumber }) => {
    if (
      (claims === rolesType.underwriter || claims === rolesType.agent) &&
      imageLoanNumber === loanNumber &&
      allowedStatusesToDeleteImageAndVideo.includes(loanStatus)
    )
      return true
    return false
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={submitForm}
      validationSchema={validationSchemaBankrupt}
    >
      {(formikProps) => {
        const { errors, dirty, resetForm, handleSubmit } = formikProps

        return (
          <Module.Wrapper
            isOpen={isOpen}
            editable={editable}
            isEdited={dirty && editable}
            id={moduleId}
          >
            <ModalConfirm
              isOpen={showModal.show}
              onRequestClose={handleCloseModal}
              onClose={handleCloseModal}
              title={showModal.title}
              action={showModal.action}
              style={showModal.style}
            />
            <Module.Header onClick={handleExpand}>
              <Module.Actions isOpen={isOpen}>
                <Module.HeadTitle>Банкротство</Module.HeadTitle>
                <Module.Arrow isOpen={isOpen} />
              </Module.Actions>
            </Module.Header>
            <Module.ContentWrapper>
              {claimsResolve && data && (
                <Module.Button handleChangeEditable={handleChangeEditable} />
              )}
              <>
                <Module.Content>
                  {isFetching && <Module.LoaderBlock />}
                  <>
                    {initialValues ? (
                      <>
                        {editable ? (
                          <Edit {...formikProps} />
                        ) : (
                          <View
                            {...formikProps}
                            bankruptData={initialValues}
                            bkiDocuments={filteredBankruptDocuments}
                            handleTabChange={handleTabChange}
                            activeTab={activeTab}
                          />
                        )}
                      </>
                    ) : (
                      <Create
                        setEditable={setEditable}
                        setCreateBankrupt={setCreateBankrupt}
                        setInitialValues={setInitialValues}
                      />
                    )}
                  </>
                </Module.Content>
              </>
            </Module.ContentWrapper>
            <StyledButtonBlock>
              {editable && (
                <div style={{ display: 'flex', margin: '32px 0' }}>
                  <Button
                    disabled={isValidForm(errors) || isUpdating || !dirty}
                    type='standardBig'
                    onClick={() => {
                      isValidForm(errors) || isUpdating || !dirty ? null : handleSubmit()
                    }}
                    pending={isUpdating}
                  >
                    Сохранить
                  </Button>
                  {canMediaBeRemoved({ imageLoanNumber: loanNumber }) && (
                    <Button
                      style={{ marginLeft: '15px' }}
                      type='bigDanger'
                      onClick={() => handleDeleteBankrupt(resetForm)}
                      disabled={isUpdating}
                    >
                      Удалить
                    </Button>
                  )}
                  <Button
                    type='emptyBig'
                    onClick={() => {
                      handleCancel(resetForm)
                      setInitialValues(false)
                      setCreateBankrupt(false)
                      setEditable(false)
                    }}
                  >
                    Отменить
                  </Button>
                </div>
              )}
            </StyledButtonBlock>
          </Module.Wrapper>
        )
      }}
    </Formik>
  )
}

const Create = (props): JSX.Element => {
  const { setEditable, setCreateBankrupt, setInitialValues } = props

  return (
    <div>
      <span style={{ color: 'gray', opacity: '0.5' }}> Данные отсутствуют</span>
      <StyledCommentsButtonBlock>
        <Button
          disabled={false}
          type='bigSuccess'
          pending={false}
          onClick={() => {
            setEditable(true)
            setCreateBankrupt(true)
            setInitialValues(createData)
          }}
        >
          Добавить банкротство
        </Button>
      </StyledCommentsButtonBlock>
    </div>
  )
}

const Edit = (props): JSX.Element => {
  const { values, setFieldValue } = props

  return (
    <>
      <Module.Column column={1}>
        <DatePickerField
          name='statement_date'
          onChange={setFieldValue}
          placeholder='Дата подачи заявления на банкротство'
          value={values.statement_date}
          format='dd.MM.yyyy'
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <DatePickerField
          name='bankrupt_date'
          onChange={setFieldValue}
          placeholder='Дату признания банкротом'
          value={values.bankrupt_date}
          format='dd.MM.yyyy'
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='bankrupt_company_name'
          onChange={setFieldValue}
          placeholder='Название компании, которая занималась банкротством'
          value={values.bankrupt_company_name}
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <StyledSelectBlock>
          <Select
            data={suggestionList}
            placeholder='Все ли займы списаны'
            value={suggestionList?.find((v) => v.value === values.is_all_debt_off) || ''}
            onChange={(v) => setFieldValue('is_all_debt_off', v.value)}
          />
          {values.is_all_debt_off !== '' && (
            <StyledResetFilter onClick={() => setFieldValue('is_all_debt_off', '')}>
              <CloseSVG />
            </StyledResetFilter>
          )}
        </StyledSelectBlock>
      </Module.Column>

      <Module.Column column={1}>
        <StyledSelectBlock>
          <Select
            data={suggestionList}
            placeholder='Было ли банкротство намеренным'
            value={suggestionList?.find((v) => v.value === values.is_deliberate_bankrupt) || ''}
            onChange={(v) => setFieldValue('is_deliberate_bankrupt', v.value)}
          />
          {values.is_deliberate_bankrupt !== '' && (
            <StyledResetFilter onClick={() => setFieldValue('is_deliberate_bankrupt', '')}>
              <CloseSVG />
            </StyledResetFilter>
          )}
        </StyledSelectBlock>
      </Module.Column>

      <Module.Column column={1}>
        <StyledSelectBlock>
          <Select
            data={suggestionList}
            placeholder='Подавал ли заявки перед банкротством'
            value={suggestionList?.find((v) => v.value === values.is_fraud_credit) || ''}
            onChange={(v) => setFieldValue('is_fraud_credit', v.value)}
          />
          {values.is_fraud_credit !== '' && (
            <StyledResetFilter onClick={() => setFieldValue('is_fraud_credit', '')}>
              <CloseSVG />
            </StyledResetFilter>
          )}
        </StyledSelectBlock>
      </Module.Column>

      <Module.Column column={1}>
        <StyledSelectBlock>
          <Select
            data={suggestionList}
            placeholder='Подавал ли заявки после банкротством'
            value={suggestionList?.find((v) => v.value === values.is_bankrupt_credit) || ''}
            onChange={(v) => setFieldValue('is_bankrupt_credit', v.value)}
          />
          {values.is_bankrupt_credit !== '' && (
            <StyledResetFilter onClick={() => setFieldValue('is_bankrupt_credit', '')}>
              <CloseSVG />
            </StyledResetFilter>
          )}
        </StyledSelectBlock>
      </Module.Column>

      <Module.Column column={1}>
        <InputField
          name='bankrupt_credit_decision'
          onChange={setFieldValue}
          placeholder='Какое решение было по кредитам'
          value={values.bankrupt_credit_decision}
          {...props}
        />
      </Module.Column>
    </>
  )
}

const View = (props): JSX.Element => {
  const { values, bkiDocuments, handleTabChange, activeTab } = props

  return (
    <>
      <Module.Column column={1}>
        <Module.Title>Дата подачи заявления на банкротство</Module.Title>
        <Module.Value>{normalizeValues(values?.statement_date, 'date')}</Module.Value>
      </Module.Column>
      <Module.Column column={1}>
        <Module.Title>Дату признания банкротом</Module.Title>
        <Module.Value>{normalizeValues(values?.bankrupt_date, 'date')}</Module.Value>
      </Module.Column>
      <Module.Column column={1}>
        <Module.Title>Название компании, которая занималась банкротством</Module.Title>
        <Module.Value>{normalizeValues(values?.bankrupt_company_name)}</Module.Value>
      </Module.Column>
      <Module.Column column={1}>
        <Module.Title>Все ли займы списаны при банкротстве</Module.Title>
        <Module.Value>{normalizeValues(values?.is_all_debt_off, 'bool')}</Module.Value>
      </Module.Column>
      <Module.Column column={1}>
        <Module.Title>Было ли банкротство намеренным</Module.Title>
        <Module.Value>{normalizeValues(values?.is_deliberate_bankrupt, 'bool')}</Module.Value>
      </Module.Column>
      <Module.Column column={1}>
        <Module.Title>
          Подавал ли клиент заявки на кредит, зная, что собирается банкротится
        </Module.Title>
        <Module.Value>{normalizeValues(values?.is_fraud_credit, 'bool')}</Module.Value>
      </Module.Column>
      <Module.Column column={1}>
        <Module.Title>
          Подавал ли клиент заявки на кредит, после признания его банкротом
        </Module.Title>
        <Module.Value>{normalizeValues(values?.is_bankrupt_credit, 'bool')}</Module.Value>
      </Module.Column>
      <Module.Column column={1}>
        <Module.Title>
          Какое решение было по кредитам, заявки на которые подавались после банкротства
        </Module.Title>
        <Module.Value>{normalizeValues(values?.bankrupt_credit_decision)}</Module.Value>
      </Module.Column>
      <StyledBKIDocumentsWrapper>
        <b>НБКИ отчёт</b>
        <Box margin={'10px 0'}>
          <StyledSwitchBlockWrapper>
            <Switcher
              tabs={tabConfig[TabsMediaFilesFilteredType.BankruptDocuments]}
              activeTab={activeTab[TabsMediaFilesFilteredType.BankruptDocuments]}
              onTabChange={(index) =>
                handleTabChange(TabsMediaFilesFilteredType.BankruptDocuments, index)
              }
            />
          </StyledSwitchBlockWrapper>
        </Box>
        {bkiDocuments?.map((v) => (
          <div key={v.image_id}>
            <a href={v.photo_url}>{normalizeValues(v?.created_at, 'date')} - Скачать</a>
          </div>
        ))}
      </StyledBKIDocumentsWrapper>
    </>
  )
}
