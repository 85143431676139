/* eslint-disable import/no-named-as-default */
import { configureStore } from '@reduxjs/toolkit'
import { errorLogger } from './middlewares/error-logger'

import authReducer from '../features/auth/authSlice'
import loansReducer from '../features/loans/loanSlice'
import singleLoanReducer from '../features/loan/singleLoanSlice'
import dictReducer from '../features/dict/dictSlice'
import filtersReducer from '../features/filters/filtersSlice'
import toasterReducer from '../features/toast/toastSlice'
import undoActionReducer from '../features/undoAction/undoActionSlice'
import unassignedModalReducer from '../features/unassignedModal/unassignedModalSlice'
import modalLoansReducer from '../features/modalLoans/modalLoansSlice'
import userLoansSlice from '../features/userHistoryLoans/userHistoryLoans'
import sdlReducer from '../features/sdl/sdlSlice'
import operatorReducer from '../features/operator/operatorSlice'
import settingsReducer from '../features/settings/settingsSlice'
import banListReducer from '../features/banList/banListSlice'
import banListFiltersReducer from '../features/banListFilters/filtersSlice'
import agentLoansReducer from '../features/agentLoans/loanSlice'
import agentFiltersReducer from '../features/agentFilters/filtersSlice'
import contractReducer from '../features/contracts/contractSlice'
import clientsReducer from '../features/clients/clientsSlice'
import contractFiltersReducer from '../features/contractsFilters/filtersSlice'
import clientsFiltersReducer from '../features/clientFilters/filtersSlice'
import collectorReducer from '../features/collector/collectorSlice'
import collectorFiltersReducer from '../features/collectorFilters/filtersSlice'
import blackListReducer from '../features/blackList/blackSlice'
import commonDocumentsReducer from '../features/commonDocuments/comonDocumentsSlice'
import otherDocumentsReducer from '../features/otherDoceuments/otherDoceumentsSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    loans: loansReducer,
    loansModal: modalLoansReducer,
    userLoans: userLoansSlice,
    singleLoan: singleLoanReducer,
    dict: dictReducer,
    filters: filtersReducer,
    toaster: toasterReducer,
    undoAction: undoActionReducer,
    unassignedModal: unassignedModalReducer,
    sdl: sdlReducer,
    banList: banListReducer,
    banListFilters: banListFiltersReducer,
    agentLoans: agentLoansReducer,
    agentFilters: agentFiltersReducer,
    contractFilters: contractFiltersReducer,
    clientsFilters: clientsFiltersReducer,
    contracts: contractReducer,
    clients: clientsReducer,
    operator: operatorReducer,
    collector: collectorReducer,
    collectorFilters: collectorFiltersReducer,
    settings: settingsReducer,
    blackList: blackListReducer,
    commonDocuments: commonDocumentsReducer,
    otherDocuments: otherDocumentsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(errorLogger),
})

export type RootState = ReturnType<typeof store.getState>
