import { GlobalState } from 'index'
import { TabsMediaFilesFilteredType } from 'pages/UnderwriterLoan/Modules/types'
import { shallowEqual, useSelector } from 'react-redux'
import { IImageLoanData } from './types'

export const useGetContractPhotoData = (): IImageLoanData => {
  const images = useSelector((store: GlobalState) => store.contracts.imageData, shallowEqual)
  const filteredTabs = useSelector((store: GlobalState) => store.contracts.filteredImages)
  const loanNumber = useSelector((store: GlobalState) => store.contracts.loanData?.loan_number)

  const filterByLoanNumber = (arr, tab) =>
    filteredTabs[tab] === null
      ? arr?.filter((image) => String(image.loan_number) === String(loanNumber))
      : arr

  return {
    personalDataPhoto: filterByLoanNumber(
      images.personalDataPhoto,
      TabsMediaFilesFilteredType.ContractsMainPage
    ),
    registrationDataPhoto: filterByLoanNumber(
      images.registrationDataPhoto,
      TabsMediaFilesFilteredType.ContractsRegPage
    ),
    passportSelfie: filterByLoanNumber(
      images.passportSelfie,
      TabsMediaFilesFilteredType.ContractsSelfie
    ),
    agentDataPhoto: filterByLoanNumber(
      images.agentDataPhoto,
      TabsMediaFilesFilteredType.ContractsScans
    ),
  }
}
