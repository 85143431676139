/* eslint-disable react/no-array-index-key */
/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable arrow-body-style */
import React from 'react'
import { StyledActiveTab, StyledSwitcher, StyledTab } from './Switcher.styles'

interface SwitcherProps {
  tabs: Record<number, string>
  activeTab: number
  onTabChange: (tabKey: number) => void
}

export const Switcher: React.FC<SwitcherProps> = ({
  tabs,
  activeTab,
  onTabChange,
}): JSX.Element => {
  const tabEntries = Object.entries(tabs).map(([key, value]) => ({
    index: Number(key),
    label: value,
  }))

  return (
    <StyledSwitcher tabCount={tabEntries.length}>
      {tabEntries.map(({ index, label }) => (
        <StyledTab key={index} active={activeTab === index} onClick={() => onTabChange(index)}>
          {label}
        </StyledTab>
      ))}
      <StyledActiveTab activeTab={activeTab} tabCount={tabEntries.length} />
    </StyledSwitcher>
  )
}
