/* eslint-disable arrow-body-style */
import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { spacing, size, border, display, flexbox, StyledProps } from './styled-props'

interface BoxProps extends StyledProps, React.HTMLAttributes<HTMLDivElement> {}

const StyledBox = styled.div<BoxProps>`
  ${spacing}
  ${size}
  ${border}
  ${display}
  ${flexbox}
`

export const Box = forwardRef<HTMLDivElement, React.PropsWithChildren<BoxProps>>(
  ({ children, ...props }, ref) => {
    return (
      <StyledBox ref={ref} {...props}>
        {children}
      </StyledBox>
    )
  }
)

Box.displayName = 'Box'

Box.defaultProps = {
  children: null,
}
