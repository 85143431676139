/* eslint-disable arrow-body-style */
/* eslint-disable indent */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Module, Switcher, UploadLoanImage } from 'components'
import { ImageModal } from 'containers'
import { GlobalState } from 'index'
import {
  StyledImageDescription,
  StyledPassportPhotoItem,
  StyledPassportPhotoWrapper,
  StyledPassportWrapper,
  StyledPersonalPhotoWrapper,
} from './Modules.styles'
import { TabsMediaFilesFilteredType } from 'pages/UnderwriterLoan/Modules/types'
import { useGetContractPhotoData } from 'features/contracts/contractSelectors'
import { StyledSwitchBlockWrapper } from 'pages/UnderwriterLoan/Modules/Modules.styles'
import { setFilteredContractImagesTab } from 'features/contracts/contractSlice'

const moduleId = 'passport'

const tabConfig = {
  [TabsMediaFilesFilteredType.ContractsSelfie]: { 0: 'По заявке', 1: 'Все' },
  [TabsMediaFilesFilteredType.ContractsRegPage]: { 0: 'По заявке', 1: 'Все' },
  [TabsMediaFilesFilteredType.ContractsMainPage]: { 0: 'По заявке', 1: 'Все' },
}

export const Passport: any = ({
  openedModules,
  onToggleModule,
  userId,
  loanNumber,
}): JSX.Element => {
  const dispatch = useDispatch()

  const activeTab = useSelector((store: GlobalState) => store.contracts.filteredImages)

  // const images = useSelector((store: GlobalState) => store.contracts.imageData)
  const images = useGetContractPhotoData()
  const { userData } = useSelector((store: GlobalState) => store.contracts)
  const [editable, setEditable] = useState<boolean>(false)
  const isOpen = openedModules.includes(moduleId)
  const [showModal, setShowModal] = useState({
    show: false,
    content: null,
  })

  const handleExpand = (): void => onToggleModule(moduleId)

  const handleCloseModal = (): void =>
    setShowModal({
      ...showModal,
      show: false,
    })

  const handleShowModal =
    ({ image }) =>
    () =>
      setShowModal({
        ...showModal,
        show: true,
        content: image,
      })

  const fetchImages = false // TODO
  const imageContent = { ...showModal.content }

  const handleTabChange = (tabType: TabsMediaFilesFilteredType, index: number) => {
    const newLoanNumber = index === 1 ? loanNumber : null
    dispatch(setFilteredContractImagesTab({ tab: tabType, loanNumber: newLoanNumber }))
  }

  return (
    <Module.Wrapper isOpen={isOpen} editable={editable} isEdited={false} id={moduleId}>
      <ImageModal.ModalContent
        userId={userId}
        isOpen={showModal.show}
        onRequestClose={handleCloseModal}
        imageContent={imageContent}
        onClose={handleCloseModal}
        type='contracts'
      />
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <Module.HeadTitle>Паспортные данные</Module.HeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        <StyledPassportWrapper>
          <Module.ImageBlock>
            <StyledPassportPhotoWrapper>
              <StyledImageDescription>Основная страница</StyledImageDescription>
              <StyledSwitchBlockWrapper>
                <Switcher
                  tabs={tabConfig[TabsMediaFilesFilteredType.ContractsMainPage]}
                  activeTab={
                    activeTab[TabsMediaFilesFilteredType.ContractsMainPage] !== null ? 1 : 0
                  }
                  onTabChange={(index) =>
                    handleTabChange(TabsMediaFilesFilteredType.ContractsMainPage, index)
                  }
                />
              </StyledSwitchBlockWrapper>
              <StyledPassportPhotoItem>
                {fetchImages && <Module.ImageLoader />}
                <UploadLoanImage
                  type='passport-main-page-photo'
                  userId={userData?.user_id}
                  capture='environment'
                  loan_number={loanNumber}
                />
                {images &&
                  images?.personalDataPhoto.length > 0 &&
                  images.personalDataPhoto.map((image) => (
                    <Module.ImageWrapper
                      onClick={handleShowModal({ image })}
                      key={image.image_id}
                      createdAt={image?.created_at}
                    >
                      <Module.SmallImage url={image.photo_url} />
                    </Module.ImageWrapper>
                  ))}
              </StyledPassportPhotoItem>
            </StyledPassportPhotoWrapper>

            <StyledPersonalPhotoWrapper>
              <StyledImageDescription>Страница регистрации</StyledImageDescription>
              <StyledSwitchBlockWrapper>
                <Switcher
                  tabs={tabConfig[TabsMediaFilesFilteredType.ContractsRegPage]}
                  activeTab={
                    activeTab[TabsMediaFilesFilteredType.ContractsRegPage] !== null ? 1 : 0
                  }
                  onTabChange={(index) =>
                    handleTabChange(TabsMediaFilesFilteredType.ContractsRegPage, index)
                  }
                />
              </StyledSwitchBlockWrapper>
              <StyledPassportPhotoItem>
                {fetchImages && <Module.ImageLoader />}
                <UploadLoanImage
                  type='passport-registration-page-photo'
                  userId={userData?.user_id}
                  capture='environment'
                  loan_number={loanNumber}
                />
                {images &&
                  images?.registrationDataPhoto.length > 0 &&
                  images.registrationDataPhoto.map((image) => (
                    <Module.ImageWrapper onClick={handleShowModal({ image })} key={image.image_id}>
                      <Module.SmallImage url={image.photo_url} />
                    </Module.ImageWrapper>
                  ))}
              </StyledPassportPhotoItem>
            </StyledPersonalPhotoWrapper>
            <StyledPassportPhotoWrapper>
              <StyledImageDescription>Фото с паспортом</StyledImageDescription>
              <StyledSwitchBlockWrapper>
                <Switcher
                  tabs={tabConfig[TabsMediaFilesFilteredType.ContractsSelfie]}
                  activeTab={activeTab[TabsMediaFilesFilteredType.ContractsSelfie] !== null ? 1 : 0}
                  onTabChange={(index) =>
                    handleTabChange(TabsMediaFilesFilteredType.ContractsSelfie, index)
                  }
                />
              </StyledSwitchBlockWrapper>
              <StyledPassportPhotoItem>
                <UploadLoanImage
                  type='passport-selfie'
                  userId={userData?.user_id}
                  contract
                  capture='environment'
                  loan_number={loanNumber}
                />
                {images &&
                  images?.passportSelfie.length > 0 &&
                  images.passportSelfie.map((image) => {
                    return (
                      <Module.ImageWrapper
                        onClick={handleShowModal({ image })}
                        key={image.image_id}
                        createdAt={image?.created_at}
                      >
                        <Module.SmallImage url={image.photo_url} />
                      </Module.ImageWrapper>
                    )
                  })}
              </StyledPassportPhotoItem>
            </StyledPassportPhotoWrapper>
          </Module.ImageBlock>
        </StyledPassportWrapper>
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}
