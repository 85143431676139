/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import { useEffect, useState } from 'react'
import { isNil, omitBy, isEqual } from 'lodash'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { DatePickerField, setInputValue } from 'helpers/form'
import { validationSchemaFilters, getAdminFio } from 'helpers'

import { loanStatuses } from 'helpers/dictionary'
import { Select, Button } from 'components'

import { ReactComponent as CloseSVG } from 'assets/close.svg'
import { ReactComponent as ArrowUpSVG } from 'assets/arrow-up.svg'
import { ReactComponent as CloseDarkSVG } from 'assets/close-dark.svg'

import { setFilters } from 'features/filters/filtersSlice'
import { useGetAgentUserNames, useGetAgentUserNamesWithIds } from 'features/dict/dictSelectors'
import { GlobalState } from 'index'
import { CreditProductTranslate } from '../../../constants'

import {
  StyledFiltersWrapper,
  StyledResetFilter,
  StyledSelectBlock,
  StyledFiltersBlock,
  StyledActionsBlock,
  StyledTitle,
  StyledAction,
  StyledFiltersContent,
} from './Filters.styles'
import { AiOutlineConsoleSql } from 'react-icons/ai'

const formatDate = (date, format) => (date ? moment(new Date(date)).format(format) : date)

const formatValues = (values) => {
  let currentFilters = {
    ...values,
    loan_expected_closing_date: formatDate(values.loan_expected_closing_date, 'YYYY-MM-DD'),
    loan_actual_closing_date: formatDate(values.loan_actual_closing_date, 'YYYY-MM-DD'),
  }

  if (values.start_date) {
    currentFilters = {
      ...currentFilters,
      loan_creation_date: {
        ...(currentFilters.loan_creation_date || {}),
        start_date: formatDate(values.start_date, 'YYYY-MM-DD'),
      },
    }
    delete currentFilters.start_date
  }

  if (values.stop_date) {
    currentFilters = {
      ...currentFilters,
      loan_creation_date: {
        ...(currentFilters.loan_creation_date || {}),
        stop_date: formatDate(values.stop_date, 'YYYY-MM-DD'),
      },
    }
    delete currentFilters.stop_date
  }

  if (values.loan_status && values.loan_status.length === 0) delete currentFilters.loan_status

  return currentFilters
}

export const Filters: React.FC<any> = ({
  showFilters,
  toggleFilters,
  pending,
  type,
}): JSX.Element => {
  const dispatch = useDispatch()
  const dictData = useSelector((state: GlobalState) => state.dict)
  const { adminUsernames = [] } = useSelector((store: any) => store.dict.data)
  const { data: filters } = useSelector((store: GlobalState) => store.filters)

  const AgentUsernamesList = adminUsernames.map((value) => ({
    label: getAdminFio(value, dictData),
    value,
  }))

  const [showAdditionalFilter, setShowAdditionalFilter] = useState<{
    show: boolean
    value: string
  }>({ show: true, value: 'desc' })

  useEffect(() => {
    if (type === 'unassigned' || type === 'processing' || type === 'pre_approved') {
      setShowAdditionalFilter({
        ...showAdditionalFilter,
        show: true,
      })

      let updatedFilter = { ...filters }

      if (showAdditionalFilter.value)
        updatedFilter = {
          ...updatedFilter,
          loan_last_status_date: showAdditionalFilter.value,
        }

      dispatch(setFilters(updatedFilter))
    } else {
      setShowAdditionalFilter({
        ...showAdditionalFilter,
        show: false,
      })
      const { loan_last_status_date, ...restFilters } = filters

      dispatch(
        setFilters({
          ...restFilters,
        })
      )
    }
  }, [type])

  const submitForm = async (values: any): Promise<void> => {
    if (pending) return

    dispatch(setFilters(omitBy(formatValues(values), isNil)))
    toggleFilters()
  }

  const resetAllFilters = (resetForm) => (): void => {
    resetForm()
  }

  return (
    <Formik
      initialValues={{
        admin_username: filters?.admin_username || null, // select
        start_date: filters?.start_date || null, // data
        stop_date: filters?.stop_date || null, // data
        operator: filters?.operator || null, // Select disabled
        loan_overdue_days_count: filters?.loan_overdue_days_count || null, // Select disabled
        loan_expected_closing_date: filters?.loan_expected_closing_date || null, // data
        loan_status: filters?.loan_status || null,
        loan_last_status_date: filters?.loan_last_status_date || null, // Select
        loan_actual_closing_date: filters?.loan_actual_closing_date || null, // data
        allProduct: filters?.allProduct || null, // data
        unassignedProducts: filters?.unassignedProducts || null, // data
      }}
      enableReinitialize
      onSubmit={submitForm}
      validationSchema={validationSchemaFilters}
    >
      {(formikProps) => {
        const { values, setFieldValue, handleSubmit, setValues }: any = formikProps

        const setValuesHandler = setInputValue(setFieldValue)
        const adminsList = adminUsernames.map((label) => ({ label, value: label }))

        const handleResetAllFields = () => {
          setValues({})
        }

        return (
          <StyledFiltersBlock showFilters={showFilters}>
            <StyledFiltersContent>
              <StyledFiltersWrapper>
                <StyledSelectBlock>
                  <Select
                    data={AgentUsernamesList}
                    placeholder='Ответственный сотрудник'
                    value={AgentUsernamesList?.find((v) => v.value === values.admin_username) || ''}
                    onChange={setValuesHandler('admin_username', 'value')}
                  />
                  {values.admin_username && (
                    <StyledResetFilter onClick={() => setFieldValue('admin_username', null)}>
                      <CloseSVG />
                    </StyledResetFilter>
                  )}
                </StyledSelectBlock>
                <DatePickerField
                  name='start_date'
                  onChange={setFieldValue}
                  placeholder='Дата создания заявки (от)'
                  value={values.start_date || null}
                  format='dd.MM.yyyy'
                  selectsStart
                  startDate={values.start_date || null}
                  endDate={values.stop_date || null}
                  {...formikProps}
                />
                <DatePickerField
                  name='stop_date'
                  onChange={setFieldValue}
                  placeholder='Дата создания заявки (до)'
                  value={values.stop_date || null}
                  format='dd.MM.yyyy'
                  selectsEnd
                  startDate={values.start_date || null}
                  endDate={values.stop_date || null}
                  minDate={values.start_date || null}
                  {...formikProps}
                />
                <DatePickerField
                  name='loan_expected_closing_date'
                  onChange={setFieldValue}
                  placeholder='Планируемая дата закрытия'
                  value={values.loan_expected_closing_date || null}
                  format='dd.MM.yyyy'
                  {...formikProps}
                />
                {type === 'all' && (
                  <StyledSelectBlock>
                    <Select
                      data={StatusesList}
                      isClearable={false}
                      isMulti
                      showPlaceholder={false}
                      placeholder='Статус заявки'
                      value={StatusesList.filter((x) =>
                        values.loan_status ? values.loan_status.includes(x.value) : null
                      )}
                      onChange={(v) =>
                        setFieldValue(
                          'loan_status',
                          Object.keys(v).map((x) => v[x].value)
                        )
                      }
                    />
                    {values.loan_status && values.loan_status.length > 0 && (
                      <StyledResetFilter onClick={() => setFieldValue('loan_status', null)}>
                        <CloseSVG />
                      </StyledResetFilter>
                    )}
                  </StyledSelectBlock>
                )}
                {type === 'all' && (
                  <StyledSelectBlock>
                    <Select
                      data={CreditProductList}
                      isClearable={false}
                      isMulti
                      placeholder='Продукт'
                      showPlaceholder={false}
                      value={CreditProductList.filter((x) =>
                        values.allProduct ? values.allProduct.includes(x.value) : null
                      )}
                      onChange={(v) =>
                        setFieldValue(
                          'allProduct',
                          Object.keys(v).map((x) => v[x].value)
                        )
                      }
                    />
                    {values.allProduct && values.allProduct.length > 0 && (
                      <StyledResetFilter onClick={() => setFieldValue('allProduct', null)}>
                        <CloseSVG />
                      </StyledResetFilter>
                    )}
                  </StyledSelectBlock>
                )}
                {type === 'unassigned' && (
                  <StyledSelectBlock>
                    <Select
                      data={CreditProductList}
                      isClearable={false}
                      isMulti
                      placeholder='Продукт'
                      showPlaceholder={false}
                      value={CreditProductList.filter((x) =>
                        values.unassignedProducts
                          ? values.unassignedProducts.includes(x.value)
                          : null
                      )}
                      onChange={(v) =>
                        setFieldValue(
                          'unassignedProducts',
                          Object.keys(v).map((x) => v[x].value)
                        )
                      }
                    />
                    {values.unassignedProducts && values.unassignedProducts.length > 0 && (
                      <StyledResetFilter onClick={() => setFieldValue('unassignedProducts', null)}>
                        <CloseSVG />
                      </StyledResetFilter>
                    )}
                  </StyledSelectBlock>
                )}
                <DatePickerField
                  name='loan_actual_closing_date'
                  onChange={setFieldValue}
                  placeholder='Фактическая дата закрытия'
                  value={values.loan_actual_closing_date || null}
                  format='dd.MM.yyyy'
                  {...formikProps}
                />
                <StyledSelectBlock>
                  {showAdditionalFilter.show && (
                    <Select
                      data={LastStatusDateFilters}
                      placeholder='Дата последнего статуса'
                      value={
                        LastStatusDateFilters?.find(
                          (v) => v.value === values.loan_last_status_date
                        ) || ''
                      }
                      onChange={({ value }) => {
                        setShowAdditionalFilter({
                          ...showAdditionalFilter,
                          value,
                        })
                        setFieldValue('loan_last_status_date', value)
                      }}
                    />
                  )}
                  {values.loan_last_status_date && (
                    <StyledResetFilter
                      onClick={() => {
                        setFieldValue('loan_last_status_date', null)
                        setShowAdditionalFilter({
                          ...showAdditionalFilter,
                          value: null,
                        })
                      }}
                    >
                      <CloseSVG />
                    </StyledResetFilter>
                  )}
                </StyledSelectBlock>
              </StyledFiltersWrapper>
              <StyledActionsBlock>
                <StyledAction onClick={toggleFilters}>
                  <ArrowUpSVG />
                  <StyledTitle>Свернуть</StyledTitle>
                </StyledAction>
                <StyledAction onClick={handleResetAllFields}>
                  <CloseDarkSVG />
                  <StyledTitle>Сбросить все</StyledTitle>
                </StyledAction>

                <Button type='standard' onClick={handleSubmit} disabled={pending}>
                  Применить
                </Button>
              </StyledActionsBlock>
            </StyledFiltersContent>
          </StyledFiltersBlock>
        )
      }}
    </Formik>
  )
}

const StatusesList = Object.keys(loanStatuses).map((value) => {
  const data = {
    label: loanStatuses[value],
    value,
  }
  return data
})

const CreditProductList = Object.keys(CreditProductTranslate).map((value) => {
  const data = {
    label: CreditProductTranslate[value],
    value: CreditProductTranslate[value],
  }
  return data
})

const LastStatusDateFilters = [
  {
    label: 'От новых к старым / новые сначала',
    value: 'desc',
  },
  {
    label: 'От старых  к новым / старые сначала',
    value: 'asc',
  },
]
