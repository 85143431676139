/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable no-useless-return */
/* eslint-disable arrow-body-style */
/* eslint-disable object-shorthand */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { orderBy } from 'lodash'
import { useSelector, useDispatch, batch } from 'react-redux'
import moment from 'moment'

// misc
import { HiOutlineClipboardCopy } from 'react-icons/hi'
import { MdChangeCircle } from 'react-icons/md'

import { Nullable } from 'commonTypes'
import { GlobalState } from 'index'

import {
  useGetLoanData,
  useGetStatusesLoan,
  useGetAssignInfo,
  useGetLoanPassportData,
  useGetLoanPersonalData,
  useGetNBKIScoring,
} from 'features/loan/loanSelectors'
import { setAssignLoanToThisUser, setPause } from 'features/loan/singleLoanSlice'
import { getLoanInWork } from 'features/loans/loanSlice'
import {
  fetchUnAssignLoan,
  fetchOnBan,
  fetchOnUnban,
  fetchPersonalData,
  updateLoanToSettingLimitStatus,
  fetchFsspData,
  blockUserLk,
  fetchUserDetailsData,
  unBlockUserLk,
  sendLoanToAutomaticCheck,
  sendPassportToBlackList,
  sendSnilsToBlackList,
  changeLoanProduct,
} from 'features/loan/extra'

import { ProjectLoansService } from 'services/loanService'

import {
  LoanStatuses,
  calcTime,
  copyToClipboard,
  getAdminFio,
  getDifferenceDateFromTotalTime,
  normalizeValues,
} from 'helpers'
import { ToastTypes } from 'lib/toast/Toast'
import { addToast } from 'features/toast/toastSlice'
import { Button, Tooltip, ModalConfirm, EmptyValueReplacer, Select } from 'components'
import { StyledTooltipMenuWrapper } from 'components/Tooltip/Tooltip.styles'

import { ReactComponent as AlertSVG } from 'assets/alert.svg'
import { ReactComponent as PlaySVG } from 'assets/small-play-button.svg'
import { ReactComponent as PauseSVG } from 'assets/pause-button.svg'
import { ReactComponent as DottedSVG } from 'assets/dotted-vertical.svg'
import { ReactComponent as CloseSVG } from 'assets/close.svg'

import { THEME } from 'core/theme'

import { CreditProduct, CreditProductCodes, CreditProductTranslate } from 'constants/creditProduct'
import { LOAN_INFO } from './options'
import {
  StyledHeadWrapper,
  StyledHeadActionBlock,
  StyledStatus,
  StyledAction,
  StyledHeadLoanInfo,
  StyledInfoWrapper,
  StyledTitle,
  StyledValue,
  StyledActionButton,
  StyledCloseExpandedElements,
  StyledBannedLabel,
  StyledInfo,
  StyledRollIcon,
  StyledLikelihood,
  StyledSelectAgent,
  StyledUserStatus,
  StyledCommentsBlock,
  StyledCommentTitle,
  StyledTextArea,
  StyledButtons,
  StyledSelectBlock,
  StyledResetFilter,
  StyledInactiveButton,
} from './Head.styles'

import { useGetAgentUserNamesWithIds } from 'features/dict/dictSelectors'
import { addToAgentAdmin, changeToAgentAdmin } from 'features/agentLoans/extra'

const NON_REASSIGNABLE_AGENTS = ['d.loginov', 'n.ivanova']
const HIDE_HEAD_LABELS = ['loan_ask_period', 'loan_period', 'loan_permitted_period']

interface IHeaderProps {
  onRollUp: () => void
  onRollDown: () => void
  claimsResolve: boolean
}

export const HeadInfo: React.FC<IHeaderProps> = ({
  onRollUp,
  onRollDown,
  claimsResolve,
}): JSX.Element => {
  const dispatch = useDispatch()
  const { total_time: totalTime } = useGetAssignInfo()
  const { is_in_work: inWork, is_free: isFree, is_paused: isPaused } = useGetStatusesLoan()
  const loanData = useGetLoanData()

  const {
    first_name: firstName = '',
    last_name: lastName = '',
    middle_name: middleName = '',
  } = useGetLoanPassportData()

  const currentUser = `${`${lastName} ${firstName.substr(0, 1)}. ${middleName.substr(0, 1)}. `}`
  const currentUserFullName = `${lastName} ${firstName} ${middleName}`

  const {
    loadData: { loan_id: loanId, loan_status: loanStatus },
    userId,
    isBanned,
    pending,
    userDetailsData: {
      data: { is_banned_cabinet },
    },
    blacklisted,
    passportData,
    otherDocs,
  } = useSelector((store: GlobalState) => store.singleLoan)

  const [loanInProgressTime, setLoanInProgressTime] = useState(null)
  const [time, setTime] = useState(0)
  const [newComment, setNewComment] = useState<string>('')
  const [passportBlockType, setPassportBlockType] = useState<string>('')

  const [showModal, setShowModal] = useState({
    show: false,
    title: '',
    description: '',
    action: null,
    style: '',
    withActionButton: false,
    blackList: null,
  })

  let timer

  useEffect(() => {
    if (!isPaused && inWork) {
      timer = setInterval(() => {
        setTime((time) => time + 1)
      }, 1000)
    }
    if (isPaused) {
      setLoanInProgressTime(getDifferenceDateFromTotalTime(totalTime, time))
    }
    return () => {
      clearInterval(timer)
    }
  }, [isPaused, inWork])

  useEffect(() => {
    if (!isPaused) setLoanInProgressTime(getDifferenceDateFromTotalTime(totalTime || 0, time))
  }, [time, isPaused])

  const handlePressPause = (): void => {
    ProjectLoansService.setPauseLoan(loanId)
    dispatch(setPause(true))
  }

  const handleAssignLoan = (): void => {
    ProjectLoansService.getAssignTheLoan(loanId)
      .then(() => {
        batch(() => {
          dispatch(setAssignLoanToThisUser())
        })
        dispatch(getLoanInWork())
      })
      .catch((e) => {
        dispatch(
          addToast({
            type: ToastTypes.warning,
            title: 'Ошибка',
            description: `заявка в работу не взята`,
          })
        )
      })
  }

  const handlePlay = (): void => {
    ProjectLoansService.setResumeLoan(loanId)
    dispatch(setPause(false))
  }

  const handleOnBan = () => {
    if (!pending) {
      setShowModal({
        show: true,
        title: 'Блокировка пользователя',
        description: `Заблокировать пользователя ${currentUser} на 90 дней ?`,
        action: () => dispatch(fetchOnBan({ id: userId, options: { days: 90 } })),
        style: 'warning',
        withActionButton: false,
        blackList: null,
      })
    }
  }

  const handleOnBanPermanent = () => {
    if (!pending) {
      setShowModal({
        show: true,
        title: 'Блокировка пользователя',
        description: `Вы подтверждаете отправку пользователя ${currentUser} в бан-лист?`,
        action: () => dispatch(fetchOnBan({ id: userId, options: { is_permanent: true } })),
        style: 'warning',
        withActionButton: false,
        blackList: null,
      })
    }
  }

  const handleSendToAutomaticCheck = () => {
    if (!pending) {
      setShowModal({
        blackList: null,
        withActionButton: false,
        show: true,
        title: 'Автоматическая проверка',
        description: `Вы подтверждаете отправку заявки на автомотическую проверку ?`,
        action: () =>
          dispatch(
            sendLoanToAutomaticCheck({
              id: loanId,
              timeLimit: loanData?.loan_ask_period,
              sumLimit: loanData?.loan_ask_sum,
              comment: 'Повторная автоматическая проверка',
              status_name: LoanStatuses.automatic_check,
              onError: () =>
                dispatch(
                  addToast({
                    type: ToastTypes.danger,
                    title: 'Ошибка',
                    description: `Во время выполнения запроса произошла ошибка, заявка не отправлена`,
                  })
                ),
              onSuccess: () => window.location.reload(),
            })
          ),
        style: 'warning',
      })
    }
  }

  const handlePassportBlackList = () => {
    if (!pending) {
      setShowModal({
        blackList: 'passport',
        withActionButton: true,
        show: true,
        title: 'Внести паспорт в ЧС',
        description: `Вы подтверждаете отправку паспорта пользователя ${currentUser} в ЧС ?`,
        action: ({ comment, type }) =>
          dispatch(
            sendPassportToBlackList({
              full_name: currentUserFullName,
              passport: `${passportData?.serial} ${passportData?.number}`,
              type: type,
              comment: comment,
              onError: () =>
                dispatch(
                  addToast({
                    type: ToastTypes.danger,
                    title: 'Ошибка',
                    description: `Во время выполнения запроса произошла ошибка`,
                  })
                ),
              onSuccess: () => window.location.reload(),
            })
          ),
        style: 'warning',
      })
    }
  }

  const handleSnilsBlackList = () => {
    if (!pending) {
      setShowModal({
        blackList: 'snils',
        withActionButton: true,
        show: true,
        title: 'Внести снилс в ЧС',
        description: `Вы подтверждаете отправку СНИЛС пользователя ${currentUser} в ЧС ?`,
        action: ({ snils }) => {
          return dispatch(
            sendSnilsToBlackList({
              snils: snils,
              onError: () =>
                dispatch(
                  addToast({
                    type: ToastTypes.danger,
                    title: 'Ошибка',
                    description: `Во время выполнения запроса произошла ошибка`,
                  })
                ),
              onSuccess: () => window.location.reload(),
            })
          )
        },
        style: 'success',
      })
    }
  }

  const handleUnBan = () => {
    if (!pending) {
      setShowModal({
        blackList: null,
        withActionButton: false,
        show: true,
        title: 'Разблокировка пользователя',
        description: `Разблокировать пользователя ${currentUser}?`,
        action: () => dispatch(fetchOnUnban({ id: userId })),
        style: 'success',
      })
    }
  }

  const handleSetStatus = (): void => {
    const action = (): void => {
      dispatch(
        updateLoanToSettingLimitStatus({
          id: loanId,
          onSuccess: () => window.location.reload(),
          onError: () =>
            dispatch(
              addToast({
                type: ToastTypes.danger,
                title: 'Ошибка',
                description: `Во время выполнения запроса произошла ошибка, статус не обновлен`,
              })
            ),
        })
      )
    }
    setShowModal({
      blackList: null,
      withActionButton: false,
      show: true,
      title: 'Отправить на повторное рассмотрение  ?',
      description: '',
      action,
      style: 'success',
    })
  }

  const handleUnBlockLk = (): void => {
    const action = (): void => {
      dispatch(
        unBlockUserLk({
          id: userId,
          onSuccess: () => {
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Успех',
                description: `Лк пользовалетя разблокирован`,
              })
            )
            dispatch(fetchUserDetailsData({ id: userId }))
          },
          onError: () =>
            dispatch(
              addToast({
                type: ToastTypes.danger,
                title: 'Ошибка',
                description: `Во время выполнения запроса произошла ошибка`,
              })
            ),
        })
      )
    }
    setShowModal({
      blackList: null,
      withActionButton: false,
      show: true,
      title: 'Разблокировать ЛК пользователя ?',
      description: '',
      action,
      style: 'success',
    })
  }

  const handleBlockLk = (): void => {
    const action = (): void => {
      dispatch(
        blockUserLk({
          id: userId,
          onSuccess: () => {
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Успех',
                description: `Лк пользовалетя заблокирован`,
              })
            )
            dispatch(fetchUserDetailsData({ id: userId }))
          },
          onError: (text) =>
            dispatch(
              addToast({
                type: text ? ToastTypes.danger : ToastTypes.warning,
                title: 'Ошибка',
                description: text || `Во время выполнения запроса произошла ошибка`,
              })
            ),
        })
      )
    }
    setShowModal({
      blackList: null,
      withActionButton: false,
      show: true,
      title: 'Заблокировать ЛК пользователя ?',
      description: '',
      action,
      style: 'warning',
    })
  }

  const handleUnassignLoan = (): void => {
    const action = (): void => {
      dispatch(fetchUnAssignLoan({ id: loanId }))
    }
    setShowModal({
      blackList: null,
      withActionButton: false,
      show: true,
      title: 'Вернуть заявку в свободные ?',
      description: '',
      action,
      style: 'success',
    })
  }

  const handleCloseModal = (): void => {
    setShowModal({
      blackList: null,
      show: false,
      title: '',
      description: '',
      action: null,
      style: '',
      withActionButton: false,
    })
    setNewComment('')
  }

  const openUserPersonalArea = () => {
    ProjectLoansService.getUserToken(userId).then(({ data: { token } }) => {
      window.open(
        `https://papa-zaim.ru/personal-area?token=${token}&userId=${userId}&underwriter=true`,
        '_blank'
      )
    })
  }

  const suggestionList = [
    {
      label: 'Мошенники',
      value: '1',
    },
    {
      label: 'Просрочники',
      value: '2',
    },
    {
      label: 'Дефолт',
      value: '3',
    },
  ]

  return (
    <StyledHeadActionBlock>
      <ModalConfirm
        isOpen={showModal.show}
        onRequestClose={handleCloseModal}
        onClose={handleCloseModal}
        title={showModal.title}
        action={showModal.action}
        style={showModal.style}
        description={showModal.description}
        buttons={!showModal.withActionButton}
        content={() =>
          showModal.withActionButton && (
            <>
              <StyledCommentsBlock>
                {showModal.blackList === 'passport' && (
                  <>
                    <StyledCommentTitle>Добавить комментарий</StyledCommentTitle>
                    <StyledTextArea
                      value={newComment || ''}
                      maxLength={999}
                      placeholder='Комментарий'
                      onChange={(e) => setNewComment(e?.target?.value)}
                    />
                  </>
                )}
                {showModal.blackList === 'passport' && (
                  <StyledSelectBlock>
                    <Select
                      data={suggestionList}
                      placeholder='Выберте тип блокировки'
                      value={suggestionList?.find((v) => v.value === passportBlockType) || ''}
                      onChange={(v) => setPassportBlockType(v.value)}
                      error={false}
                    />
                    {passportBlockType && (
                      <StyledResetFilter onClick={() => setPassportBlockType('')}>
                        <CloseSVG />
                      </StyledResetFilter>
                    )}
                  </StyledSelectBlock>
                )}

                <StyledButtons>
                  {showModal.blackList === 'passport' && (
                    <Button
                      type='standardBig'
                      onClick={() =>
                        newComment.trim() === '' || !passportBlockType
                          ? null
                          : showModal.action({ comment: newComment, type: passportBlockType })
                      }
                      disabled={newComment.trim() === '' || !passportBlockType}
                    >
                      Подтвердить
                    </Button>
                  )}
                  {showModal.blackList === 'snils' && (
                    <Button
                      type='standardBig'
                      onClick={() => showModal.action({ snils: otherDocs.snils_number })}
                    >
                      Подтвердить
                    </Button>
                  )}
                  <Button type='emptyBig' disabled={false} onClick={handleCloseModal}>
                    Отмена
                  </Button>
                </StyledButtons>
              </StyledCommentsBlock>
            </>
          )
        }
      />

      {blacklisted || isBanned?.is_banned ? (
        <StyledUserStatus>
          {blacklisted && (
            <StyledBannedLabel>
              <AlertSVG />
              Пользователь в ЧС
            </StyledBannedLabel>
          )}
          {isBanned?.is_banned && (
            <StyledBannedLabel>
              <AlertSVG />
              Пользователь в бан-листе
            </StyledBannedLabel>
          )}
        </StyledUserStatus>
      ) : (
        <>
          {claimsResolve && (
            <>
              {inWork && !isPaused && (
                <Button type='smallHeader' onClick={handlePressPause}>
                  <PauseSVG />
                  Поставить на паузу
                </Button>
              )}
              {isFree && (
                <Button type='smallSuccess' onClick={handleAssignLoan}>
                  <PlaySVG />
                  Взять в работу
                </Button>
              )}
              {isPaused && (
                <Button type='smallSuccess' onClick={handlePlay}>
                  <PlaySVG />
                  Продолжить работу
                </Button>
              )}
              {inWork && (
                <StyledStatus>
                  В обработке<span>{loanInProgressTime}</span>
                </StyledStatus>
              )}
            </>
          )}
        </>
      )}

      <StyledAction>
        <StyledCloseExpandedElements onClick={onRollDown}>
          <StyledRollIcon>+</StyledRollIcon> Развернуть все
        </StyledCloseExpandedElements>
        <StyledCloseExpandedElements onClick={onRollUp}>
          <StyledRollIcon>-</StyledRollIcon> Свернуть все
        </StyledCloseExpandedElements>

        {claimsResolve ? (
          <Tooltip
            content={
              <StyledActionButton>
                Действия
                <DottedSVG />
              </StyledActionButton>
            }
            coward={false}
          >
            <StyledTooltipMenuWrapper style={{ cursor: 'pointer' }}>
              {inWork && (
                <Button type='emptyBig' onClick={handleUnassignLoan}>
                  Вернуть заявку
                </Button>
              )}
              {!isBanned?.is_banned ? (
                <>
                  <Button type='emptyBig' onClick={handleOnBan}>
                    Бан на 90 дней
                  </Button>
                  <Button type='emptyBig' onClick={handleOnBanPermanent}>
                    Бан навсегда
                  </Button>
                </>
              ) : (
                <Button type='emptyBig' onClick={handleUnBan}>
                  Разбанить
                </Button>
              )}
              {(loanStatus === 'underwriter_refusal' || loanStatus === 'failure_automatic') && (
                <Button type='emptyBig' onClick={handleSetStatus}>
                  Повторное рассмотрение
                </Button>
              )}

              <Button type='emptyBig' onClick={openUserPersonalArea}>
                Личный кабинет клиента
              </Button>
              {is_banned_cabinet ? (
                <Button type='emptyBig' onClick={handleUnBlockLk}>
                  Разблокировать ЛК
                </Button>
              ) : (
                <Button type='emptyBig' onClick={handleBlockLk}>
                  Заблокировать ЛК
                </Button>
              )}
              <Button type='emptyBig' onClick={handleSendToAutomaticCheck}>
                Автоматическая проверка
              </Button>
              <StyledInactiveButton inactive={blacklisted}>
                <Button type='emptyBig' onClick={() => handlePassportBlackList()}>
                  Внести паспорт в ЧС
                </Button>
              </StyledInactiveButton>

              <StyledInactiveButton inactive={blacklisted}>
                <Button type='emptyBig' onClick={() => handleSnilsBlackList()}>
                  Внести снилс в ЧС
                </Button>
              </StyledInactiveButton>
            </StyledTooltipMenuWrapper>
          </Tooltip>
        ) : (
          <Tooltip
            content={
              <StyledActionButton>
                Действия
                <DottedSVG />
              </StyledActionButton>
            }
            coward={false}
          >
            <StyledTooltipMenuWrapper style={{ cursor: 'pointer' }}>
              <Button type='emptyBig' onClick={openUserPersonalArea}>
                Личный кабинет клиента
              </Button>
              {is_banned_cabinet ? (
                <Button type='emptyBig' onClick={handleUnBlockLk}>
                  Разблокировать ЛК
                </Button>
              ) : (
                <Button type='emptyBig' onClick={handleBlockLk}>
                  Заблокировать ЛК
                </Button>
              )}
            </StyledTooltipMenuWrapper>
          </Tooltip>
        )}
      </StyledAction>
    </StyledHeadActionBlock>
  )
}
function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const Head: React.FC<any> = ({ claimsResolve = false }): JSX.Element => {
  const q = useQuery()
  const dispatch = useDispatch()
  const loanData = useGetLoanData()
  const { isOverdue, fssp, loadable } = useSelector((state: GlobalState) => state.singleLoan)
  const dictData = useSelector((state: GlobalState) => state.dict)
  const scoring = useGetNBKIScoring()
  const agentUserNamesWithIds = useGetAgentUserNamesWithIds() || []
  const AgentUsernamesList = agentUserNamesWithIds.map((value) => ({
    label: getAdminFio(value.username, dictData),
    value: value.admin_id,
  }))

  const [assignAgent, setAssignAgent] = useState<string>('')
  const [newProduct, setNewProduct] = useState<string>(
    CreditProductTranslate[loanData?.credit_product] || ''
  )

  const {
    loadData: { loan_id: loanId, loan_source: loanSource = '', loan_number: currentLoanNumber },
    userId,
    ptiData: { data: ptiData },
    selectedLoanData,
    userData,
    personalData,
  } = useSelector((store: GlobalState) => store.singleLoan)
  const {
    statusesLoan: { is_in_work: loanInWork },
  } = useSelector((store: GlobalState) => store.singleLoan)
  const { is_paused: isPaused, is_in_work: isInWork } = useSelector(
    (store: GlobalState) => store.singleLoan.statusesLoan
  )
  const { time_zone: TZ, isFetching } = useGetLoanPersonalData()
  const [totalLoansDept, setTotalLoansDept] = useState<Nullable<number>>(null)

  useEffect(() => {
    if (ptiData && ptiData.length) {
      const foundPti = ptiData.filter((x) => x.loan_number.toString() === currentLoanNumber)

      if (foundPti && foundPti.length > 0) {
        const sortByPtiSettlementDate = orderBy(
          foundPti,
          [(obj) => new Date(obj.pti_settlement_date)],
          ['desc']
        )
        setTotalLoansDept(Math.round(sortByPtiSettlementDate[0].pti * 100))
      }
    }
  }, [ptiData, currentLoanNumber])

  const {
    first_name: firstName = '',
    last_name: lastName = '',
    middle_name: middleName = '',
    registration_address: regAddress,
    date_of_birth: birthDate,
    serial,
    number,
    date_of_issue: dateOfIssue,
    place_of_birth: placeOfBirth,
    place_of_issue: placeOfIssue,
    registration_address: address,
  } = useGetLoanPassportData()

  useEffect(() => {
    if (userId && isFetching) dispatch(fetchPersonalData({ id: userId }))
  }, [userId, loanId])

  const closedCount = q.get('closedCount')

  useEffect(() => {
    if (
      firstName &&
      lastName &&
      userId &&
      currentLoanNumber &&
      !isFetching &&
      (loanData?.credit_product !== CreditProduct.pdl_08 ||
        (loanData?.credit_product === CreditProduct.pdl_08 && +closedCount)) &&
      (loanData?.credit_product !== CreditProduct.pdl ||
        (loanData?.credit_product === CreditProduct.pdl && +closedCount))
    ) {
      batch(() => {
        dispatch(
          fetchFsspData({
            data: {
              last_name: lastName,
              first_name: firstName,
              mid_name: middleName,
              birth_date: moment(new Date(birthDate)).format('DD.MM.YYYY'),
            },
            userId,
            number: currentLoanNumber,
            force: false,
          })
        )
      })
    }
  }, [
    firstName,
    lastName,
    userId,
    currentLoanNumber,
    birthDate,
    loanData?.credit_product,
    dispatch,
    middleName,
    closedCount,
  ])

  const fio: any = `${lastName} ${firstName} ${middleName}`

  const copy = () => {
    copyToClipboard(fio)
      .then(() => {
        dispatch(
          addToast({
            type: ToastTypes.success,
            title: 'Успех',
            description: `Данные скопированны`,
          })
        )
      })
      .catch((err) => {
        console.log('Something went wrong', err)
      })
  }

  const handleUpdateFssp = (): void => {
    dispatch(
      fetchFsspData({
        data: {
          last_name: lastName,
          first_name: firstName,
          mid_name: middleName,
          birth_date: moment(new Date(birthDate)).format('DD.MM.YYYY'),
        },
        userId,
        number: currentLoanNumber,
        force: true,
      })
    )
  }

  const initialModalState = { title: '', description: '', show: false }

  const [showModal, setShowModal] = useState(initialModalState)
  const [showChangeProductModal, setChangeProductShowModal] = useState(initialModalState)

  const handleUpdateAgent = async () => {
    try {
      await ProjectLoansService.getCustomerAgentData(userData.customer_id).then((data: any) => {
        setAssignAgent(data?.data?.agent_id)
      })
    } catch (error) {
      console.log(error)
    } finally {
      setShowModal({
        show: true,
        title: 'Перезакрепление клиента.',
        description: 'Выберите агента для перезакрепления',
      })
    }
  }

  const changeProduct = () => {
    if (!loanData?.credit_product) {
      console.error('Product name is not defined')
      return
    }

    const changedProductId = Object.keys(CreditProductCodes).find(
      (key) =>
        CreditProductCodes[key] ===
        Object.keys(CreditProductTranslate).find(
          (translateKey) => CreditProductTranslate[translateKey] === newProduct
        )
    )

    dispatch(
      changeLoanProduct({
        loan_id: loanId,
        product_id: changedProductId,
        onSuccess: () => {
          dispatch(
            addToast({
              type: ToastTypes.success,
              title: 'Успех',
              description: 'Продукт успешно изменен',
            })
          )
          setChangeProductShowModal(initialModalState)
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        },
        onError: () => {
          dispatch(
            addToast({
              type: ToastTypes.danger,
              title: 'Ошибка',
              description: 'Во время запроса произошла ошибка',
            })
          )
        },
      })
    )
  }

  const handleChangeProduct = () => {
    setChangeProductShowModal({
      show: true,
      title: 'Изменить тип продукта.',
      description: 'Выберите тип продукта для изменения',
    })
  }

  const fetchAgentChange = () => {
    if (!assignAgent) return
    dispatch(
      (!selectedLoanData?.agent_username ? addToAgentAdmin : changeToAgentAdmin)({
        phone: personalData.login,
        admin_id: assignAgent,
        errorAction: () => {
          setShowModal(initialModalState)
          dispatch(
            addToast({
              type: ToastTypes.warning,
              title: 'Ошибка',
              description: `Передача клиента прошла с ошибкой`,
            })
          )
        },
        successAction: () => {
          setShowModal(initialModalState)
          batch(() => {
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Успех',
                description: `Агент успешно назначено`,
              })
            )
          })
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        },
      })
    )
  }
  const showButtons =
    ![CreditProduct.pdl_08, CreditProduct.pdl].includes(loanData?.credit_product) && claimsResolve

  const getScoreStyle = useCallback(
    (score: number) => {
      if (score < 80) return 'red'
      if (score > 90) return 'green'
      return null
    },
    [scoring?.score]
  )

  const isReassignAllowed = !NON_REASSIGNABLE_AGENTS.includes(selectedLoanData?.agent_username)

  const isAgentFioTheSame =
    `${lastName} ${firstName} ${middleName}` ===
    (selectedLoanData?.agent_username && getAdminFio(selectedLoanData?.agent_username, dictData))

  const CreditProductList = Object.keys(CreditProductTranslate).map((value) => {
    const data = {
      label: CreditProductTranslate[value],
      value: CreditProductTranslate[value],
    }
    return data
  })

  const showChangeLoanProductOption = (): boolean => {
    const inWorkAndActive = isInWork && !isPaused

    if (
      (loanData?.loan_status === LoanStatuses.setting_a_limit ||
        loanData?.loan_status === LoanStatuses.agent_in_work) &&
      claimsResolve &&
      inWorkAndActive
    )
      return true
    return false
  }

  return (
    <StyledHeadWrapper>
      <StyledHeadLoanInfo>
        {LOAN_INFO.map((item: any) => {
          if (claimsResolve && HIDE_HEAD_LABELS.includes(item.name)) return null

          return (
            <StyledInfoWrapper key={item.id} style={item?.style || null}>
              <StyledTitle>{item.title}</StyledTitle>
              <StyledValue styles={item.name === 'loan_ask_sum' ? 'boldGreen' : null}>
                {normalizeValues(loanData[item.name], item.type)}
              </StyledValue>
              {item.name === 'credit_product' && showChangeLoanProductOption() && (
                <StyledInfo leftPosition='90px' onClick={handleChangeProduct}>
                  Изменить продукт
                  <div
                    style={{
                      fontSize: '18px',
                    }}
                  >
                    <MdChangeCircle />
                  </div>
                </StyledInfo>
              )}
            </StyledInfoWrapper>
          )
        })}
        <StyledInfoWrapper onClick={copy} style={{ cursor: 'pointer', gridColumn: 'span 1' }}>
          <StyledInfo>
            скопировать
            <HiOutlineClipboardCopy />
          </StyledInfo>

          <StyledTitle>ФИО</StyledTitle>
          <StyledValue
            alarm={isAgentFioTheSame && 'red'}
          >{`${lastName} ${firstName} ${middleName}`}</StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Tекущее время клиента</StyledTitle>
          <StyledValue>{TZ ? calcTime(TZ?.split(':')[0]) : <EmptyValueReplacer />}</StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Регион клиента</StyledTitle>
          <StyledValue> {normalizeValues(regAddress?.region)}</StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Процентная ставка</StyledTitle>
          <StyledValue> {loanData?.product_percent_day} %</StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Скорр балл НБКИ</StyledTitle>
          <StyledValue alarm={getScoreStyle(scoring?.score)}>
            {scoring?.score || <EmptyValueReplacer />}
          </StyledValue>
        </StyledInfoWrapper>
        {!claimsResolve && (
          <StyledInfoWrapper>
            <StyledTitle>Вероятность просрочки</StyledTitle>
            <StyledLikelihood status={isOverdue}>{OverdueTypes[isOverdue]}</StyledLikelihood>
          </StyledInfoWrapper>
        )}

        <StyledInfoWrapper>
          <StyledTitle>Трафик</StyledTitle>
          <StyledValue>{loanSource?.toString() || <EmptyValueReplacer />}</StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Вебмастер</StyledTitle>
          <StyledValue>{loanData?.loan_content_id || <EmptyValueReplacer />}</StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>ФССП</StyledTitle>
          {!fssp.isFetching && fssp.data && !fssp.data?.detail && (
            <StyledValue>
              <span style={{ color: fssp.data.found ? THEME.COLORS.WARNING : 'initial' }}>
                {fssp.data.found ? 'Найден' : 'Не найден'}
              </span>
            </StyledValue>
          )}
          {showButtons && (
            <StyledValue>
              <StyledInfo
                onClick={handleUpdateFssp}
                style={{ cursor: 'pointer', gridColumn: 'span 1' }}
              >
                запросить
              </StyledInfo>
            </StyledValue>
          )}

          {fssp.isFetching && <StyledValue>запрашивается...</StyledValue>}
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Агент</StyledTitle>
          <StyledValue alarm={isAgentFioTheSame && 'red'}>
            {(selectedLoanData?.agent_username &&
              getAdminFio(selectedLoanData?.agent_username, dictData)) || <EmptyValueReplacer />}
          </StyledValue>
          {isReassignAllowed && claimsResolve && (
            <StyledValue>
              <StyledInfo
                onClick={handleUpdateAgent}
                style={{ cursor: 'pointer', gridColumn: 'span 1', width: 'max-content' }}
              >
                Назначить агента
              </StyledInfo>
            </StyledValue>
          )}
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Уступка прав требований</StyledTitle>
          <StyledValue alarm={loanData?.is_third_transfer_approved ? 'green' : 'red'}>
            {loanData?.is_third_transfer_approved ? 'Да' : 'Нет'}
          </StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Согласие передачи в БКИ</StyledTitle>
          <StyledValue alarm={loanData?.is_transfer_bki_approved ? 'green' : 'red'}>
            {loanData?.is_transfer_bki_approved ? 'Да' : 'Нет'}
          </StyledValue>
        </StyledInfoWrapper>
      </StyledHeadLoanInfo>
      <ModalConfirm
        isOpen={showModal.show}
        onRequestClose={() => setShowModal({ ...showModal, show: false })}
        onClose={() => setShowModal({ ...showModal, show: false })}
        description={showModal.description}
        title={showModal.title}
        buttons={false}
        content={() => (
          <div>
            <StyledSelectAgent>
              <Select
                data={AgentUsernamesList}
                placeholder='Агент'
                value={AgentUsernamesList?.find((v) => v.value === assignAgent) || ''}
                onChange={({ value }) => setAssignAgent(value)}
              />
            </StyledSelectAgent>

            <Button type='standardBig' onClick={fetchAgentChange} disabled={!assignAgent}>
              Перезакрепить
            </Button>
            <Button type='emptyBig' onClick={() => setShowModal(initialModalState)}>
              Отмена
            </Button>
          </div>
        )}
      />
      <ModalConfirm
        isOpen={showChangeProductModal.show}
        onRequestClose={() => setChangeProductShowModal({ ...showChangeProductModal, show: false })}
        onClose={() => setChangeProductShowModal({ ...showChangeProductModal, show: false })}
        description={showChangeProductModal.description}
        title={showChangeProductModal.title}
        buttons={false}
        content={() => (
          <div>
            <StyledSelectAgent>
              <Select
                data={CreditProductList}
                placeholder='Продукт'
                value={CreditProductList?.find((v) => v.value === newProduct) || ''}
                onChange={({ value }) => setNewProduct(value)}
              />
            </StyledSelectAgent>

            <Button
              type='standardBig'
              onClick={
                !newProduct ||
                newProduct === CreditProductTranslate[loanData?.credit_product] ||
                loadable['changeLoanProduct']
                  ? null
                  : () => changeProduct()
              }
              pending={loadable['changeLoanProduct']}
              disabled={
                !newProduct ||
                newProduct === CreditProductTranslate[loanData?.credit_product] ||
                loadable['changeLoanProduct']
              }
            >
              Изменить
            </Button>
            <Button type='emptyBig' onClick={() => setChangeProductShowModal(initialModalState)}>
              Отмена
            </Button>
          </div>
        )}
      />
    </StyledHeadWrapper>
  )
}

const OverdueTypes = {
  true: 'Большая',
  false: 'Низкая',
  '': 'нет данных',
}
