/* eslint-disable react/jsx-fragments */
/* eslint-disable object-shorthand */
/* eslint-disable indent */
/* eslint-disable arrow-body-style */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'

import { GlobalState } from 'index'
import { addToast } from 'features/toast/toastSlice'
import { InputField, isValidForm, validationSchemaPdnCalculator } from 'helpers'
import { Button, CheckBox, Module } from 'components'
import { ToastTypes } from 'lib/toast/Toast'

import {
  StyledButtonBlock,
  StyledLimitItem,
  StyledPDNCalculatorContent,
  StyledPDNCalculatorResult,
  StyledPDNCalculatorTitle,
  StyledPdnTableWrapper,
} from './Modules.styles'
import { IModuleProps } from './types'

interface IFormValues {
  loanSum: string
  percentMonthlyPayment: string
  loanAskPeriod: string
  otherIncome: string
}

interface CalculatePercentMonthlyPaymentArgs {
  loanAskSum: number
  productPercentDay: number
  loanAskPeriod: number
}

interface EditProps {
  values: {
    loanSum: string
    percentMonthlyPayment: string
    otherIncome: string
    percentPerDay: string
  }
  setFieldValue: (name: string, value: string) => void
  fragmentation: boolean
  setFragmentation: (value: boolean) => void
  recalculatePercentMonthlyPayment: ({
    values,
    setFieldValue,
  }: RecalculatePercentMonthlyPaymentProps) => void
}

interface RecalculatePercentMonthlyPaymentProps {
  values: any
  setFieldValue: (name: string, value: string) => void
}

const CREDIT_INFO_EXTERNAL_AUTH_TOKEN = 'Bearer 46|rr2hQtjaXppajONiFdKChlpZESYNVbf5UkDvG9kz625d7617'
const PDL_CALCULATE_LIMIT_API_URL_V2 = 'https://alf.tech.papazaim.com/api/credit-info/get-pti-array'

const moduleId = 'pdnCalculator'

const fetchData = async ({
  onError,
  onSuccess,
  setFetching,
  loanNumber,
  loanSum,
  percentPerDay,
  otherIncome,
  setApiResponse,
  setSecondApiResponse,
  setErrorResponse,
  fragmentation,
}) => {
  setFetching(true)
  const sum = fragmentation ? loanSum / 2 : loanSum

  try {
    const firstUrl = `${PDL_CALCULATE_LIMIT_API_URL_V2}/${loanNumber}?loan_sum=${sum}&loan_payment=${percentPerDay}&income=${otherIncome}`
    const firstResults = await axios
      .get(firstUrl, { headers: { Authorization: CREDIT_INFO_EXTERNAL_AUTH_TOKEN } })
      .then((response) => response.data)
    setApiResponse(firstResults)

    if (fragmentation) {
      const secondUrl = `${firstUrl}&second_stair=true`
      const secondResults = await axios
        .get(secondUrl, { headers: { Authorization: CREDIT_INFO_EXTERNAL_AUTH_TOKEN } })
        .then((response) => response.data)
      setSecondApiResponse(secondResults)
    } else {
      setSecondApiResponse(null)
    }

    setErrorResponse(null)
    onSuccess()
  } catch (err) {
    setErrorResponse(`Ошибка ${err.message}`)
    setFetching(false)
    onError()
  } finally {
    setFetching(false)
  }
}

// NOTE: original calculate monthlyPayment func do not delete
//     const percentMonthlyPayment = Math.round(
//       (loadData?.loan_ask_sum *
//         ((((loadData?.product_percent_day * 7) / 100) *
//           (1 + (loadData?.product_percent_day * 7) / 100) ** loadData?.loan_ask_period) /
//           ((1 + (loadData?.product_percent_day * 7) / 100) ** loadData?.loan_ask_period - 1)) *
//         loadData?.loan_ask_period) /
//         Math.ceil((loadData?.loan_ask_period * 7) / 30.41)
//     ).toFixed(2)

const calculatePercentMonthlyPayment = ({
  loanAskSum,
  productPercentDay,
  loanAskPeriod,
}: CalculatePercentMonthlyPaymentArgs): string => {
  const weeklyInterestRate = (productPercentDay * 7) / 100
  const numerator = loanAskSum * (weeklyInterestRate * (1 + weeklyInterestRate) ** loanAskPeriod)
  const denominator = (1 + weeklyInterestRate) ** loanAskPeriod - 1
  const monthlyPayment = (numerator / denominator) * loanAskPeriod
  const adjustedPayment = monthlyPayment / Math.ceil((loanAskPeriod * 7) / 30.41)
  return Math.round(adjustedPayment).toFixed(2)
}

export const PdnCalculator: React.FC<IModuleProps> = ({
  openedModules,
  onToggleModule,
}): JSX.Element => {
  const dispatch = useDispatch()
  const { loadData, userDetailsData } = useSelector((store: GlobalState) => store.singleLoan)

  const [fetching, setFetching] = useState<boolean>(false)
  const [apiResponse, setApiResponse] = useState<any>(null)
  const [secondApiResponse, setSecondApiResponse] = useState<any>(null)
  const [errorResponse, setErrorResponse] = useState<any>(null)
  const [initialValues, setInitialValues] = useState<IFormValues>({
    loanSum: null,
    percentMonthlyPayment: null,
    loanAskPeriod: null,
    otherIncome: null,
  })
  const [fragmentation, setFragmentation] = useState<boolean>(false)

  useEffect(() => {
    if (loadData?.loan_ask_sum && loadData?.product_percent_day && loadData?.loan_ask_period) {
      const percentMonthlyPayment = calculatePercentMonthlyPayment({
        loanAskSum: loadData.loan_ask_sum,
        productPercentDay: loadData.product_percent_day,
        loanAskPeriod: loadData.loan_ask_period,
      })

      setInitialValues({
        loanSum: String(loadData.loan_ask_sum),
        percentMonthlyPayment,
        loanAskPeriod: String(loadData.loan_ask_period),
        otherIncome: userDetailsData?.data?.other_income
          ? String(userDetailsData.data.other_income)
          : '0',
      })
    }
  }, [
    loadData?.loan_ask_sum,
    loadData?.product_percent_day,
    loadData?.loan_ask_period,
    userDetailsData?.data?.other_income,
    loadData?.credit_product,
  ])

  const isOpen = openedModules.includes(moduleId)

  const handleCancel = (resetForm) => (): void => resetForm()

  const handleExpand = (): void => onToggleModule(moduleId)

  const submitForm = async (values: any): Promise<void> => {
    console.log('values ===>', values)

    fetchData({
      setFetching,
      loanNumber: loadData?.loan_number,
      loanSum: parseFloat(values?.loanSum),
      percentPerDay: values?.percentMonthlyPayment,
      otherIncome: values?.otherIncome,
      setApiResponse,
      setSecondApiResponse,
      setErrorResponse,
      fragmentation,
      onError: () => {
        dispatch(
          addToast({
            type: ToastTypes.warning,
            title: 'Ошибка',
            description: `Данные не сохранены`,
          })
        )
      },
      onSuccess: () => {
        dispatch(
          addToast({
            type: ToastTypes.success,
            title: 'Успех',
            description: `Данные успешно перезапрошены`,
          })
        )
      },
    })
  }

  const recalculatePercentMonthlyPayment = ({
    values,
    setFieldValue,
  }: RecalculatePercentMonthlyPaymentProps) => {
    const monthlyPayment = calculatePercentMonthlyPayment({
      loanAskSum: values.loanSum,
      productPercentDay: loadData.product_percent_day,
      loanAskPeriod: loadData.loan_ask_period,
    })

    setFieldValue('percentMonthlyPayment', monthlyPayment)
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={submitForm}
      validationSchema={validationSchemaPdnCalculator}
    >
      {(formikProps) => {
        const { errors, dirty, resetForm, handleSubmit } = formikProps

        return (
          <Module.Wrapper isOpen={isOpen} editable isEdited={dirty} id={moduleId}>
            <Module.Header onClick={handleExpand}>
              <Module.Actions isOpen={isOpen}>
                <Module.HeadTitle>Калькулятор ПДН</Module.HeadTitle>
                <Module.Arrow isOpen={isOpen} />
              </Module.Actions>
            </Module.Header>
            <Module.ContentWrapper>
              <>
                <Module.Content>
                  <Edit
                    {...formikProps}
                    fragmentation={fragmentation}
                    setFragmentation={setFragmentation}
                    recalculatePercentMonthlyPayment={recalculatePercentMonthlyPayment}
                  />
                </Module.Content>
              </>
              <StyledPdnTableWrapper>
                <PdnTable data={apiResponse} />
                <PdnTable data={secondApiResponse} />
              </StyledPdnTableWrapper>
              <StyledButtonBlock>
                <div style={{ margin: '25px 0' }}>
                  <Button
                    disabled={isValidForm(errors) || fetching}
                    type='standardBig'
                    onClick={!fetching ? () => handleSubmit() : null}
                    pending={fetching}
                  >
                    Запросить
                  </Button>
                  {dirty && (
                    <Button type='emptyBig' onClick={handleCancel(resetForm)}>
                      Отменить
                    </Button>
                  )}
                </div>
              </StyledButtonBlock>
            </Module.ContentWrapper>
          </Module.Wrapper>
        )
      }}
    </Formik>
  )
}

const getSelfLocked = (data: boolean | null): string => {
  switch (true) {
    case data === true:
      return 'Да'
    case data === false:
      return 'Нет'
    default:
      return 'Неизвестно'
  }
}

const PdnTable = ({ data }) => {
  if (!data) return null

  return (
    <div style={{ margin: '32px' }}>
      <StyledPDNCalculatorContent>
        <React.Fragment>
          <StyledLimitItem>
            <StyledPDNCalculatorTitle>Доход:</StyledPDNCalculatorTitle>
          </StyledLimitItem>
          <StyledLimitItem>
            <StyledPDNCalculatorResult>{data[0]?.income}</StyledPDNCalculatorResult>
          </StyledLimitItem>

          <StyledLimitItem>
            <StyledPDNCalculatorTitle>Дополнительный доход:</StyledPDNCalculatorTitle>
          </StyledLimitItem>
          <StyledLimitItem>
            <StyledPDNCalculatorResult>{data[0]?.other_sources_income}</StyledPDNCalculatorResult>
          </StyledLimitItem>

          <StyledLimitItem>
            <StyledPDNCalculatorTitle>ПДН:</StyledPDNCalculatorTitle>
          </StyledLimitItem>
          <StyledLimitItem>
            <StyledPDNCalculatorResult>{data[0]?.PDN}</StyledPDNCalculatorResult>
          </StyledLimitItem>

          <StyledLimitItem>
            <StyledPDNCalculatorTitle>Все платежи:</StyledPDNCalculatorTitle>
          </StyledLimitItem>
          <StyledLimitItem>
            <StyledPDNCalculatorResult>{data[0]?.allPayments}</StyledPDNCalculatorResult>
          </StyledLimitItem>

          <StyledLimitItem>
            <StyledPDNCalculatorTitle>Суммарный доход:</StyledPDNCalculatorTitle>
          </StyledLimitItem>
          <StyledLimitItem>
            <StyledPDNCalculatorResult>{data[0]?.totalIncome}</StyledPDNCalculatorResult>
          </StyledLimitItem>

          <StyledLimitItem>
            <StyledPDNCalculatorTitle>Другие платежи по кредитам:</StyledPDNCalculatorTitle>
          </StyledLimitItem>
          <StyledLimitItem>
            <StyledPDNCalculatorResult>{data[0]?.otherLoansPayment}</StyledPDNCalculatorResult>
          </StyledLimitItem>

          <StyledLimitItem>
            <StyledPDNCalculatorTitle>Доход по БКИ:</StyledPDNCalculatorTitle>
          </StyledLimitItem>
          <StyledLimitItem>
            <StyledPDNCalculatorResult>{data[0]?.kbrsIncome}</StyledPDNCalculatorResult>
          </StyledLimitItem>

          <StyledLimitItem>
            <StyledPDNCalculatorTitle>Региональный доход:</StyledPDNCalculatorTitle>
          </StyledLimitItem>
          <StyledLimitItem>
            <StyledPDNCalculatorResult>{data[0]?.regionIncome}</StyledPDNCalculatorResult>
          </StyledLimitItem>

          <StyledLimitItem>
            <StyledPDNCalculatorTitle>Сумма займа:</StyledPDNCalculatorTitle>
          </StyledLimitItem>
          <StyledLimitItem>
            <StyledPDNCalculatorResult>{data[0]?.loanSum}</StyledPDNCalculatorResult>
          </StyledLimitItem>

          <StyledLimitItem>
            <StyledPDNCalculatorTitle>Источник:</StyledPDNCalculatorTitle>
          </StyledLimitItem>
          <StyledLimitItem>
            <StyledPDNCalculatorResult>external</StyledPDNCalculatorResult>
          </StyledLimitItem>

          <StyledLimitItem>
            <StyledPDNCalculatorTitle>Ежемесячный платеж:</StyledPDNCalculatorTitle>
          </StyledLimitItem>
          <StyledLimitItem>
            <StyledPDNCalculatorResult>{data[0]?.loanPayment}</StyledPDNCalculatorResult>
          </StyledLimitItem>

          <StyledLimitItem>
            <StyledPDNCalculatorTitle>Самозапрет</StyledPDNCalculatorTitle>
          </StyledLimitItem>
          <StyledLimitItem>
            <StyledPDNCalculatorResult>
              {getSelfLocked(data[0]?.selfLock)}
            </StyledPDNCalculatorResult>
          </StyledLimitItem>
        </React.Fragment>
      </StyledPDNCalculatorContent>
    </div>
  )
}

const Edit: React.FC<EditProps> = (props): JSX.Element => {
  const {
    values,
    setFieldValue,
    fragmentation,
    setFragmentation,
    recalculatePercentMonthlyPayment,
  } = props

  const handleBlur = () => recalculatePercentMonthlyPayment({ values, setFieldValue })

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') handleBlur()
  }

  return (
    <>
      <Module.Column column={1}>
        <InputField
          name='loanSum'
          onChange={setFieldValue}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          placeholder='Сумма займа'
          value={values.loanSum}
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='percentMonthlyPayment'
          onChange={setFieldValue}
          placeholder='Ежемесячный платеж'
          value={values.percentMonthlyPayment}
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='otherIncome'
          onChange={setFieldValue}
          placeholder='Дополнительный доход клиента'
          value={values.otherIncome}
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <div style={{ marginTop: '20px' }}>
          <CheckBox
            size='big'
            active={fragmentation}
            onChange={(value) => setFragmentation(!value)}
            value={fragmentation}
            label={'Дробление'}
          />
        </div>
      </Module.Column>
    </>
  )
}
