/* eslint-disable arrow-body-style */
import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { spacing, size, border, display, flexbox, StyledProps } from './styled-props'

interface FlexProps extends StyledProps, React.HTMLAttributes<HTMLDivElement> {}

const StyledFlex = styled.div<FlexProps>`
  display: flex;
  ${spacing}
  ${size}
  ${border}
  ${display}
  ${flexbox}
`

export const Flex = forwardRef<HTMLDivElement, React.PropsWithChildren<FlexProps>>(
  ({ children, ...props }, ref) => {
    return (
      <StyledFlex ref={ref} {...props}>
        {children}
      </StyledFlex>
    )
  }
)

Flex.displayName = 'Flex'

Flex.defaultProps = {
  children: null,
}
