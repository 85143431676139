import { shallowEqual, useSelector } from 'react-redux'
import { GlobalState } from '../../index'
import {
  ILoanPageData,
  IPersonalData,
  ILoanData,
  IPassportData,
  IOtherDocs,
  IBankCardsData,
  IRegistrationData,
  IImageLoanData,
  INbkiHitory,
  IStatusLoan,
  IAssignInfo,
  INbkiScoring,
} from './types'
import { TabsMediaFilesFilteredType } from 'pages/UnderwriterLoan/Modules/types'

export const useGetIsCompleteLoanPage = (): boolean =>
  useSelector((store: GlobalState) => store.singleLoan.isCompleteLoanPage, shallowEqual)

export const useGetIsFetchingLoanPage = (): boolean =>
  useSelector((store: GlobalState) => store.singleLoan.isFetchingLoanPage, shallowEqual)

export const useGetErrorFetchingLoanPage = (): boolean =>
  useSelector((store: GlobalState) => store.singleLoan.fetchingLoanPageError, shallowEqual)

export const useGetLoanPageData = (): ILoanPageData =>
  useSelector((store: GlobalState) => store.singleLoan.selectedLoanData, shallowEqual)

export const useGetLoanPersonalData = (): IPersonalData =>
  useSelector((store: GlobalState) => store.singleLoan.personalData, shallowEqual)

export const useGetLoanData = (): ILoanData =>
  useSelector((store: GlobalState) => store.singleLoan.loadData, shallowEqual)

export const useGetLoanPassportData = (): IPassportData =>
  useSelector((store: GlobalState) => store.singleLoan.passportData, shallowEqual)

export const useGetLoanOtherDocsData = (): IOtherDocs =>
  useSelector((store: GlobalState) => store.singleLoan.otherDocs, shallowEqual)

export const useGetLoanBankCardsData = (): Array<IBankCardsData> =>
  useSelector((store: GlobalState) => store.singleLoan.bankCardsData, shallowEqual)

export const useGetRegistrationData = (): IRegistrationData =>
  useSelector((store: GlobalState) => store.singleLoan.registrationData, shallowEqual)

export const useGetResidenceData = (): IRegistrationData =>
  useSelector((store: GlobalState) => store.singleLoan.residenceData, shallowEqual)

export const useGetExpandedCounter = (): number =>
  useSelector((store: GlobalState) => store.singleLoan.expandCounterLoanItems, shallowEqual)

export const useGetUserId = (): string =>
  useSelector((store: GlobalState) => store.singleLoan.userId, shallowEqual)

// export const useGetPhotoData = (): IImageLoanData =>
//   useSelector((store: GlobalState) => store.singleLoan.loanImageData, shallowEqual)

export const useGetPhotoData = (): IImageLoanData => {
  const images = useSelector((store: GlobalState) => store.singleLoan.loanImageData, shallowEqual)
  const filteredTabs = useSelector((store: GlobalState) => store.singleLoan.filteredImages)
  const loanNumber = useSelector((store: GlobalState) => store.singleLoan.loadData.loan_number)

  const filterByLoanNumber = (arr, tab) =>
    filteredTabs[tab] === null
      ? arr?.filter((image) => String(image.loan_number) === String(loanNumber))
      : arr

  return {
    personalDataPhoto: filterByLoanNumber(
      images.personalDataPhoto,
      TabsMediaFilesFilteredType.MainPage
    ),
    registrationDataPhoto: filterByLoanNumber(
      images.registrationDataPhoto,
      TabsMediaFilesFilteredType.RegPage
    ),
    passportSelfie: filterByLoanNumber(images.passportSelfie, TabsMediaFilesFilteredType.Selfie),
    bankCardDataPhoto: filterByLoanNumber(
      images.bankCardDataPhoto,
      TabsMediaFilesFilteredType.BankCards
    ),
    agentDataPhoto: filterByLoanNumber(
      images.agentDataPhoto,
      TabsMediaFilesFilteredType.AgentPhoto
    ),
  }
}

export const useGetNBKIData = (): Array<INbkiHitory> =>
  useSelector((store: GlobalState) => store.singleLoan.nbkiHistoryData, shallowEqual)

export const useGetNBKIScoring = (): INbkiScoring =>
  useSelector((store: GlobalState) => store.singleLoan.nbkiScoringData, shallowEqual)

export const useGetStatusesLoan = (): IStatusLoan =>
  useSelector((store: GlobalState) => store.singleLoan.statusesLoan, shallowEqual)

export const useGetAssignInfo = (): IAssignInfo =>
  useSelector((store: GlobalState) => store.singleLoan.assignInfo, shallowEqual)
