/* eslint-disable indent */
/* eslint-disable arrow-body-style */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector, batch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import StickyBox from 'react-sticky-box'
import { NavMenuType } from 'App'
import { fetchUserLoans } from 'features/userHistoryLoans/extra'

import { Loader, UserInformation, Navigation, ScrollToTop } from 'components'
import {
  PersonalData,
  PassportData,
  BankCards,
  Registration,
  Residence,
  Phones,
  Verification,
  NBKIHistory,
  UserDetails,
  SetLimitPLD,
  SetLimitInstallment,
  SetLimitResult,
  LoansHistory,
  Services,
  AgentPhoto,
  Comments,
  StatusesHistory,
  AccrueHistory,
  RepaymentHistory,
  AdditionalContacts,
  DeptLoanBRS,
  Payments,
  Fssp,
  VideoConfirmation,
  Femida,
  BankruptData,
  NBKITransfer,
  Documents,
  PdnCalculator,
  OtherDocuments,
} from './Modules'

import { INavigationItem } from 'components/Navigation/Navigation'
import { GlobalState } from 'index'
import { resetLoan } from 'features/loan/singleLoanSlice'

import {
  useGetErrorFetchingLoanPage,
  useGetIsFetchingLoanPage,
  useGetUserId,
  useGetLoanData,
} from '../../features/loan/loanSelectors'

import {
  fetchLoanPageById,
  fetchPersonalDataPhoto,
  fetchRegistrationDataPhoto,
  fetchBankCardDataPhoto,
  fetchAgentDataPhoto,
  fetchNBKIHistoryData,
  fetchIsBanned,
  fetchPassportData,
  fetchPtiData,
  getLoanComments,
  getStatusesHistory,
  fetchAnalyzeData,
  fetchNBKIScoringData,
  fetchServicesData,
  fetchLoanTransactionsByNumber,
  fetchLoanDebtByNumber,
  fetchAdditionalContacts,
  fetchPtiBRSData,
  fetchUserDetailsData,
  fetchVideoVerification,
  fetchBankruptData,
  fetchLoanGeneralInfoData,
  fetchBkiDocuments,
  checkVerifyPassportData,
  fetchCheckIsBlacklisted,
  getLoanMessages,
  fetchPassportSelfieDataPhoto,
} from '../../features/loan/extra'

import { HeadInfo, Head } from './Head'
import {
  StyledLoaderPage,
  StyledWrapper,
  StyledBlockNav,
  StyledContent,
  StyledNavContent,
} from './Loan.styles'
import {
  UNDERWRITER_LOANS_ROUTE,
  OPERATOR_LOANS_ROUTE,
  CreditProduct,
  agentPhotoCredits,
} from '../../constants'
import { IModuleProps } from './Modules/types'
import { fetchLoanTransactions } from 'features/operator/extra'
import { rolesType } from 'constants/rtoles'

import { Comments as CommentsAgent } from 'pages/AgentLoan/Modules/Comments'
import { getLoanComments as getAgentLoanComments } from 'features/agentLoans/extra'
import { checkLoanIssuedDate, checkLoanIssuedStatus, LoanStatuses } from 'helpers'
import { fetchAgentUsernames } from 'features/dict/extra'
import { NavigationContext } from 'app/contexts'
import { Information } from './Modules/Information'
import { PDNLimitCalculate } from './Modules/SetLimitInstallment/PDNLimitCalculate'
import {
  getPZDocumentsList,
  getSaasDocumentsList,
  getSignatureQuestionnaires,
} from 'features/commonDocuments/extra'
import { Messages } from './Modules/Messages'
import { getOtherDocumentsList } from 'features/otherDoceuments/extra'

const navigationList = (
  showSetLimit,
  showAgentPhoto = false,
  showPDLPhoto = false,
  showPDNLimits,
  isOperatorClaims,
  isUnderwriterClaims,
  showInfo = false
): INavigationItem[] => {
  const list = [
    { title: 'Персональные данные', anchor: 'personal' },
    { title: 'Паспортные данные', anchor: 'passport' },
    { title: 'Банкротство', anchor: 'bankrupt' },
    { title: 'Видео идентификация', anchor: 'videoConfirmation' },

    { title: 'Адрес регистрации', anchor: 'address' },

    { title: 'Адрес проживания', anchor: 'residence' },

    { title: 'Банковские карты', anchor: 'cards' },

    { title: 'Другие документы', anchor: 'otherDocuments' },

    { title: 'Телефоны', anchor: 'phone' },

    { title: 'Дополнительные контакты', anchor: 'additionalContacts' },

    { title: 'Информация для проверки', anchor: 'info' },
    { title: 'История НБКИ', anchor: 'history' },
    { title: 'ФССП', anchor: 'fssp' },
    { title: 'Занятость и доходы', anchor: 'income' },
    { title: 'Комментарии ', anchor: 'comments' },
    { title: 'Сообщения ', anchor: 'messages' },
    { title: 'Комментарии агента', anchor: 'agentComments' },
    { title: 'История статусов', anchor: 'statusesHistory' },
    { title: 'История займов', anchor: 'loansHistory' },
    { title: 'Услуги', anchor: 'services' },
    { title: 'Документы', anchor: 'documents' },
  ]
  if (!showPDLPhoto) list.push({ title: 'График платежей', anchor: 'payments' })

  list.push({ title: 'Долговая нагрузка БРС', anchor: 'deptLoanBRS' })

  if (isOperatorClaims || isUnderwriterClaims)
    list.push({
      title: 'История начислений',
      anchor: 'accrueHistory',
    })

  if (isOperatorClaims || isUnderwriterClaims)
    list.push({
      title: 'История погашений',
      anchor: 'repaymentHistory',
    })

  list.push({ title: 'Согласия передачи в БКИ', anchor: 'transfer' })

  if (showAgentPhoto || showPDLPhoto)
    list.splice(7, 0, {
      title: showAgentPhoto ? 'Фото агента' : 'Скан документов',
      anchor: 'agentPhoto',
    })

  if (!isOperatorClaims) {
    const index = list.findIndex((v) => v.anchor === 'history')
    list.splice(index + 1, 0, { title: 'Femida', anchor: 'femida' })
  }

  if (showInfo) {
    list.push({
      title: 'Информация по займу',
      anchor: 'information',
    })
  }

  if (showPDNLimits) {
    list.push({
      title: ' Калькулятор ПДН',
      anchor: 'pdnCalculator',
    })
  }

  if (showSetLimit)
    list.push({
      title: 'Выставление лимита',
      anchor: 'limit',
    })

  return list
}

export const UnderwriterLoanPage: any = (props): JSX.Element => {
  const { claims } = props
  const { id } = useParams<{ id: string | undefined }>()
  const history = useHistory()
  const dispatch = useDispatch()
  const { setNavigation, setNavMenuType } = useContext(NavigationContext)
  const {
    isBanned,
    statusesLoan: { is_in_work: loanInWork },
    resolve,
    same_address: sameAddress,
    loadData,
    userData: {
      customer_id: customerId,
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
    },
    in_passport: inPassport,
    selectedLoanData,
    statusesHistory,
  } = useSelector((store: GlobalState) => store.singleLoan)
  const { is_paused: isPaused, is_in_work: isInWork } = useSelector(
    (store: GlobalState) => store.singleLoan.statusesLoan
  )
  const isFetching = useGetIsFetchingLoanPage()
  const errorFetching = useGetErrorFetchingLoanPage()
  const userId = useGetUserId()
  const {
    loan_number: loanNumber,
    credit_product: creditProduct,
    loan_status: loanStatus,
  } = useGetLoanData()
  const [openedModules, setOpenedModules] = useState<string[]>([])

  const showAgentPhoto = agentPhotoCredits.includes(creditProduct)

  const showPDLPhoto = creditProduct === CreditProduct?.pdl

  const onToggleModule = (anchor: string): void => {
    const newOpenedModules = [...openedModules]
    const index = newOpenedModules.indexOf(anchor)
    index !== -1 ? newOpenedModules.splice(index, 1) : newOpenedModules.push(anchor)
    setOpenedModules(newOpenedModules)
  }

  const onNavigationClick = (anchor: string) => {
    if (!openedModules.includes(anchor)) {
      setOpenedModules([...openedModules, anchor])
    }
  }

  useEffect(() => {
    setNavMenuType(NavMenuType[claims])
  }, [])

  useEffect(() => {
    if (loanNumber) {
      setNavigation({
        activeMenu: null,
        headContent: `${claims === rolesType.operator ? 'Оператор' : 'Андеррайтер'} Заявка ${
          loanInWork ? 'в работе :' : ''
        } №${loanNumber}`,
        backTo: `${
          claims === rolesType.operator ? OPERATOR_LOANS_ROUTE : UNDERWRITER_LOANS_ROUTE
        }?type=all`,
        slug: claims === rolesType.operator ? rolesType.operator : rolesType.underwriter,
      })
    }
  }, [loanNumber, loanInWork])

  useEffect(() => {
    if (userId) {
      const args = { id: userId, loanId: loadData?.loan_id }
      if (showAgentPhoto || showPDLPhoto) dispatch(fetchAgentDataPhoto(args))

      batch(() => {
        dispatch(fetchPassportData(args))
        dispatch(fetchCheckIsBlacklisted({ userId }))
        dispatch(fetchUserDetailsData(args))
        dispatch(fetchAdditionalContacts(args))
        dispatch(fetchIsBanned(args))
        dispatch(fetchPersonalDataPhoto(args))
        dispatch(fetchRegistrationDataPhoto(args))
        dispatch(fetchBankCardDataPhoto(args))
        dispatch(fetchNBKIHistoryData(args))
        dispatch(fetchPtiData(args))
        dispatch(fetchNBKIScoringData({ id: loadData?.loan_number }))
        dispatch(getSaasDocumentsList({ loan_number: loadData?.loan_number }))
        dispatch(getPZDocumentsList({ loan_id: loadData?.questionnaire_id }))
        dispatch(getOtherDocumentsList({ user_id: userId }))
        dispatch(getSignatureQuestionnaires({ loan_number: loadData?.loan_number }))
        dispatch(fetchServicesData({ loanNumber: loadData?.loan_number }))
        dispatch(getLoanComments(args))
        dispatch(getStatusesHistory({ id: loadData.loan_id }))
        dispatch(fetchUserLoans({ ids: [`${userId}`] }))
        dispatch(getAgentLoanComments({ loanNumber: loadData?.loan_number }))
        dispatch(fetchPtiBRSData({ loan_number: loadData?.loan_number }))
        dispatch(fetchAgentUsernames())
        dispatch(fetchBankruptData({ userId }))
        dispatch(fetchPassportSelfieDataPhoto({ userId }))
        dispatch(
          fetchBkiDocuments({
            userId,
          })
        )
      })
    }

    if (customerId) {
      dispatch(fetchVideoVerification({ customer_id: customerId }))
      dispatch(getLoanMessages({ customer_id: customerId }))
    }
    if (loadData?.loan_id) dispatch(fetchAnalyzeData({ userId, loanId: loadData.loan_id }))
    if (loadData?.loan_id && (claims === rolesType.operator || claims === rolesType.underwriter)) {
      batch(() => {
        dispatch(
          claims === rolesType.operator
            ? fetchLoanTransactions({ loan_number: loadData?.loan_number })
            : fetchLoanTransactionsByNumber({ loan_number: +loadData?.loan_number })
        )
        dispatch(fetchLoanDebtByNumber({ number: +loadData.loan_number }))
      })
    }
    if (loadData?.loan_id && claims === rolesType.underwriter) {
      dispatch(checkVerifyPassportData({ loanId: loadData?.loan_id }))
    }
  }, [userId])

  const showInfo = [
    LoanStatuses.loan_issued,
    LoanStatuses.loan_overdue,
    LoanStatuses.frozen_collector,
    LoanStatuses.period_paid,
    LoanStatuses.loan_repayment_date,
  ].includes(loanStatus)

  const showPDNLimits =
    checkLoanIssuedDate(loadData?.loan_date_create) &&
    (checkLoanIssuedStatus(statusesHistory?.data) ||
      [
        LoanStatuses.setting_a_limit,
        LoanStatuses.on_check,
        LoanStatuses.agent_in_work,
        LoanStatuses.customer_revision,
      ].includes(loanStatus))

  useEffect(() => {
    if (showInfo) {
      dispatch(fetchLoanGeneralInfoData({ loanNumber: loadData?.loan_number }))
    }
  }, [dispatch, loadData?.loan_number, loanStatus, showInfo])

  useEffect(() => {
    dispatch(fetchLoanPageById({ id }))
    return () => {
      dispatch(resetLoan())
    }
  }, [])

  useEffect(() => {
    if (errorFetching)
      history.push(claims === rolesType.operator ? OPERATOR_LOANS_ROUTE : UNDERWRITER_LOANS_ROUTE)
  }, [errorFetching])

  const claimsResolve = {
    underwriter: true,
    operator: false,
  }

  const isEditable = loanStatus === LoanStatuses.setting_a_limit

  const moduleProps: IModuleProps = {
    loan_id: id,
    openedModules,
    onToggleModule,
    creditProduct,
    claimsResolve: claimsResolve[claims],
    loanStatus,
    loanNumber: loadData?.loan_number,
    userId,
    customerId,
    inPassport,
    isEditable,
    loanBalance: selectedLoanData?.loan_balance,
    claims,
  }

  const onRollUp = (): void => {
    setOpenedModules([])
    const el = document.getElementById('loan-content')
    if (el) {
      el.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }
  const onRollDown = (): void => {
    const anchors = navigationList(
      (showSettingLimit || resolve) && !isBanned?.is_banned,
      showAgentPhoto,
      showPDLPhoto,
      showPDNLimits,
      claims === rolesType.operator,
      claims === rolesType.underwriter,
      showInfo
    ).map((v) => v.anchor)

    setOpenedModules(anchors)
  }
  const showSettingLimit = isInWork && !isPaused

  return (
    <>
      {isFetching ? (
        <StyledLoaderPage>
          <Loader />
        </StyledLoaderPage>
      ) : (
        <>
          <ScrollToTop />
          <StyledWrapper>
            <StyledBlockNav>
              <UserInformation
                fullName={[lastName, firstName, middleName].join(' ')}
                creditProduct={loadData?.credit_product}
              />
              <Navigation
                list={navigationList(
                  (showSettingLimit || resolve) && !isBanned?.is_banned,
                  showAgentPhoto,
                  showPDLPhoto,
                  showPDNLimits,
                  claims === rolesType.operator,
                  claims === rolesType.underwriter,
                  showInfo
                )}
                containerId='loan-content'
                onChange={onNavigationClick}
              />
            </StyledBlockNav>
            <StyledNavContent>
              <StickyBox>
                <HeadInfo
                  onRollUp={onRollUp}
                  onRollDown={onRollDown}
                  claimsResolve={claimsResolve[claims]}
                />
              </StickyBox>
              <StyledContent id='loan-content'>
                <Head claimsResolve={claimsResolve[claims]} />
                <PersonalData {...moduleProps} />
                <PassportData {...moduleProps} />
                <BankruptData {...moduleProps} />
                <VideoConfirmation {...moduleProps} />

                <Registration {...moduleProps} />

                <Residence {...moduleProps} sameAddress={sameAddress} />

                <BankCards {...moduleProps} />
                {(showAgentPhoto || showPDLPhoto) && (
                  <AgentPhoto
                    {...moduleProps}
                    photoType={showAgentPhoto ? 'showAgentPhoto' : 'showPDLPhoto'}
                  />
                )}
                <OtherDocuments {...moduleProps} />
                <Phones {...moduleProps} />
                <AdditionalContacts {...moduleProps} />
                <Verification {...moduleProps} />
                <NBKIHistory {...moduleProps} />
                {claims !== rolesType.operator && <Femida {...moduleProps} />}
                <Fssp {...moduleProps} />
                <UserDetails {...moduleProps} />
                <Comments {...moduleProps} />
                {claims !== rolesType.operator && <Messages {...moduleProps} />}
                <CommentsAgent {...moduleProps} title='Комментарии агента' />
                <StatusesHistory {...moduleProps} />
                <LoansHistory {...moduleProps} />
                <Services {...moduleProps} />
                <Documents {...moduleProps} />
                {creditProduct !== CreditProduct?.pdl && <Payments {...moduleProps} />}
                <DeptLoanBRS {...moduleProps} />
                {(claims === rolesType.operator || claims === rolesType.underwriter) && (
                  <AccrueHistory {...moduleProps} claims={claims} />
                )}
                {(claims === rolesType.operator || claims === rolesType.underwriter) && (
                  <RepaymentHistory {...moduleProps} claims={claims} />
                )}
                <NBKITransfer {...moduleProps} />
                {showInfo && <Information {...moduleProps} />}
                {showPDNLimits && <PdnCalculator {...moduleProps} />}
                {showPDNLimits && (
                  <PDNLimitCalculate
                    showRecalculate={claims !== rolesType.operator}
                    showRefetch={claims !== rolesType.operator}
                  />
                )}
                {claimsResolve[claims] &&
                  showSettingLimit &&
                  !isBanned?.is_banned &&
                  !resolve &&
                  (creditProduct !== CreditProduct.pdl ||
                    creditProduct !== CreditProduct.pdl_08) && (
                    <SetLimitInstallment {...moduleProps} />
                  )}
                {(creditProduct === CreditProduct.pdl ||
                  creditProduct === CreditProduct.pdl_08) && <SetLimitPLD {...moduleProps} />}
                {resolve ? <SetLimitResult {...moduleProps} resolve={resolve} /> : null}
              </StyledContent>
            </StyledNavContent>
          </StyledWrapper>
        </>
      )}
    </>
  )
}
