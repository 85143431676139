import styled, { css } from 'styled-components/macro'

export const StyledSwitcher = styled.div<{ tabCount: number }>`
  position: relative;
  display: grid;
  grid-template-columns: repeat(${({ tabCount }) => tabCount}, 1fr);
  background: #3b414f;
  border-radius: 16px;
  height: 40px;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  align-items: center;

  @media (max-width: 400px) {
    grid-template-columns: 1fr;
    height: ${({ tabCount }) => tabCount * 42}px;
    font-size: 12px;
  }
`

export const StyledTab = styled.div<{ active: boolean }>`
  z-index: 1;
  transition: all 0.2s linear;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  ${({ active }) =>
    active &&
    css`
      cursor: default;
      color: #08003a;
    `}
  @media (max-width: 630px) {
    font-size: 12px;
  }
`

export const StyledActiveTab = styled.div<{ activeTab: number; tabCount: number }>`
  position: absolute;
  top: 0;
  background: #f8cc46;
  border-radius: 14px;
  height: 34px;
  width: calc(100% / ${({ tabCount }) => tabCount} - 4px);
  left: 2px;
  top: 3px;
  transition: transform 0.2s linear;
  transform: translateX(calc(${({ activeTab }) => activeTab} * 100%));

  @media (max-width: 400px) {
    width: calc(100% - 4.5px);
    transform: translateY(calc(${({ activeTab }) => activeTab} * 100%));
  }
`
