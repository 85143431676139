/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { GlobalState } from 'index'

import { Module } from 'components'
import { normalizeValues } from 'helpers'

const moduleId = 'contacts'

export const Contacts: any = ({ openedModules, onToggleModule }): JSX.Element => {
  const [data, setData] = useState({ items: [] })
  const isOpen = openedModules.includes(moduleId)
  const phonesData = useSelector((store: GlobalState) => store.collector.phonesData)

  const PHONE_TYPES = {
    mobile: 'Мобильный',
    work: 'Рабочий',
  }

  useEffect(() => {
    if (setData) {
      setData({ items: phonesData })
    }
  }, [phonesData])

  const handleExpand = (): void => onToggleModule(moduleId)

  const isFetching = false // TODO

  return (
    <Module.Wrapper isOpen={isOpen} editable={false} isEdited={false} id={moduleId}>
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <Module.HeadTitle>Контакты</Module.HeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        <Module.Content>
          {isFetching && <Module.LoaderBlock />}
          <>
            {data.items &&
              data.items.map((item, index) => (
                <React.Fragment key={index}>
                  <Module.Column column={4}>
                    <Module.Title>{`${PHONE_TYPES[item?.number_type] || ''} Телефон`}</Module.Title>
                    <Module.Value styles='styledValuePhone'>
                      {normalizeValues(item.number)}
                    </Module.Value>
                  </Module.Column>
                </React.Fragment>
              ))}
          </>
        </Module.Content>
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}
